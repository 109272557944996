.edit_lesson_type_pdf .pdf_url_field {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-bottom: 16px;
}
.edit_lesson_type_pdf .pdf_url_field input {
  width: 100%;
}
.edit_lesson_type_pdf .pdf_url_field button {
  flex-shrink: 0;
  margin-top: -4px;
}
.edit_lesson_type_pdf dd {
  position: relative;
}
.edit_lesson_type_pdf dd .pdf_viewer canvas {
  max-width: 594px;
}/*# sourceMappingURL=pdf.css.map */