@charset "UTF-8";
.ceb_general .q_hint {
  position: absolute;
  display: inline-block;
  margin-left: 16px;
  width: 24px;
  line-height: 24px;
  height: 24px;
  background-color: #434A5C;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  border-radius: 50%;
}
.ceb_general .course_description_block {
  margin-bottom: 48px;
}
.ceb_general .course_description_block .rdw-editor-toolbar {
  border: 0 solid;
  padding-left: 0;
  margin-left: -4px;
}
.ceb_general .course_description_block textarea,
.ceb_general .course_description_block .demo-editor {
  display: block;
  width: calc(100% - 18px);
  height: 200px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.ceb_general .course_description_block textarea.placeholder,
.ceb_general .course_description_block .demo-editor.placeholder {
  color: #C0C2C8;
}
.ceb_general .course_description_block textarea:-moz-placeholder,
.ceb_general .course_description_block .demo-editor:-moz-placeholder {
  color: #C0C2C8;
}
.ceb_general .course_description_block textarea::-moz-placeholder,
.ceb_general .course_description_block .demo-editor::-moz-placeholder {
  color: #C0C2C8;
}
.ceb_general .course_description_block textarea:-ms-input-placeholder,
.ceb_general .course_description_block .demo-editor:-ms-input-placeholder {
  color: #C0C2C8;
}
.ceb_general .course_description_block textarea::-webkit-input-placeholder,
.ceb_general .course_description_block .demo-editor::-webkit-input-placeholder {
  color: #C0C2C8;
}
.ceb_general .course_type_selection {
  background-color: #F2F0F0;
  border-radius: 8px;
  padding: 24px 32px;
  margin: 0 auto 48px;
  width: 800px;
}
.ceb_general .course_type_selection .course_parametres_block:last-of-type {
  margin-bottom: 0;
}
.ceb_general .course_type_selection .course_parametres_block.cpb_description {
  font-size: 13px;
  opacity: 0.8;
  margin-top: -24px;
  margin-bottom: 32px;
}
.ceb_general .course_type_selection .course_parametres_block.cpb_date_start {
  margin-bottom: 64px;
}
.ceb_general .course_type_selection h5 {
  color: #040E26;
}
.ceb_general .course_type_selection .text_input {
  background-color: #F2F0F0;
}
.ceb_general .course_parametres_block {
  position: relative;
  z-index: 10;
  margin: 0 0 48px;
  display: flex;
  justify-content: space-between;
}
.ceb_general .course_parametres_block.cpb_first_line {
  z-index: 11;
}
.ceb_general .course_parametres_block.hidden {
  display: none;
}
.ceb_general .course_parametres_block.focused {
  z-index: 20;
}
.ceb_general .course_parametres_block .cpb_item {
  width: 255px;
  flex-shrink: 0;
}
.ceb_general .course_parametres_block .cpb_item.free_course_toggler {
  padding-top: 20px;
}
.ceb_general .course_parametres_block .cpb_item .dropdown {
  padding-bottom: 1px;
  border-bottom: 1px solid #C0C2C8;
}
.ceb_general .course_covers_block .ce_upload_wrapper {
  margin-bottom: 64px;
}
.ceb_general .course_covers_block .ce_upload {
  background-color: #F2F0F0;
  position: relative;
  overflow: hidden;
}
.ceb_general .course_covers_block .ce_upload.loaded {
  background-color: #040E26;
}
.ceb_general .course_covers_block .ce_upload.loaded:after {
  content: "загрузить другую";
  opacity: 0;
  color: #fff;
  transition: opacity ease-out 200ms;
}
.ceb_general .course_covers_block .ce_upload.loaded img {
  transition: opacity ease-out 200ms;
}
.ceb_general .course_covers_block .ce_upload.loaded:hover:after {
  opacity: 1;
}
.ceb_general .course_covers_block .ce_upload.loaded:hover img {
  opacity: 0.3;
}
.ceb_general .course_covers_block .ce_upload:after {
  content: "загрузить";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 16px;
  line-height: 24px;
  color: #040E26;
}
.ceb_general .course_covers_block .ce_upload.ce_upload_preview {
  height: 148px;
  cursor: pointer;
}
.ceb_general .course_covers_block .ce_upload.ce_upload_preview input {
  visibility: hidden;
}
.ceb_general .course_covers_block .ce_upload.ce_upload_vertical {
  height: 365px;
}
.ceb_general .course_covers_block .ce_upload.ce_upload_cover {
  height: 365px;
}
.ceb_general .course_covers_block .ce_upload.ce_upload_cover input {
  visibility: hidden;
}
.ceb_general .course_covers_block .ce_upload img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 5;
}
.ceb_general .course_covers_block .ce_upload label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 20;
}
.ceb_general .course_covers_block .ce_upload label.cc_preview {
  height: 148px;
}
.ceb_general .course_covers_block .ce_upload label.cc_cover {
  height: 365px;
}