.ls_welcome_wrapper {
  min-height: 710px;
  position: relative;
  background-color: #FAFCFF;
}
.ls_welcome_wrapper .ls_bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  top: 0;
  width: 100%;
  max-width: 1440px;
  height: 100%;
}
.ls_welcome_wrapper .ls_bg .bg_text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin-top: 22px;
  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  color: #040E26;
  text-transform: uppercase;
}
.ls_welcome_wrapper .ls_bg .circle img {
  width: 100%;
  height: auto;
}
.ls_welcome_wrapper .ls_bg .girl {
  position: absolute;
  z-index: 0;
  left: 525px;
  bottom: 0;
  width: 867px;
  height: 690px;
}
.ls_welcome_wrapper .ls_bg .create {
  position: absolute;
  top: 20px;
  left: 880px;
  width: 160px;
  height: 160px;
  box-shadow: 20px 20px 30px rgba(147, 158, 203, 0.1490196078);
  border-radius: 50%;
}
.ls_welcome_wrapper .ls_bg .publish {
  position: absolute;
  top: 165px;
  left: 1085px;
  width: 280px;
  height: 280px;
}
.ls_welcome_wrapper .ls_bg .get_money {
  position: absolute;
  top: 495px;
  left: 1010px;
  width: 120px;
  height: 120px;
  box-shadow: 20px 20px 30px rgba(65, 73, 103, 0.1490196078);
  border-radius: 50%;
}
.ls_welcome_wrapper .ls_bg .ellipse {
  position: absolute;
  border-radius: 50%;
  left: -60px;
  bottom: 130px;
  width: 120px;
  height: 120px;
  background: #FAFCFF; /* Old browsers */ /* FF3.6+ */
  background: -webkit-gradient(129deg, color-stop(0%, #FAFCFF), color-stop(100%, #DEE6F5)); /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(129deg, #FAFCFF 0%, #DEE6F5 100%); /* W3C */
}
.ls_welcome_wrapper .ls_welcome_text {
  padding-top: 56px;
  width: 590px;
}
.ls_welcome_wrapper .ls_welcome_text a {
  text-decoration: none;
  color: #2450FF;
  border-bottom: 1px solid rgba(36, 80, 255, 0.3137254902);
}
.ls_welcome_wrapper .ls_welcome_text h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 55px;
  margin-bottom: 30px;
}
.ls_welcome_wrapper .ls_welcome_text p {
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 50px;
}
.ls_welcome_wrapper .ls_welcome_text ul {
  width: 500px;
}
.ls_welcome_wrapper .ls_welcome_text ul.large_list li {
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 35px;
}
.ls_welcome_wrapper .ls_welcome_text ul.large_list li:before {
  width: 8px;
  height: 8px;
  top: 12px;
}
.ls_welcome_wrapper .ls_welcome_text ul li {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 32px;
  position: relative;
}
.ls_welcome_wrapper .ls_welcome_text ul li:before {
  content: "";
  position: absolute;
  left: -22px;
  top: 11px;
  border-radius: 50%;
  background-color: #2450FF;
  width: 7px;
  height: 7px;
}
@media screen and (max-width: 1050px) {
  .ls_welcome_wrapper .ls_welcome_text {
    width: auto;
  }
  .ls_welcome_wrapper .ls_welcome_text ul {
    width: auto;
  }
  .ls_welcome_wrapper .ls_bg {
    overflow: hidden;
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    position: relative;
    height: auto;
  }
  .ls_welcome_wrapper .ls_bg .girl {
    left: 0;
    width: 100%;
    height: auto;
    position: relative;
    bottom: auto;
  }
  .ls_welcome_wrapper .ls_bg .create,
  .ls_welcome_wrapper .ls_bg .publish,
  .ls_welcome_wrapper .ls_bg .get_money {
    width: 120px;
    height: 120px;
    left: auto;
    right: 60px;
  }
  .ls_welcome_wrapper .ls_bg .create {
    top: 0;
  }
  .ls_welcome_wrapper .ls_bg .publish {
    top: 200px;
  }
  .ls_welcome_wrapper .ls_bg .get_money {
    top: 400px;
  }
}
@media screen and (max-width: 867px) {
  .ls_welcome_wrapper .ls_bg .girl {
    width: 100%;
  }
  .ls_welcome_wrapper .ls_bg .girl img {
    width: 100%;
  }
  .ls_welcome_wrapper .ls_bg .bg_text {
    margin-top: 12px;
  }
  .ls_welcome_wrapper .ls_bg .create,
  .ls_welcome_wrapper .ls_bg .publish,
  .ls_welcome_wrapper .ls_bg .get_money {
    width: 100px;
    height: 100px;
  }
  .ls_welcome_wrapper .ls_bg .publish {
    top: 160px;
  }
  .ls_welcome_wrapper .ls_bg .get_money {
    top: 320px;
  }
}
@media screen and (max-width: 600px) {
  .ls_welcome_wrapper .ls_bg .bg_text {
    font-size: 10px;
    line-height: 10px;
  }
  .ls_welcome_wrapper .ls_bg .create,
  .ls_welcome_wrapper .ls_bg .publish,
  .ls_welcome_wrapper .ls_bg .get_money {
    width: 60px;
    height: 60px;
    right: 30px;
  }
  .ls_welcome_wrapper .ls_bg .publish {
    top: 100px;
  }
  .ls_welcome_wrapper .ls_bg .get_money {
    top: 200px;
  }
  .ls_welcome_wrapper .ls_welcome_text h1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 19px;
  }
  .ls_welcome_wrapper .ls_welcome_text p {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 26px;
  }
  .ls_welcome_wrapper .ls_welcome_text ul {
    margin-left: 15px;
  }
  .ls_welcome_wrapper .ls_welcome_text ul li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .ls_welcome_wrapper .ls_welcome_text ul li:before {
    width: 5px;
    height: 5px;
    left: -15px;
  }
}
@media screen and (max-width: 380px) {
  .ls_welcome_wrapper .ls_bg .bg_text {
    font-size: 10px;
    line-height: 10px;
  }
  .ls_welcome_wrapper .ls_bg .create,
  .ls_welcome_wrapper .ls_bg .publish,
  .ls_welcome_wrapper .ls_bg .get_money {
    width: 40px;
    height: 40px;
    right: 30px;
  }
  .ls_welcome_wrapper .ls_bg .publish {
    top: 80px;
  }
  .ls_welcome_wrapper .ls_bg .get_money {
    top: 160px;
  }
}/*# sourceMappingURL=index.css.map */