.main_schools_wrapper {
  position: relative;
  padding: 40px 0;
}
.main_schools_wrapper .ms_feed_outer {
  position: relative;
  max-width: 1295px;
  margin: 0 auto;
}
.main_schools_wrapper .ms_feed_outer .arr {
  position: absolute;
  z-index: 5;
  top: 0;
  height: 100%;
  width: 64px;
  opacity: 0.8;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .main_schools_wrapper .ms_feed_outer .arr {
    display: none;
  }
}
.main_schools_wrapper .ms_feed_outer .arr i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
}
.main_schools_wrapper .ms_feed_outer .arr i svg {
  width: 16px;
  height: 16px;
}
.main_schools_wrapper .ms_feed_outer .arr.hidden {
  display: none;
}
.main_schools_wrapper .ms_feed_outer .arr.arr_prev {
  right: 100%;
}
.main_schools_wrapper .ms_feed_outer .arr.arr_next {
  left: 100%;
}
.main_schools_wrapper .ms_feed_outer .arr.arr_next i svg {
  transform: rotate(180deg);
}
.main_schools_wrapper .ms_feed_outer .ms_feed_wrapper {
  overflow: hidden;
  position: relative;
  height: 352px;
}
@media screen and (max-width: 1500px) {
  .main_schools_wrapper .ms_feed_outer {
    max-width: 1160px;
  }
}
.main_schools_wrapper h2 {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  max-width: 1295px;
  margin: 0 auto 10px;
}
.main_schools_wrapper .ms_feed {
  display: flex;
  position: absolute;
  white-space: nowrap;
  text-align: left;
  top: 0;
  overflow: hidden;
  transition: left ease-out 300ms;
  padding-bottom: 30px;
}
.main_schools_wrapper .ms_feed .ms_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 225px;
  margin: 0px 20px;
  text-align: center;
}
@media screen and (max-width: 1500px) {
  .main_schools_wrapper .ms_feed .ms_item {
    width: 200px;
    margin: 0px 15px;
  }
}
.main_schools_wrapper .ms_feed .ms_item a {
  text-decoration: none;
  color: unset;
}
.main_schools_wrapper .ms_feed .ms_item .ms_letter {
  font-size: 100px;
  line-height: 200px;
}
.main_schools_wrapper .ms_feed .ms_item .no_img {
  border: 2px solid #000;
  background-color: #FFF;
}
.main_schools_wrapper .ms_feed .ms_item .ms_avatar {
  box-sizing: border-box;
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 20px;
  overflow: hidden;
}
.main_schools_wrapper .ms_feed .ms_item .ms_avatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
}
.main_schools_wrapper .ms_feed .ms_item h5 {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 4px;
  white-space: normal;
  word-break: break-word;
}
.main_schools_wrapper .ms_feed .ms_item h6 {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 15px;
  opacity: 0.7;
}
.main_schools_wrapper .ms_feed .ms_item .ms_count {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.main_schools_wrapper .ms_feed .ms_item .ms_count a {
  color: #1F57C3;
}
@media screen and (max-width: 1500px) {
  .main_schools_wrapper h2 {
    max-width: 1160px;
  }
}
@media screen and (max-width: 1230px) {
  .main_schools_wrapper {
    max-width: 1280px;
    width: auto;
  }
  .main_schools_wrapper h2 {
    max-width: 1280px;
    width: auto;
    margin-left: 30px;
  }
  .main_schools_wrapper .ms_feed {
    margin-left: 30px;
  }
}
@media screen and (max-width: 780px) {
  .main_schools_wrapper h2 {
    margin-left: 20px;
  }
  .main_schools_wrapper .ms_feed {
    margin-left: 20px;
  }
}
@media screen and (max-width: 480px) {
  .main_schools_wrapper .ms_feed {
    overflow: auto;
    position: relative;
  }
}/*# sourceMappingURL=index.css.map */