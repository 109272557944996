.cl_requirements {
  background-color: #F5F5F5;
  padding: 68px 0 72px;
}
.cl_requirements .cl_centrer {
  display: flex;
}
.cl_requirements .cl_centrer h2 {
  width: 50%;
}
.cl_requirements .cl_centrer .requirements_block {
  width: 50%;
  font-size: 17px;
  line-height: 25px;
}
@media screen and (max-width: 1180px) {
  .cl_requirements .cl_centrer {
    display: block;
  }
  .cl_requirements .cl_centrer h2 {
    width: auto;
    margin-bottom: 40px;
  }
  .cl_requirements .cl_centrer .requirements_block {
    width: auto;
  }
}
@media screen and (max-width: 700px) {
  .cl_requirements {
    padding: 41px 0 50px;
  }
  .cl_requirements .cl_centrer h2 {
    margin-bottom: 31px;
  }
}/*# sourceMappingURL=index.css.map */