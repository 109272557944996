.greeting_player {
  position: relative;
  width: 100%;
  height: 56.25vw;
  overflow: hidden;
}
.greeting_player.playing .btn_play {
  opacity: 0;
}
.greeting_player.playing .preloader {
  opacity: 1;
}
.greeting_player.playing.started .btn_play,
.greeting_player.playing.started .poster,
.greeting_player.playing.started .preloader {
  display: none;
}
.greeting_player .poster {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  transition: linear 300ms;
}
.greeting_player .btn_play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  transition: linear 300ms;
}
.greeting_player .preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  opacity: 0;
  width: 59px;
  height: 64px;
  margin-left: -8px;
}
.greeting_player .preloader svg {
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=index.css.map */