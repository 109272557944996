.modal_auth_wrapper {
  max-height: 550px;
  overflow: auto;
}
.modal_auth_wrapper .payment_logos {
  font-size: 0;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.modal_auth_wrapper .payment_logos img {
  height: 20px;
}
.modal_auth_wrapper button {
  width: 100%;
}
.modal_auth_wrapper .modal_auth_form_toggler {
  text-align: center;
  padding: 16px 0;
}
.modal_auth_wrapper .modal_auth_form_toggler span {
  display: inline-block;
  vertical-align: top;
  margin: 0 24px;
  cursor: pointer;
  color: #C0C2C8;
}
.modal_auth_wrapper .modal_auth_form_toggler span:hover {
  color: #434A5C;
}
.modal_auth_wrapper .modal_auth_form_toggler span.active {
  color: #434A5C;
  border-bottom: 1px solid #434A5C;
  cursor: default;
}
.modal_auth_wrapper .sign_up_form, .modal_auth_wrapper .sign_in_form {
  padding: 16px;
  max-width: 300px;
}
.modal_auth_wrapper .sign_up_form .text_input, .modal_auth_wrapper .sign_in_form .text_input {
  margin-bottom: 24px;
  padding-left: 0;
  padding-right: 0;
}
.modal_auth_wrapper .sign_up_form .checkbox, .modal_auth_wrapper .sign_in_form .checkbox {
  margin-bottom: 24px;
}
.modal_auth_wrapper .sign_up_form .checkbox span, .modal_auth_wrapper .sign_in_form .checkbox span {
  font-size: 13px;
  line-height: 19px;
}
.modal_auth_wrapper .sign_up_form .checkbox ins, .modal_auth_wrapper .sign_in_form .checkbox ins {
  top: 3px;
}

.sign_up_wrapper {
  position: relative;
  width: 336px;
  margin: 0 auto;
  padding-bottom: 100px;
  text-align: center;
  padding-top: 90px;
}
@media screen and (max-width: 1050px) {
  .sign_up_wrapper {
    width: auto;
  }
}
.sign_up_wrapper.suw_confirm h1 {
  margin-bottom: 32px;
}
.sign_up_wrapper .sign_up_landing .radio_block {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper {
  flex-shrink: 0;
  width: 470px;
  margin: 0 16px 16px;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper {
  position: relative;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label {
  position: relative;
  display: block;
  padding: 48px 48px 40px 80px;
  text-align: left;
  cursor: pointer;
  height: calc(100% - 98px);
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label:hover {
  background-color: #F2F0F0;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label:hover p, .sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label:hover ul li {
  color: #434A5C;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label:hover ul li:before {
  background-color: #434A5C;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label:hover p.reg_join_free svg path {
  stroke: #434A5C;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label .point {
  position: absolute;
  left: 48px;
  top: 130px;
  background-color: #fff;
  border: 1px solid #C0C2C8;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label .radio_pic {
  margin-bottom: 24px;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  color: #C0C2C8;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label p.reg_join_free {
  font-weight: bold;
  position: relative;
  padding-right: 20px;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label p.reg_join_free ins {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label p.reg_join_free ins svg {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
  width: 24px;
  height: 24px;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label p.reg_join_free ins svg path {
  stroke: #C0C2C8;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label p + ul {
  margin-top: -24px;
  margin-bottom: 24px;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label ul li {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  padding-left: 16px;
  color: #C0C2C8;
  white-space: normal;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper label ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 4px;
  height: 4px;
  background-color: #C0C2C8;
  border-radius: 50%;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
  height: 0;
  left: -100px;
  top: -100px;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper input:checked + label {
  background-color: #F2F0F0;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper input:checked + label .point:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #040E26;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper input:checked + label p, .sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper input:checked + label ul li {
  color: #434A5C;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper input:checked + label ul li:before {
  background-color: #434A5C;
}
.sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper input:checked + label p.reg_join_free svg path {
  stroke: #434A5C;
}
.sign_up_wrapper .suf_header {
  padding-top: 112px;
  text-align: center;
  margin-bottom: 32px;
}
.sign_up_wrapper .suf_header img {
  margin-bottom: 16px;
  display: inline-block;
}
.sign_up_wrapper .suf_header h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
.sign_up_wrapper .sign_up_form {
  margin-bottom: 24px;
}
.sign_up_wrapper .alter_link {
  font-size: 14px;
  line-height: 14px;
  color: #434A5C;
  text-decoration: none;
  border-bottom: 1px solid rgba(60, 30, 30, 0.5);
}
@media screen and (max-width: 1050px) {
  .sign_up_wrapper {
    padding-top: 112px;
    width: auto;
    margin: 0 24px;
  }
  .sign_up_wrapper .sign_up_landing .radio_block {
    display: block;
  }
  .sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper {
    width: auto;
  }
  .sign_up_wrapper .auth_tabs {
    display: none;
  }
  .sign_up_wrapper .suf_header {
    padding-top: 0;
  }
}/*# sourceMappingURL=index.css.map */