.tos_confirmation_screen {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  min-width: 330px;
}
.tos_confirmation_screen .tos_centrer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
}
.tos_confirmation_screen .tcs_logo_holder {
  text-align: center;
  margin-bottom: 24px;
}
.tos_confirmation_screen p, .tos_confirmation_screen ul {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
}
.tos_confirmation_screen p a, .tos_confirmation_screen ul a {
  color: #040E26;
  text-decoration: none;
  border-bottom: 1px solid #040E26;
}
.tos_confirmation_screen ul li {
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;
}
.tos_confirmation_screen ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #040E26;
}
.tos_confirmation_screen .tcs_buttons_holder {
  text-align: center;
  padding-top: 16px;
}
.tos_confirmation_screen .tcs_buttons_holder button {
  margin: 0 8px;
}/*# sourceMappingURL=index.css.map */