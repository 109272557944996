.lesson_content .lesson_text_wrapper {
  max-width: 1170px;
  margin: 0 auto;
}
.lesson_content .lesson_text_wrapper h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 25px;
}
.lesson_content .lesson_text_wrapper h2 {
  font-size: 23px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 25px;
}
.lesson_content .lesson_text_wrapper h3 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  margin-bottom: 25px;
}
.lesson_content .lesson_text_wrapper h1 span, .lesson_content .lesson_text_wrapper h2 span, .lesson_content .lesson_text_wrapper h3 span {
  font-size: unset !important;
  color: unset !important;
  font-family: unset !important;
  background-color: unset !important;
}
.lesson_content .lesson_text_wrapper p, .lesson_content .lesson_text_wrapper ul, .lesson_content .lesson_text_wrapper ol, .lesson_content .lesson_text_wrapper blockquote {
  margin-bottom: 25px;
  font-size: 17px;
  line-height: 25px;
}
.lesson_content .lesson_text_wrapper p span, .lesson_content .lesson_text_wrapper ul span, .lesson_content .lesson_text_wrapper ol span, .lesson_content .lesson_text_wrapper blockquote span {
  font-size: unset !important;
  color: unset !important;
  font-family: unset !important;
  background-color: unset !important;
}
.lesson_content .lesson_text_wrapper blockquote {
  padding: 15px 45px;
  font-style: italic;
  background-color: #f5f5f5;
}
.lesson_content .lesson_text_wrapper p + p {
  margin-top: -18px;
}
.lesson_content .lesson_text_wrapper ul li, .lesson_content .lesson_text_wrapper ol li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 25px;
}
.lesson_content .lesson_text_wrapper ul li:last-of-type, .lesson_content .lesson_text_wrapper ol li:last-of-type {
  margin-bottom: 0;
}
.lesson_content .lesson_text_wrapper ul li:before, .lesson_content .lesson_text_wrapper ol li:before {
  position: absolute;
  left: 5px;
}
.lesson_content .lesson_text_wrapper ul li:before {
  content: "";
  top: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #2450FF;
}
.lesson_content .lesson_text_wrapper ol {
  counter-reset: line-number;
}
.lesson_content .lesson_text_wrapper ol li {
  counter-increment: line-number;
}
.lesson_content .lesson_text_wrapper ol li:before {
  content: counter(line-number) ".";
}/*# sourceMappingURL=text.css.map */