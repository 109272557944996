.cl_header .header_bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.cl_header .header_bg .cover_wrapper {
  background-color: #000;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.cl_header .header_bg .cover_wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  opacity: 0.6;
}
@media screen and (max-width: 1180px) {
  .cl_header .header_bg {
    height: 630px;
    top: 0;
  }
  .cl_header .header_bg .cover_wrapper img {
    height: 100%;
    width: auto;
  }
}
@media screen and (max-width: 1180px) {
  .cl_header .header_bg {
    height: 500px;
    top: 0;
  }
}/*# sourceMappingURL=bg.css.map */