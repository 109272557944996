.landing_wrapper .l_content {
  width: 1340px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}
.landing_wrapper .l_content h2 {
  font-size: 45px;
  line-height: 70px;
  font-weight: 500;
}
@media screen and (max-width: 1440px) {
  .landing_wrapper .l_content {
    width: auto;
    margin: 0 50px;
  }
}
@media screen and (max-width: 600px) {
  .landing_wrapper .l_content {
    margin: 0 25px;
  }
  .landing_wrapper .l_content h2 {
    font-size: 24px;
    line-height: 30px;
  }
}
.landing_wrapper .l_centrer {
  max-width: 1340px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}
@media screen and (max-width: 1440px) {
  .landing_wrapper .l_centrer {
    width: auto;
    margin: 0 50px;
  }
}
@media screen and (max-width: 600px) {
  .landing_wrapper .l_centrer {
    margin: 0 25px;
  }
}
.landing_wrapper button {
  background-color: #2450FF;
  border: 0 solid;
  padding: 21px 35px 24px;
  border-radius: 30px;
}
.landing_wrapper button:disabled {
  opacity: 0.5;
}
@media screen and (max-width: 600px) {
  .landing_wrapper button {
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    padding: 13px 0;
    width: 145px;
  }
}/*# sourceMappingURL=index.css.map */