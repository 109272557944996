.lp_special_wrapper {
  padding: 114px 0 147px;
  overflow: hidden;
  position: relative;
}
.lp_special_wrapper .lp_bg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 635px;
  background: #F8F8FA; /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom, #F8F8FA 0%, #fff 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir ); /* IE6-9 */
}
.lp_special_wrapper .l_content {
  text-align: center;
}
.lp_special_wrapper .l_content h2 {
  margin-bottom: 46px;
}
.lp_special_wrapper .l_content .special_description {
  margin: 0 auto 67px;
  font-size: 18px;
  line-height: 25px;
  max-width: 570px;
}
.lp_special_wrapper .l_content .plate {
  position: relative;
  margin: 0 auto 40px;
  padding: 60px 40px;
  width: 490px;
  border: 1px solid #F2F2F5;
  border-radius: 20px;
  background: #fff; /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom, #fff 0%, #F8F8FA 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir ); /* IE6-9 */
}
.lp_special_wrapper .l_content .plate .bubble_left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  margin-right: 1px;
}
.lp_special_wrapper .l_content .plate .bubble_right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 1px;
  margin-top: -40px;
}
.lp_special_wrapper .l_content .plate .plan_name {
  color: #6DCEF8;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 19px;
}
.lp_special_wrapper .l_content .plate .plan_old_price {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 12px;
  text-decoration: line-through;
  opacity: 0.5;
}
.lp_special_wrapper .l_content .plate .plan_price {
  font-size: 45px;
  line-height: 45px;
  font-weight: 600;
  margin-bottom: 10px;
}
.lp_special_wrapper .l_content .plate .plan_period {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 36px;
}
.lp_special_wrapper .l_content .plate .plan_options {
  margin: 0 auto;
  border-top: 1px solid #E6E6E6;
  white-space: normal;
  text-align: left;
  padding-top: 45px;
  margin-bottom: 57px;
}
.lp_special_wrapper .l_content .plate .plan_options li {
  position: relative;
  padding-left: 28px;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 25px;
}
.lp_special_wrapper .l_content .plate .plan_options li:last-of-type {
  margin-bottom: 0;
}
.lp_special_wrapper .l_content .plate .plan_options li .tick {
  position: absolute;
  left: 6px;
  top: 9px;
  transform: rotate(-45deg);
}
.lp_special_wrapper .l_content .plate .plan_options li .tick:before {
  content: "";
  height: 7px;
  width: 11px;
  border-left: 1px solid #0DA750;
  border-bottom: 1px solid #0DA750;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lp_special_wrapper .l_content .stars {
  max-width: 570px;
  margin: 0 auto;
}
.lp_special_wrapper .l_content .stars p {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #444750;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .lp_special_wrapper {
    padding: 50px 0 55px;
  }
  .lp_special_wrapper .l_content h2 {
    margin-bottom: 34px;
  }
  .lp_special_wrapper .l_content .special_description {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 47px;
  }
  .lp_special_wrapper .l_content .plate {
    width: auto;
  }
  .lp_special_wrapper .l_content .plate .plan_name {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 26px;
  }
  .lp_special_wrapper .l_content .plate .plan_old_price {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 8px;
  }
  .lp_special_wrapper .l_content .plate .plan_price {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 8px;
  }
  .lp_special_wrapper .l_content .plate .plan_period {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 36px;
  }
  .lp_special_wrapper .l_content .plate .plan_options {
    padding-top: 29px;
  }
  .lp_special_wrapper .l_content .plate .plan_options li {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
    padding-left: 19px;
  }
  .lp_special_wrapper .l_content .plate .plan_options li .tick {
    top: 8px;
  }
  .lp_special_wrapper .l_content .plate .plan_options li .tick:before {
    height: 5px;
    width: 8px;
  }
  .lp_special_wrapper .l_content .stars p {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 15px;
  }
}/*# sourceMappingURL=index.css.map */