.header_user_section {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  font-size: 0;
}
.header_user_section .auth_links {
  white-space: nowrap;
}
.header_user_section .auth_links a {
  display: inline-block;
  vertical-align: top;
  margin-left: 60px;
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: #040E26;
}
.header_user_section .user_authorized {
  position: relative;
  white-space: nowrap;
}
.header_user_section .user_authorized .ua_menu_btn {
  position: absolute;
  min-width: 27px;
  height: 27px;
  background: rgba(31, 87, 195, 0.1);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_user_section .user_authorized .ua_notif {
  text-decoration: none;
  right: calc(100% + 80px);
  max-width: 100px;
}
.header_user_section .user_authorized .ua_notif_counter {
  color: #fff;
  background-color: #E933C3;
  border-radius: 16px;
  margin-left: 6px;
  margin-right: 6px;
  width: 30px;
  height: 19px;
  text-align: center;
  line-height: 19px;
  font-size: 12px;
}
.header_user_section .user_authorized .ua_resume {
  right: calc(100% + 40px);
  text-decoration: none;
}
.header_user_section .user_authorized .ua_username {
  white-space: normal;
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 10px;
  line-height: 24px;
  text-transform: uppercase;
  padding: 0 20px 0 8px;
  letter-spacing: 0.05em;
  margin-top: 8px;
  cursor: pointer;
}
.header_user_section .user_authorized .ua_username.opened .ua_dropdown {
  display: block;
}
.header_user_section .user_authorized .ua_username .ua_username_text {
  color: #1F57C3;
}
.header.active .header_user_section .user_authorized .ua_username .ua_username_text {
  color: #040E26 !important;
}
.header_user_section .user_authorized .ua_username .ua_userpic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 100%;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #F2F0F0;
}
.header_user_section .user_authorized .ua_username .ua_userpic .avatar {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border-radius: 50%;
}
.header_user_section .user_authorized .ua_username .ua_userpic .avatar img {
  width: 100%;
}
.header_user_section .user_authorized .ua_username .ua_userpic span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 1px;
  color: #040E26;
  letter-spacing: 0;
}
.header_user_section .user_authorized .ua_username .dropdown_arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin-top: -1px;
}
.header_user_section .user_authorized .ua_username .dropdown_arrow path {
  fill: #040E26;
}
.header.active .header_user_section .user_authorized .ua_username .dropdown_arrow path {
  fill: #040E26 !important;
}
.header_user_section .user_authorized .ua_username .ua_dropdown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  top: 100%;
  margin-top: 18px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(38, 4, 4, 0.25);
  border-radius: 8px;
  min-width: 150px;
  display: none;
}
.header_user_section .user_authorized .ua_username .ua_dropdown .tool_tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
}
.header_user_section .user_authorized .ua_username .ua_dropdown a {
  display: block;
  text-decoration: none;
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  padding: 18px 16px;
  border-bottom: 1px solid #E6E7EA;
  color: #040E26;
}
.header_user_section .user_authorized .ua_username .ua_dropdown a.ua_logout, .header_user_section .user_authorized .ua_username .ua_dropdown a.red {
  color: #C43333;
}
.header_user_section .user_authorized .ua_username .ua_dropdown a:last-of-type {
  border-bottom: 0 solid;
}
@media screen and (max-width: 480px) {
  .header_user_section {
    display: none;
  }
}