.l_info_bar {
  z-index: 90;
  top: 70px;
  width: 100%;
  position: fixed;
  height: 48px;
  background: white;
  border-bottom: 1px rgba(0, 0, 0, 0.5) solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 25px;
  color: #040E26;
}
.l_info_bar_title {
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .l_info_bar {
    flex-direction: column;
    height: auto;
    gap: 2px;
  }
}
@media screen and (max-width: 480px) {
  .l_info_bar {
    display: none;
  }
}