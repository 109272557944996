.edit_lesson_type_text dt {
  padding-top: 12px;
}
.edit_lesson_type_text .rdw-editor-toolbar {
  border: 0 solid;
  padding-left: 0;
  margin-left: -4px;
}
.edit_lesson_type_text textarea,
.edit_lesson_type_text .demo-editor {
  display: block;
  width: 100%;
  height: 200px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.edit_lesson_type_text textarea.placeholder,
.edit_lesson_type_text .demo-editor.placeholder {
  color: #C0C2C8;
}
.edit_lesson_type_text textarea:-moz-placeholder,
.edit_lesson_type_text .demo-editor:-moz-placeholder {
  color: #C0C2C8;
}
.edit_lesson_type_text textarea::-moz-placeholder,
.edit_lesson_type_text .demo-editor::-moz-placeholder {
  color: #C0C2C8;
}
.edit_lesson_type_text textarea:-ms-input-placeholder,
.edit_lesson_type_text .demo-editor:-ms-input-placeholder {
  color: #C0C2C8;
}
.edit_lesson_type_text textarea::-webkit-input-placeholder,
.edit_lesson_type_text .demo-editor::-webkit-input-placeholder {
  color: #C0C2C8;
}
.edit_lesson_type_text textarea h1,
.edit_lesson_type_text .demo-editor h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 24px;
}
.edit_lesson_type_text textarea h2,
.edit_lesson_type_text .demo-editor h2 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 24px;
}
.edit_lesson_type_text textarea h3,
.edit_lesson_type_text .demo-editor h3 {
  font-size: 17px;
  line-height: 25px;
  font-weight: 600;
  margin-bottom: 21px;
}

.add-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  max-height: 245px;
  border: 1px solid #F1F1F1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #BFBDBD;
}
.add-link-modal_errorText {
  color: #C33333;
  margin-bottom: 5px;
}
.add-link-modal label {
  margin-bottom: 10px;
}
.add-link-modal input {
  margin-bottom: 10px;
}
.add-link-modal button {
  width: 100px;
}