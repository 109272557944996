.player_v2_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
  text-align: center;
}
.player_v2_wrapper .react-player__preview {
  max-width: 1000px;
  margin: 0 auto;
}
.player_v2_wrapper .react-player-ad {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.player_v2_wrapper .react-player-ad.hidden {
  display: none;
}
@media screen and (max-width: 600px) {
  .player_v2_wrapper {
    height: 240px;
  }
}/*# sourceMappingURL=index.css.map */