.edit_create_wrapper .cc_progress {
  font-size: 0;
  white-space: nowrap;
  margin-bottom: 56px;
  padding-top: 48px;
}
.edit_create_wrapper .cc_progress li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-right: 24px;
  margin-right: 24px;
  height: 24px;
  color: #E6E7EA;
  border-right: 1px solid #C0C2C8;
}
.edit_create_wrapper .cc_progress li a {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
  color: #434A5C;
  text-decoration: none;
}
.edit_create_wrapper .cc_progress li a:hover {
  color: #C0C2C8;
}
.edit_create_wrapper .cc_progress li span {
  cursor: default;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 24px;
}
.edit_create_wrapper .cc_progress li.active a {
  border-bottom: 1px solid #C0C2C8;
}
.edit_create_wrapper .cc_progress li.active a:hover {
  color: #434A5C;
}
.edit_create_wrapper .cc_progress li:last-of-type {
  border-right: 0 solid;
  margin-right: 0;
  padding-right: 0;
}/*# sourceMappingURL=tabs.css.map */