.student_profile_form_preloader {
  z-index: 999999;
}
.student_profile_form_semi_title {
  margin: 10px 0px;
  color: #96A3AE;
  font-size: 12px;
  font-weight: 500;
}
.student_profile_form .link_info {
  max-width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 8px;
  top: -50px;
  left: 130px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.student_profile_form .link_info_tip {
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  background: lightgray;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #1F57C3;
}
.student_profile_form .link_info_tip_content {
  cursor: auto !important;
  position: absolute;
  z-index: 9999;
  top: 25px;
  right: 0px;
  width: 250px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}
@media screen and (max-width: 480px) {
  .student_profile_form .link_info {
    top: 0px;
    left: 0px;
    margin-bottom: 15px;
  }
}
.student_profile_form_documents_container {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.student_profile_form_document {
  display: flex;
  flex-direction: row;
}
.student_profile_form_document_icon {
  border: 1px solid gray;
  width: 48px;
  height: 48px;
}
.student_profile_form_document_title {
  font-weight: bold;
  font-size: 14px;
  margin-left: 8px;
}
.student_profile_form_document_attach {
  font-size: 12px;
  cursor: pointer;
  margin-top: 8px;
  margin-left: 8px;
}
.student_profile_form_topic {
  padding-bottom: 15px;
  max-width: 650px;
}
.student_profile_form_topic_title {
  background-color: #d2d1db;
  height: 32px;
  line-height: 32px;
  padding-left: 12px;
}
.student_profile_form_topic_wrapper {
  margin-left: 140px;
}
@media screen and (max-width: 480px) {
  .student_profile_form_topic_wrapper {
    margin-left: 0px;
  }
}
.student_profile_form_topic_content {
  width: 360px;
  margin-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
@media screen and (max-width: 480px) {
  .student_profile_form_topic_content {
    width: 320px;
    margin-top: 10px;
  }
}
.student_profile_form_topic_content_item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: #1F57C3;
  border-radius: 20px;
  color: #FFFFFF;
  padding: 4px 10px;
  height: 23px;
  line-height: 23px;
  font-weight: 400;
  font-size: 12px;
}
.student_profile_form_topic_content_xmark {
  cursor: pointer;
  width: 9px;
  height: 9px;
  margin-left: 6px;
  margin-top: 1px;
  background-repeat: no-repeat;
  background-size: contain;
}
.student_profile_form_topic_interests {
  flex-wrap: wrap;
  z-index: 1000;
  box-sizing: border-box;
  padding: 8px 10px;
  gap: 4px;
  position: absolute;
  display: none;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 360px;
  background-color: #FFFFFF;
  min-height: 40px;
  max-height: 400px;
  overflow: auto;
}
@media screen and (max-width: 480px) {
  .student_profile_form_topic_interests {
    width: 320px;
  }
}
.student_profile_form_topic_interests_item {
  cursor: pointer;
  height: 23px;
  display: flex;
  border: 1px solid #96A3AE;
  color: #96A3AE;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 4px 10px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
}
.student_profile_form_topic_interests_item_selected {
  cursor: pointer;
  height: 23px;
  display: flex;
  border: 1px solid #1F57C3;
  color: #1F57C3;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 4px 10px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
}
.student_profile_form_topic_search {
  display: flex;
  align-items: center;
  width: 360px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0px 10px;
}
@media screen and (max-width: 480px) {
  .student_profile_form_topic_search {
    width: 320px;
  }
}
.student_profile_form_topic_search_icon {
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
}
.student_profile_form_topic_search input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  font-size: 16px;
  line-height: 19px;
  margin-left: 10px;
  color: #1F57C3;
}
.student_profile_form_topic_select {
  cursor: pointer;
  width: 120px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.student_profile_form_topic_select_arrow {
  margin-left: 6px;
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
}
.student_profile_form_topic_topics {
  z-index: 1000;
  box-sizing: border-box;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  position: absolute;
  display: none;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 200px;
  background-color: #FFFFFF;
  min-height: 40px;
  max-height: 400px;
  overflow: auto;
}
.student_profile_form_topic_topics_item {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.student_profile_form_topic_topics_item_label {
  margin-left: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.student_profile_form_topic_topics_item_checkmark_selected {
  background-color: #1F57C3;
}
.student_profile_form_topic_topics_item_checkmark_selected_sign {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.student_profile_form_topic_topics_item_checkmark {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.student_profile_form .selected_item {
  background-color: #1F57C3;
}
.student_profile_form .selected_item img {
  filter: brightness(2);
}
.student_profile_form_socials_container {
  display: flex;
  flex-wrap: wrap;
}
.student_profile_form_g_selector {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  margin-left: 140px;
}
@media screen and (max-width: 480px) {
  .student_profile_form_g_selector {
    margin-left: 0px;
    justify-content: center;
  }
}
.student_profile_form_g_selector_item {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 95px;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.student_profile_form_g_selector_item img {
  height: 24px;
  width: 24px;
  filter: brightness(1);
}
.student_profile_form_divider {
  margin-top: 48px;
  margin-bottom: 48px;
}
.student_profile_form_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
@media screen and (max-width: 480px) {
  .student_profile_form_row {
    flex-direction: column;
  }
}
.student_profile_form_save_button {
  margin-left: 300px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1F57C3;
  border-radius: 50px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  width: 107px;
  height: 29px;
}
.student_profile_form_save_button:hover {
  color: black;
  background-color: lightgray;
}
@media screen and (max-width: 480px) {
  .student_profile_form_save_button {
    margin-left: 50px;
    overflow: visible;
  }
}
.student_profile_form_password_button {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
  background-color: #2350ff;
  width: 120px;
  height: 48px;
  border-radius: 12px;
  color: #FFF;
}
.student_profile_form_password_button:hover {
  color: black;
  background-color: lightgray;
}
.student_profile_form_delete_button {
  margin-left: 300px;
  margin-top: 35px;
  margin-bottom: 50px;
  width: 100px;
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  cursor: pointer;
  color: #96A3AE;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.student_profile_form_delete_button:hover {
  color: black;
  opacity: 0.7;
}
.student_profile_form .student_profile_layout {
  padding: 16px;
  margin-top: 30px;
}
.student_profile_form .student_profile_layout_statuses {
  display: flex;
  flex-direction: row;
  gap: 60px;
  margin-bottom: 45px;
}
@media screen and (max-width: 480px) {
  .student_profile_form .student_profile_layout_statuses {
    flex-direction: column;
    gap: 20px;
  }
}
.student_profile_form .student_profile_layout_statuses_status {
  display: flex;
  align-items: center;
  gap: 10px;
}
.student_profile_form .student_profile_layout_statuses_status_icon.not-filled {
  filter: brightness(1.2) grayscale(1);
  color: rgba(0, 0, 0, 0.33);
}
.student_profile_form .student_profile_layout_statuses_status_icon {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
.student_profile_form .student_profile_layout_statuses_status_icon_def {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background: #ECECEC;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
.student_profile_form .student_profile_layout_statuses_status_icon_def div {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
}
.student_profile_form .student_profile_layout_statuses_status_link {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #1F57C3;
}
.student_profile_form .student_profile_layout_statuses_status_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.student_profile_form .student_profile_layout_statuses_status_hint {
  cursor: pointer;
  position: relative;
  width: 18px;
  height: 18px;
  background: #D9D9D9;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #1F57C3;
}
.student_profile_form .student_profile_layout_statuses_status_hint_text {
  z-index: 1000;
  height: 100px;
  position: absolute;
  top: 30px;
  text-align: initial;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}
.student_profile_form .student_profile_layout_statuses_status_hint_text_left {
  width: 233px;
  left: 0px;
}
@media screen and (max-width: 480px) {
  .student_profile_form .student_profile_layout_statuses_status_hint_text_left {
    z-index: 3;
    left: initial;
  }
}
.student_profile_form .student_profile_layout_statuses_status_hint_text_right {
  width: 370px;
  right: 0px;
}
@media screen and (max-width: 480px) {
  .student_profile_form .student_profile_layout_statuses_status_hint_text_right {
    z-index: 3;
    right: -120px;
  }
}
.student_profile_form .student_profile_layout_address {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
}
.student_profile_form .student_profile_layout_heading {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  margin-bottom: 10px;
}
.student_profile_form .student_profile_layout_account_heading {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
  margin-bottom: 20px;
  max-width: 600px;
}
.student_profile_form .student_profile_layout_info {
  margin-bottom: 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
.student_profile_form .socials {
  margin-bottom: 16px;
}
.student_profile_form_input {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 360px;
  padding: 8px 12px 8px 20px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
  margin-right: 24px;
  margin-left: 140px;
  height: 40px;
  box-sizing: border-box;
}
@media screen and (max-width: 480px) {
  .student_profile_form_input {
    margin-left: 0px;
    width: 320px;
  }
}
.student_profile_form_input_withPhone {
  justify-content: flex-start;
  gap: 10px;
}
.student_profile_form_input_withPhone input {
  width: 200px !important;
}
.student_profile_form_input_phone {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  border-right: 1px solid rgba(150, 163, 174, 0.2);
  padding-right: 10px;
}
.student_profile_form_input_phone_flag {
  width: 23px;
  height: 16px;
  background-repeat: no-repeat;
}
.student_profile_form_input_phone_select {
  width: 16px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  rotate: 90deg;
  background-position-x: center;
}
.student_profile_form_input_edit {
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 480px) {
  .student_profile_form_input_edit {
    background-size: initial;
    width: 21px;
    height: 18px;
  }
}
.student_profile_form_input_label {
  width: 160px;
  font-size: 12px;
  color: #96A3AE;
  cursor: initial;
}
.student_profile_form_input_date {
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
}
.student_profile_form_input_date_placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.student_profile_form_input input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  width: 330px;
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
}
.student_profile_form_input_arrow {
  cursor: pointer;
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  rotate: 90deg;
  background-position: center;
}
.student_profile_form_input select {
  z-index: 400;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  width: 330px;
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
}
.student_profile_form .student_profile_tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: rgba(150, 163, 174, 0.1);
  border-radius: 50px;
  width: 226px;
  height: 30px;
  line-height: 30px;
  margin: auto;
  font-size: 12px;
  color: #96A3AE;
}
.student_profile_form .student_profile_tabs_account {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
}
.student_profile_form .student_profile_tabs_data {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
}
.student_profile_form .student_profile_tabs .selected_tab {
  color: #1F57C3;
  background: #FFFFFF;
  border-radius: 50px;
  height: 26px;
}
.student_profile_form .student_profile_tabs div {
  cursor: pointer;
}

.student_profile_form_data_documents_container {
  display: flex;
  flex-direction: column;
}
.student_profile_form_data_document {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(150, 163, 174, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
  width: 360px;
  margin-left: 140px;
  padding: 8px;
}
@media screen and (max-width: 480px) {
  .student_profile_form_data_document {
    margin-left: 0px;
    margin-top: 8px;
    width: 320px;
  }
}
.student_profile_form_data_document_icon {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.student_profile_form_data_document_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  width: 290px;
  margin-left: 17px;
  color: #96A3AE;
}
.student_profile_form_data_document_accepted {
  background: #2450FF !important;
}
.student_profile_form_data_document_accepted .student_profile_form_data_document_icon {
  filter: brightness(2);
}
.student_profile_form_data_document_accepted .student_profile_form_data_document_text {
  color: #FFF !important;
}
.student_profile_form_data_document {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(150, 163, 174, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  height: 40px;
  width: 360px;
  margin-left: 140px;
  padding: 8px;
}
@media screen and (max-width: 480px) {
  .student_profile_form_data_document {
    margin-left: 0px;
    margin-top: 8px;
    width: 320px;
  }
}
.student_profile_form_data_document_icon {
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center;
}
.student_profile_form_data_document_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  width: 290px;
  margin-left: 17px;
  color: #96A3AE;
}
.student_profile_form_data .selected_item {
  background-color: lightgreen;
}
.student_profile_form_data .selected_item img {
  filter: invert(1);
}
.student_profile_form_data_divider {
  width: 1px;
  height: 1px;
  margin-top: 48px;
  margin-bottom: 48px;
}
.student_profile_form_data_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
}
.student_profile_form_data .student_profile_layout {
  padding: 16px;
  margin-top: 30px;
}
.student_profile_form_data .student_profile_layout_heading {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 900;
}
.student_profile_form_data .student_profile_layout_info {
  margin-bottom: 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
.student_profile_form_data_input {
  display: flex;
  flex-direction: column;
  width: 240px;
  padding: 8px 20px;
  border: 1px solid gray;
  border-radius: 2px;
  margin-right: 24px;
}
.student_profile_form_data_input_label {
  font-size: 14px;
  color: gray;
  cursor: initial;
}
.student_profile_form_data_input input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
}

.student_profile_form_topic_interests::-webkit-scrollbar-track, .student_profile_form_topic_topics::-webkit-scrollbar-track, select::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}

.student_profile_form_topic_interests::-webkit-scrollbar, .student_profile_form_topic_topics::-webkit-scrollbar, select::-webkit-scrollbar {
  display: block;
  width: 14px;
  background-color: none;
}

.student_profile_form_topic_interests::-webkit-scrollbar-thumb, .student_profile_form_topic_topics::-webkit-scrollbar-thumb, select::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #EAEDEF;
}