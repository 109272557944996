.cl_description {
  position: relative;
  padding: 40px 0 80px;
}
.cl_description .cl_centrer {
  padding: 68px 100px 72px;
  width: 970px;
  border: 1px solid #E4E5E8;
  border-radius: 10px;
}
.cl_description .cl_anchor {
  position: absolute;
  top: -70px;
}
.cl_description.folded .course_description {
  max-height: 200px;
  overflow: hidden;
}
.cl_description.folded .course_description:after {
  display: block;
}
.cl_description .fold_switcher {
  position: relative;
  display: inline-block;
  margin-top: 55px;
  padding-right: 10px;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  cursor: pointer;
}
.cl_description .fold_switcher svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-top: 1px;
}
.cl_description .course_description {
  position: relative;
  color: #040E26;
}
.cl_description .course_description:after {
  display: none;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  background: rgba(255, 255, 255, 0.5019607843); /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5019607843) 0%, #ffffff 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir ); /* IE6-9 */
}
.cl_description .course_description p, .cl_description .course_description ul, .cl_description .course_description ol {
  margin-bottom: 43px;
  font-size: 17px;
  line-height: 25px;
}
.cl_description .course_description p + p {
  margin-top: -18px;
}
.cl_description .course_description ul li, .cl_description .course_description ol li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 25px;
}
.cl_description .course_description ul li:last-of-type, .cl_description .course_description ol li:last-of-type {
  margin-bottom: 0;
}
.cl_description .course_description ul li:before, .cl_description .course_description ol li:before {
  position: absolute;
  left: 5px;
}
.cl_description .course_description ul li:before {
  content: "";
  top: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #2450FF;
}
.cl_description .course_description ol {
  counter-reset: line-number;
}
.cl_description .course_description ol li {
  counter-increment: line-number;
}
.cl_description .course_description ol li:before {
  content: counter(line-number) ".";
}
@media screen and (max-width: 1180px) {
  .cl_description .cl_centrer {
    width: auto;
    padding: 68px 50px 72px;
  }
}
@media screen and (max-width: 700px) {
  .cl_description {
    padding-bottom: 50px;
  }
  .cl_description .cl_anchor {
    position: absolute;
    top: -60px;
  }
  .cl_description .cl_centrer {
    padding: 41px 20px 37px;
  }
  .cl_description .cl_centrer h2 {
    margin-bottom: 30px;
  }
}/*# sourceMappingURL=index.css.map */