.subscription_plans_wrapper {
  padding-top: 128px;
  text-align: center;
}
.subscription_plans_wrapper .spw_inner {
  font-size: 0;
  white-space: nowrap;
  overflow: auto;
  padding-top: 40px;
}
.subscription_plans_wrapper .sp_item {
  display: inline-block;
  vertical-align: top;
  width: 320px;
  margin: 0 8px;
  white-space: normal;
  text-align: left;
}
.subscription_plans_wrapper .sp_item .sp_item_inner {
  padding: 46px 23px 46px 46px;
}
.subscription_plans_wrapper .sp_item .spi_price {
  color: #040E26;
  margin-bottom: 16px;
}
.subscription_plans_wrapper .sp_item .spi_price .spi_price_old,
.subscription_plans_wrapper .sp_item .spi_price .spi_price_value {
  font-weight: 200;
  position: relative;
  display: inline-block;
}
.subscription_plans_wrapper .sp_item .spi_price .spi_price_old {
  font-size: 48px;
  line-height: 48px;
}
.subscription_plans_wrapper .sp_item .spi_price .spi_price_old.spi_empty {
  opacity: 0;
}
.subscription_plans_wrapper .sp_item .spi_price .spi_price_old:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  height: 2px;
  background-color: #C33333;
}
.subscription_plans_wrapper .sp_item .spi_price .spi_price_value {
  font-size: 72px;
  line-height: 72px;
}
.subscription_plans_wrapper .sp_item .spi_price .spi_price_value .spi_price_term {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
}
.subscription_plans_wrapper .sp_item .spi_title {
  color: #040E26;
  width: 100%;
  padding-right: 23px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #000000;
  font-size: 24px;
  line-height: 32px;
}
.subscription_plans_wrapper .sp_item .spi_list li {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #434A5C;
  position: relative;
}
.subscription_plans_wrapper .sp_item .spi_list li svg {
  position: absolute;
  left: -32px;
  top: 3px;
}
.subscription_plans_wrapper .price_disclamer {
  max-width: 640px;
  padding-top: 48px;
  margin: 0 auto 48px;
  text-align: center;
  font-size: 13px;
  line-height: 19px;
}/*# sourceMappingURL=school-plans.css.map */