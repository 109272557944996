.blog_feed_item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 402px;
  height: 457px;
  padding: 6px 6px 20px 6px;
  margin-right: 20px;
  white-space: normal;
  text-align: left;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border-bottom: 8px solid #fff;
}
.blog_feed_item a {
  text-decoration: none;
  color: unset;
}
.blog_feed_item .bfi_cover {
  position: relative;
  display: block;
  z-index: 0;
  height: 300px;
  overflow: hidden;
  border-radius: 6px;
}
.blog_feed_item .bfi_cover img {
  position: relative;
  z-index: 2;
  height: 100%;
}
.blog_feed_item .bfi_cover .img_plug {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}
.blog_feed_item .bfi_info {
  display: flex;
  justify-content: space-between;
  margin: 0 14px;
  padding: 10px 0 14px;
  font-size: 10px;
  line-height: 13px;
}
.blog_feed_item .bfi_info .bfi_date {
  color: #1F57C3;
}
.blog_feed_item .bfi_info .bfi_author {
  color: rgba(0, 0, 0, 0.6980392157);
  margin-left: 20px;
}
.blog_feed_item h3 {
  margin: 0 14px 10px;
  font-size: 18px;
  line-height: 23px;
}
.blog_feed_item h4 {
  font-size: 12px;
  line-height: 15px;
  margin: 0 14px;
}
@media screen and (max-width: 1500px) {
  .blog_feed_item {
    width: 356px;
  }
}
.search_articles .blog_feed_item, .profile_dashboard .blog_feed_item {
  border-bottom: 6px solid #fff;
  width: 240px;
  height: 404px;
  margin-bottom: 40px;
}
.search_articles .blog_feed_item .bfi_cover, .profile_dashboard .blog_feed_item .bfi_cover {
  height: 160px;
}
@media screen and (max-width: 780px) {
  .blog_feed_item {
    border-bottom: 6px solid #fff;
    width: 240px;
    height: 404px;
  }
  .blog_feed_item .bfi_cover {
    height: 160px;
  }
}