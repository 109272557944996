.edit_pencil_icon {
  position: relative;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(79, 86, 103, 0.1019607843);
  border-radius: 50%;
}
.edit_pencil_icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 14px;
}

.icon_course_level circle {
  fill: none;
  stroke: #fff;
  opacity: 0.5;
}
.icon_course_level circle.filled {
  fill: #fff;
  opacity: 1;
}

.course_rating_wrapper {
  white-space: nowrap;
}
.course_rating_wrapper span, .course_rating_wrapper svg {
  display: inline-block;
  vertical-align: top;
}
.course_rating_wrapper span {
  margin-left: 4px;
}

.icon_course_rating {
  vertical-align: top;
  margin-top: -1px;
  margin-right: 7px;
}
.icon_course_rating path {
  fill: #fff;
  opacity: 0.5;
}
.icon_course_rating path.filled {
  opacity: 1;
}/*# sourceMappingURL=icons.css.map */