.blog_post_wrapper {
  position: relative;
}
.blog_post_wrapper .blog_post_pattern {
  height: 160px;
}
.blog_post_wrapper .blog_post_inner {
  padding-top: 16px;
  text-align: left;
  max-width: 1170px;
  margin: 0 auto;
}
.blog_post_wrapper .blog_post_inner .post_edit_button {
  position: absolute;
  top: 180px;
}
.blog_post_wrapper .blog_post_inner h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin-bottom: 25px;
  text-align: center;
}
.blog_post_wrapper .blog_post_inner .post_image {
  text-align: center;
  margin-bottom: 24px;
  padding-top: 80px;
}
.blog_post_wrapper .blog_post_inner .post_image img {
  margin: 0 auto;
  max-width: 600px;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner h2 {
  font-size: 23px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 25px;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner h3 {
  font-size: 20px;
  line-height: 27px;
  font-weight: 500;
  margin-bottom: 25px;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner h1 span, .blog_post_wrapper .blog_post_inner .blog_post_inner h2 span, .blog_post_wrapper .blog_post_inner .blog_post_inner h3 span {
  font-size: unset !important;
  color: unset !important;
  font-family: unset !important;
  background-color: unset !important;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner p, .blog_post_wrapper .blog_post_inner .blog_post_inner ul, .blog_post_wrapper .blog_post_inner .blog_post_inner ol, .blog_post_wrapper .blog_post_inner .blog_post_inner blockquote {
  margin-bottom: 25px;
  font-size: 17px;
  line-height: 25px;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner p span, .blog_post_wrapper .blog_post_inner .blog_post_inner ul span, .blog_post_wrapper .blog_post_inner .blog_post_inner ol span, .blog_post_wrapper .blog_post_inner .blog_post_inner blockquote span {
  font-size: unset !important;
  color: unset !important;
  font-family: unset !important;
  background-color: unset !important;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner blockquote {
  padding: 15px 45px;
  font-style: italic;
  background-color: #f5f5f5;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner p + p {
  margin-top: -18px;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner ul li, .blog_post_wrapper .blog_post_inner .blog_post_inner ol li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 25px;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner ul li:last-of-type, .blog_post_wrapper .blog_post_inner .blog_post_inner ol li:last-of-type {
  margin-bottom: 0;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner ul li:before, .blog_post_wrapper .blog_post_inner .blog_post_inner ol li:before {
  position: absolute;
  left: 5px;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner ul li:before {
  content: "";
  top: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #2450FF;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner ol {
  counter-reset: line-number;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner ol li {
  counter-increment: line-number;
}
.blog_post_wrapper .blog_post_inner .blog_post_inner ol li:before {
  content: counter(line-number) ".";
}/*# sourceMappingURL=index.css.map */