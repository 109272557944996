.dmo_wrapper {
  position: relative;
  z-index: 1;
  padding: 70px 0 0;
  text-align: center;
  overflow: hidden;
}
.dmo_wrapper .text_wrapper {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: calc(100% - 48px);
  max-width: 1000px;
  margin: 36px 24px 64px;
  text-align: left;
  white-space: normal;
}
.dmo_wrapper .text_wrapper h2 {
  margin-bottom: 24px;
}
.dmo_wrapper .text_wrapper .company_logo {
  width: 104px;
  height: 67px;
  margin-bottom: 30px;
  background-image: url("./images/logo.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .dmo_wrapper .text_wrapper .company_logo {
    background-image: url("./images/logo@2x.png");
    background-size: 104px 67px;
  }
}
.dmo_wrapper .dmo_inner {
  position: relative;
  z-index: 1;
  height: 560px;
  text-align: left;
}
.dmo_wrapper .dmo_inner .ellipse_1 {
  position: absolute;
  z-index: 0;
  top: 100px;
  left: -400px;
  width: 800px;
  height: 800px;
  filter: blur(50px);
  background-repeat: no-repeat;
  background-size: 800px 800px;
  background-image: url("./images/ellipse_1.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .dmo_wrapper .dmo_inner .ellipse_1 {
    background-image: url("./images/ellipse_1@2x.png");
    background-size: 800px 800px;
  }
}
.dmo_wrapper .dmo_inner .flake {
  position: absolute;
  z-index: 2;
}
.dmo_wrapper .dmo_inner .flake.flake_1 {
  top: 152px;
  left: 75%;
}
.dmo_wrapper .dmo_inner .flake.flake_2 {
  top: 352px;
  left: 80%;
}
.dmo_wrapper .dmo_inner .scroll_to_form_button {
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 10;
  cursor: pointer;
  font: normal normal 600 14px/14px Manrope;
  padding: 18px 40px 19px;
  color: #0064FF;
  border: 2px solid #0064FF;
  border-radius: 28px;
  background-color: #fff;
}
.dmo_wrapper .dmo_inner .ded {
  position: absolute;
  z-index: 4;
  left: 0;
  bottom: 0;
  width: 508px;
  height: 480px;
  background-image: url("./images/ded-moroz-transparent.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .dmo_wrapper .dmo_inner .ded {
    background-image: url("./images/ded-moroz-transparent@2x.png");
    background-size: 508px 480px;
  }
}
.dmo_wrapper .dmo_inner .header_content {
  position: absolute;
  z-index: 5;
  width: calc(50% - 48px);
  left: 50%;
  top: 203px;
}
.dmo_wrapper .dmo_inner .header_content h6 {
  white-space: nowrap;
  margin-bottom: 11px;
}
.dmo_wrapper .dmo_inner .header_content h6 svg {
  display: inline-block;
  vertical-align: top;
}
.dmo_wrapper .dmo_inner .header_content h6 span {
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
  margin-left: 8px;
  font: normal normal 800 12px/12px Manrope;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  color: #FFD83E;
}
.dmo_wrapper .dmo_inner .header_content h1 {
  font: normal normal 800 50px/64px Manrope;
  color: #fff;
  margin-bottom: 32px;
}
.dmo_wrapper .dmo_inner .header_content .date {
  white-space: nowrap;
  display: inline-block;
  margin-bottom: 32px;
}
.dmo_wrapper .dmo_inner .header_content .date svg {
  display: inline-block;
  vertical-align: top;
}
.dmo_wrapper .dmo_inner .header_content .date span {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
  font: normal normal 600 14px/14px Manrope;
  margin-left: 8px;
  color: #AFEBFF;
}
.dmo_wrapper .dmo_inner .header_content .btn_holder button {
  display: inline-block;
  vertical-align: top;
  margin: 0 24px 24px 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  padding: 18px 40px 19px;
  color: #0064FF;
  background-color: #ffffff;
  border: 2px solid #0064FF;
  border-radius: 28px;
}
.dmo_wrapper .dmo_inner .header_content .btn_holder .flake_loader {
  position: relative;
  margin: 0 auto;
  z-index: 15;
  width: 59px;
  height: 64px;
  margin-left: -8px;
}
.dmo_wrapper .dmo_inner .header_content .btn_holder .flake_loader svg {
  animation: rotating 4s linear infinite;
}
@media screen and (max-width: 1050px) {
  .dmo_wrapper .dmo_inner .flake.flake_1 {
    left: 85%;
  }
  .dmo_wrapper .dmo_inner .flake.flake_2 {
    left: 94%;
  }
  .dmo_wrapper .dmo_inner .ded {
    width: 50%;
    height: calc((100vw - 80px) / 2 / 508 * 480);
    background-size: cover;
  }
  .dmo_wrapper .dmo_inner .header_content {
    width: calc(50% - 60px);
    margin-left: 60px;
  }
}
@media screen and (max-width: 700px) {
  .dmo_wrapper .dmo_inner {
    height: auto;
  }
  .dmo_wrapper .dmo_inner .ellipse_1 {
    display: none;
  }
  .dmo_wrapper .dmo_inner .flake.flake_1 {
    top: 304px;
    left: 251px;
    width: 59px;
    height: auto;
  }
  .dmo_wrapper .dmo_inner .flake.flake_2 {
    top: auto;
    bottom: 0;
    left: auto;
    right: -50px;
    width: 120px;
    height: auto;
  }
  .dmo_wrapper .dmo_inner .scroll_to_form_button {
    right: auto;
    top: 16px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 24px 11px;
  }
  .dmo_wrapper .dmo_inner .ded {
    position: relative;
    bottom: auto;
    left: auto;
    margin-left: 16px;
    width: 254px;
    height: 240px;
    background-size: 254px 240px;
    background-image: url("./images/ded-moroz-transparent.png");
  }
}
@media only screen and (max-width: 700px) and (-webkit-min-device-pixel-ratio: 2), only screen and (max-width: 700px) and (min-device-pixel-ratio: 2), only screen and (max-width: 700px) and (min-resolution: 192dpi), only screen and (max-width: 700px) and (min-resolution: 2dppx) {
  .dmo_wrapper .dmo_inner .ded {
    background-image: url("./images/ded-moroz-transparent@2x.png");
    background-size: 254px 240px;
  }
}
@media screen and (max-width: 700px) {
  .dmo_wrapper .dmo_inner .header_content {
    position: relative;
    margin: 0 24px;
    top: auto;
    left: auto;
    width: auto;
    padding-top: 104px;
    margin-bottom: 32px;
  }
  .dmo_wrapper .dmo_inner .header_content h6 {
    margin-bottom: 18px;
  }
  .dmo_wrapper .dmo_inner .header_content h1 {
    font: normal normal 800 32px/40px Manrope;
    margin-bottom: 22px;
  }
}
.dmo_wrapper .player_holder {
  position: relative;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=index.css.map */