.cl_perks {
  background-color: #F5F5F5;
  padding: 68px 0 72px;
}
.cl_perks h2 {
  margin-bottom: 52px;
}
.cl_perks .perks_carousel {
  text-align: center;
  position: relative;
}
.cl_perks .perks_carousel .feed_holder {
  overflow: hidden;
  margin-bottom: 35px;
  text-align: left;
}
.cl_perks .perks_carousel .feed_holder .feed {
  display: flex;
  flex-wrap: nowrap;
  transition: margin-left linear 400ms;
}
.cl_perks .perks_carousel .feed_holder .feed .feed_item {
  width: 450px;
  flex-shrink: 0;
  margin-right: 30px;
  padding: 52px 60px 68px;
  background-color: #fff;
  border-radius: 10px;
}
.cl_perks .perks_carousel .feed_holder .feed .feed_item dt {
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 32px;
}
.cl_perks .perks_carousel .feed_holder .feed .feed_item dd {
  font-size: 17px;
  line-height: 25px;
}
.cl_perks .perks_carousel .arr {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
}
.cl_perks .perks_carousel .arr:hover {
  opacity: 1;
}
.cl_perks .perks_carousel .arr:before {
  content: "";
  position: absolute;
  top: -6px;
  width: 30px;
  height: 30px;
  border: 1px solid #040E26;
  transform: rotate(45deg);
}
.cl_perks .perks_carousel .arr.arr_left {
  right: 100%;
  margin-right: 30px;
}
.cl_perks .perks_carousel .arr.arr_left:before {
  left: 7px;
}
.cl_perks .perks_carousel .arr.arr_right {
  left: 100%;
  margin-left: 30px;
}
.cl_perks .perks_carousel .arr.arr_right:before {
  right: 7px;
}
.cl_perks .perks_carousel .bullits {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.cl_perks .perks_carousel .bullits li {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px;
  width: 5px;
  height: 5px;
  border-radius: 3px;
  opacity: 0.5;
  background-color: #040E26;
  cursor: pointer;
  transition: linear 150ms;
}
.cl_perks .perks_carousel .bullits li:hover {
  opacity: 1;
}
.cl_perks .perks_carousel .bullits li.active {
  width: 20px;
  opacity: 1;
  background-color: #2450FF;
}
@media screen and (max-width: 1180px) {
  .cl_perks .perks_carousel .feed_holder .feed .feed_item {
    width: calc(50vw - 50px - 100px - 15px);
    padding: 52px 50px 62px;
  }
  .cl_perks .perks_carousel .arr.arr_left {
    margin-right: 23px;
  }
  .cl_perks .perks_carousel .arr.arr_right {
    margin-left: 23px;
  }
}
@media screen and (max-width: 900px) {
  .cl_perks .perks_carousel .feed_holder .feed .feed_item {
    width: calc(100vw - 200px);
  }
  .cl_perks .perks_carousel .arr {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .cl_perks {
    padding: 41px 0 50px;
  }
  .cl_perks .cl_centrer {
    margin: 0;
  }
  .cl_perks .cl_centrer h2 {
    margin: 0 20px 35px;
  }
  .cl_perks .perks_carousel .feed_holder .feed {
    transition: margin-left linear 250ms;
  }
  .cl_perks .perks_carousel .feed_holder .feed .feed_item {
    width: calc(100vw - 40px - 40px - 20px - 20px);
    padding: 36px 20px 37px;
    margin-right: 20px;
  }
  .cl_perks .perks_carousel .feed_holder .feed .feed_item:first-of-type {
    margin-left: 20px;
  }
  .cl_perks .perks_carousel .feed_holder .feed .feed_item dt {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 25px;
  }
  .cl_perks .perks_carousel .feed_holder .feed .feed_item dd {
    font-size: 15px;
    line-height: 20px;
  }
}/*# sourceMappingURL=index.css.map */