.lesson_content .lesson_webinar_wrapper .lecturer_and_date_section {
  display: flex;
  margin-bottom: 32px;
  font-size: 15px;
  line-height: 20px;
}
.lesson_content .lesson_webinar_wrapper .lecturer_and_date_section .lecturer {
  position: relative;
  padding-left: 50px;
  margin-right: 32px;
}
.lesson_content .lesson_webinar_wrapper .lecturer_and_date_section .lecturer .userpic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #040E26;
}
.lesson_content .lesson_webinar_wrapper .lecturer_and_date_section .lecturer .userpic span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}
.lesson_content .lesson_webinar_wrapper .lecturer_and_date_section .date {
  position: relative;
  padding-left: 30px;
}
.lesson_content .lesson_webinar_wrapper .lecturer_and_date_section .date svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.lesson_content .lesson_webinar_wrapper .lecturer_and_date_section .date span {
  opacity: 0.7;
}
.lesson_content .lesson_webinar_wrapper .platform_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #E8E8E8;
  padding: 30px 50px 30px 30px;
}
.lesson_content .lesson_webinar_wrapper .platform_section .broadcast_icon {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  margin-right: 30px;
}
.lesson_content .lesson_webinar_wrapper .platform_section .broadcast_icon .live_now {
  position: absolute;
  z-index: 100;
  left: 22px;
  top: 29px;
  border: 2px solid #FFFFFF;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #6AB261;
}
.lesson_content .lesson_webinar_wrapper .platform_section .broadcast_icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lesson_content .lesson_webinar_wrapper .platform_section p {
  width: 100%;
  font-size: 16px;
  line-height: 25px;
}
.lesson_content .lesson_webinar_wrapper .platform_section .btn_holder {
  flex-shrink: 0;
}
@media screen and (max-width: 600px) {
  .lesson_content .lesson_webinar_wrapper .lecturer_and_date_section {
    display: block;
  }
  .lesson_content .lesson_webinar_wrapper .lecturer_and_date_section .lecturer .userpic {
    margin-top: 10px;
  }
  .lesson_content .lesson_webinar_wrapper .lecturer_and_date_section .date {
    padding-left: 50px;
    margin-top: 2px;
  }
  .lesson_content .lesson_webinar_wrapper .lecturer_and_date_section .date svg {
    display: none;
  }
  .lesson_content .lesson_webinar_wrapper .platform_section {
    width: calc(100vw - 40px);
    padding: 20px;
    margin-left: -20px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
  .lesson_content .lesson_webinar_wrapper .platform_section .broadcast_icon {
    margin-bottom: 24px;
  }
  .lesson_content .lesson_webinar_wrapper .platform_section p {
    margin-bottom: 26px;
  }
  .lesson_content .lesson_webinar_wrapper .platform_section .btn_holder {
    width: 100%;
  }
  .lesson_content .lesson_webinar_wrapper .platform_section .btn_holder button {
    width: 100%;
  }
}/*# sourceMappingURL=webinar.css.map */