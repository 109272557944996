.social_share_widget {
  position: relative;
  width: 30px;
  height: 30px;
}
.social_share_widget.visible .ssw_switcher_wrapper svg path {
  opacity: 1;
}
.social_share_widget.visible .ssw_bubble {
  display: block;
}
.social_share_widget .ssw_switcher_wrapper {
  cursor: pointer;
}
.social_share_widget .ssw_switcher_wrapper:hover svg path {
  opacity: 1;
}
.social_share_widget .ssw_bubble {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin-top: 6px;
  width: 50px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #E4E5E8;
  padding: 15px 0;
  text-align: center;
}
.social_share_widget .ssw_bubble .bubble_arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  width: 10px;
  height: 6px;
  overflow: hidden;
}
.social_share_widget .ssw_bubble .bubble_arrow:after {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 1px solid #E4E5E8;
  transform: rotate(45deg);
}
.social_share_widget .ssw_bubble button {
  width: 20px;
  height: 20px;
  margin: 0 auto 15px;
  transition: linear 130ms;
}
.social_share_widget .ssw_bubble button:hover {
  transform: scale(1.3);
}
.social_share_widget .ssw_bubble button:last-of-type {
  margin-bottom: 0;
}
.social_share_widget .ssw_bubble button svg circle {
  fill: #040E26;
}
@media screen and (max-width: 1180px) {
  .social_share_widget .ssw_switcher_wrapper svg .b {
    fill: #040E26;
  }
}/*# sourceMappingURL=index.css.map */