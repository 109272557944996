.ls_header_wrapper {
  height: 60px;
  background-color: #FAFCFF;
  padding-top: 40px;
}
.ls_header_wrapper .ls_logo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 58px;
  height: 40px;
}
.ls_header_wrapper .ls_logo img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
}
.ls_header_wrapper .ls_logo a {
  color: unset;
  text-decoration: none;
}
.ls_header_wrapper .ls_logo span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  margin-left: 20px;
}
.ls_header_wrapper .ls_auth_links {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}
.ls_header_wrapper .ls_auth_links a {
  display: inline-block;
  margin-right: 15px;
  color: #040E26;
  text-decoration: none;
}
.ls_header_wrapper .ls_auth_links a:last-of-type {
  margin-right: 0;
}
@media screen and (max-width: 600px) {
  .ls_header_wrapper {
    background-color: #fff;
    height: 45px;
  }
  .ls_header_wrapper .ls_logo span {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */