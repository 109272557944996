.lp_welcome_wrapper {
  min-height: 710px;
  position: relative;
  background-color: #FAFCFF;
  overflow: hidden;
}
.lp_welcome_wrapper .lp_bg {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.lp_welcome_wrapper .lp_bg .girl {
  position: absolute;
  z-index: 5;
  right: 135px;
  bottom: 0;
  height: 690px;
  width: 570px;
}
.lp_welcome_wrapper .lp_bg .girl .pic_ellipse {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
}
.lp_welcome_wrapper .lp_bg .girl .pic_girl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  bottom: 0;
}
.lp_welcome_wrapper .lp_bg .ellipse_1 {
  position: absolute;
  z-index: 1;
  width: 120px;
  height: 120px;
  left: -60px;
  bottom: 130px;
}
.lp_welcome_wrapper .lp_bg .ellipse_2 {
  position: absolute;
  z-index: 1;
  width: 249px;
  height: 126px;
  right: -124px;
  bottom: 0;
}
.lp_welcome_wrapper .lp_bg .ellipse_2 img {
  width: 100%;
}
.lp_welcome_wrapper .lp_welcome_text {
  padding-top: 100px;
  width: 630px;
}
.lp_welcome_wrapper .lp_welcome_text h1 {
  font-size: 55px;
  line-height: 65px;
  font-weight: 600;
  margin-bottom: 40px;
}
.lp_welcome_wrapper .lp_welcome_text p {
  font-size: 24px;
  line-height: 35px;
  width: 570px;
}
@media screen and (max-width: 1050px) {
  .lp_welcome_wrapper {
    height: 750px;
  }
  .lp_welcome_wrapper .lp_bg .girl {
    width: 490px;
    height: 590px;
    right: 100px;
  }
  .lp_welcome_wrapper .lp_bg .girl .pic_girl {
    height: 530px;
  }
  .lp_welcome_wrapper .lp_welcome_text {
    padding-top: 30px;
    width: 70%;
  }
  .lp_welcome_wrapper .lp_welcome_text p {
    width: 400px;
  }
}
@media screen and (max-width: 900px) {
  .lp_welcome_wrapper {
    height: auto;
  }
  .lp_welcome_wrapper .lp_bg {
    position: relative;
    bottom: auto;
    left: auto;
    margin-bottom: -20px;
  }
  .lp_welcome_wrapper .lp_bg .girl {
    position: relative;
    width: 100%;
    text-align: center;
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    right: auto;
  }
  .lp_welcome_wrapper .lp_bg .girl .pic_girl {
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    position: relative;
    display: inline-block;
    margin-top: 32px;
  }
  .lp_welcome_wrapper .lp_welcome_text {
    width: 100%;
  }
  .lp_welcome_wrapper .lp_welcome_text p {
    width: auto;
  }
}
@media screen and (max-width: 600px) {
  .lp_welcome_wrapper .lp_welcome_text {
    margin-bottom: 30px;
  }
  .lp_welcome_wrapper .lp_welcome_text h1 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 19px;
  }
  .lp_welcome_wrapper .lp_welcome_text p {
    font-size: 18px;
    line-height: 25px;
  }
}
.lp_welcome_wrapper .lp_bg .girl .pic_girl {
  height: auto;
  width: 80%;
}/*# sourceMappingURL=index.css.map */