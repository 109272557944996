.sidebar_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #E5E5E5;
  padding-top: 60px;
  transition: ease-out 400ms;
  overflow: auto;
}
.sidebar_wrapper.hidden {
  top: -100vh;
}
.sidebar_wrapper .auth_tabs {
  margin-bottom: 0;
}
.sidebar_wrapper .sign_up_wrapper .auth_tabs {
  display: none;
}
.sidebar_wrapper .sidebar_forms {
  padding: 48px 24px 24px;
}
.sidebar_wrapper .sidebar_forms .sign_in_form,
.sidebar_wrapper .sidebar_forms .sign_up_form {
  text-align: center;
}
.sidebar_wrapper .sidebar_forms .sign_in_form .text_input,
.sidebar_wrapper .sidebar_forms .sign_up_form .text_input {
  background-color: transparent;
}
.sidebar_wrapper .sidebar_forms .sign_up_form {
  padding-bottom: 96px;
}
.sidebar_wrapper .sidebar_forms .sign_up_wrapper {
  padding-top: 0;
  margin: 0;
}
.sidebar_wrapper .sidebar_forms .sign_up_wrapper .sign_up_landing .radio_block .radio_wrapper {
  margin: 0 0 24px;
}/*# sourceMappingURL=index.css.map */