.student_change_name_modal {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
}
.student_change_name_modal_title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1F57C3;
}
.student_change_name_modal_desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: #1F57C3;
}
.student_change_name_modal_error {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #F02D2D;
}
.student_change_name_modal_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 310px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.student_change_name_modal_input_desc {
  font-size: 12px;
  font-weight: 400;
  color: #96A3AE;
}
.student_change_name_modal_input input {
  width: 280px;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  margin-left: 10px;
  font-size: 16px;
  line-height: 40px;
  color: #1F57C3;
}
.student_change_name_modal_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
}
.student_change_name_modal_list li {
  list-style-type: initial;
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
  color: #1F57C3;
}
.student_change_name_modal_list li::marker {
  color: #1F57C3;
}
.student_change_name_modal_footer {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}
.student_change_name_modal_footer_decline {
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.student_change_name_modal_footer_confirm {
  cursor: pointer;
  box-sizing: border-box;
  height: 29px;
  width: 107px;
  padding: 7px 22px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  background: #1F57C3;
  border-radius: 50px;
}/*# sourceMappingURL=studentNameChangeModal.css.map */