.sign_in_wrapper {
  position: relative;
  width: 336px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 120px;
  text-align: center;
}
.sign_in_wrapper h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
@media screen and (max-width: 600px) {
  .sign_in_wrapper {
    padding-top: 112px;
    width: auto;
    margin: 0 24px;
  }
  .sign_in_wrapper .auth_tabs {
    width: 100vw;
    margin-left: -24px;
    background-color: #F2F0F0;
    border-top-color: #E5E5E5;
  }
}/*# sourceMappingURL=index.css.map */