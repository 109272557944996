.lesson_content .player_wrapper .player_inner {
  width: 800px;
  height: 460px;
  margin: 0 auto;
  position: relative;
}
.lesson_content .player_wrapper .player_inner iframe {
  position: relative;
  margin: 0 auto;
  display: block;
}
@media screen and (max-width: 1050px) {
  .lesson_content .player_wrapper .player_inner {
    width: auto;
  }
  .lesson_content .player_wrapper .player_inner iframe {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 600px) {
  .lesson_content .player_wrapper {
    width: 100vw;
    margin-left: -20px;
  }
}/*# sourceMappingURL=video.css.map */