.school_profile_form_heading {
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  margin-bottom: 10px;
}
.school_profile_form_semi_title {
  margin: 10px 0px 40px;
  color: #96A3AE;
  font-size: 12px;
  font-weight: 500;
}
.school_profile_form .link_info {
  max-width: 500px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  position: relative;
  top: -50px;
  left: 130px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.school_profile_form .link_info_tip {
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  background: lightgray;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #1F57C3;
}
.school_profile_form .link_info_tip_content {
  cursor: auto !important;
  position: absolute;
  z-index: 9999;
  top: 25px;
  right: 0px;
  width: 250px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}
@media screen and (max-width: 480px) {
  .school_profile_form .link_info {
    top: 0px;
    left: 0px;
    margin-bottom: 15px;
  }
}
.school_profile_form_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
@media screen and (max-width: 480px) {
  .school_profile_form_row {
    flex-direction: column;
  }
}
.school_profile_form_input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 360px;
  padding: 8px 12px 8px 20px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
  margin-right: 24px;
  margin-left: 140px;
  height: 40px;
  box-sizing: border-box;
}
.school_profile_form_input .dropdown {
  width: 400px !important;
}
.school_profile_form_input .dropdown .dropdown_list_wrapper {
  width: 350px !important;
}
.school_profile_form_input .dropdown .dropdown_list_wrapper ul li:first-of-type {
  border-top: 1px solid #E6E7EA;
}
.school_profile_form_input .dropdown .dropdown_list_wrapper ul li:first-of-type + :not([style*="display: none"]) {
  border-top: 1px solid #E6E7EA;
}
@media screen and (max-width: 480px) {
  .school_profile_form_input {
    margin-left: 0px;
    width: 320px;
  }
}
.school_profile_form_input_textarea {
  align-items: initial !important;
  height: 120px;
}
.school_profile_form_input_withPhone input {
  width: 200px !important;
}
.school_profile_form_input_phone {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  border-right: 1px solid rgba(150, 163, 174, 0.2);
  padding-right: 10px;
}
.school_profile_form_input_phone_flag {
  width: 23px;
  height: 16px;
  background-repeat: no-repeat;
}
.school_profile_form_input_phone_select {
  width: 16px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  rotate: 90deg;
  background-position-x: center;
}
.school_profile_form_input_edit {
  cursor: pointer;
  width: 16px;
  height: 16px;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 480px) {
  .school_profile_form_input_edit {
    width: 21px;
  }
}
.school_profile_form_input_label {
  width: 160px;
  font-size: 12px;
  color: #96A3AE;
  cursor: initial;
}
.school_profile_form_input_date {
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
}
.school_profile_form_input_date_placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.school_profile_form_input textarea {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  width: 330px;
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
}
.school_profile_form_input input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  width: 330px;
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
}
.school_profile_form_input select {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  width: 330px;
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
}
.school_profile_form_save_button {
  margin: auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1F57C3;
  border-radius: 50px;
  cursor: pointer;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  width: 107px;
  height: 29px;
}
.school_profile_form_save_button:hover {
  color: black;
  background-color: lightgray;
}/*# sourceMappingURL=school-profile.css.map */