.userpic_upload_wrapper {
  position: relative;
  margin-bottom: 32px;
}
.userpic_upload_wrapper .userpic_upload_inner {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #434A5C;
  text-align: center;
}
.userpic_upload_wrapper .userpic_upload_inner label {
  position: absolute;
  z-index: 30;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  padding-top: 65px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5647058824);
  transition: opacity ease-out 100ms;
  cursor: pointer;
  font-weight: 500;
}
.userpic_upload_wrapper .userpic_upload_inner label:hover {
  opacity: 1;
}
.userpic_upload_wrapper .userpic_upload_inner .userpic_letter {
  position: absolute;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  line-height: 80px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}
.userpic_upload_wrapper .userpic_upload_inner .userpic_letter .ul_empty {
  font-size: 24px;
  line-height: 36px;
  display: block;
}
.userpic_upload_wrapper .userpic_upload_inner .avatar {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.userpic_upload_wrapper .userpic_upload_inner input {
  opacity: 0;
  visibility: hidden;
}
.userpic_upload_wrapper .button_upload {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 130px;
  font-weight: 400;
  font-size: 12px;
}
.userpic_upload_wrapper .button_upload label {
  cursor: pointer;
}/*# sourceMappingURL=index.css.map */