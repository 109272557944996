.cl_header .header_rating {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 48px;
  margin-right: 40px;
}
.cl_header .header_rating .icon_course_rating {
  margin-right: 18px;
  transform: scale(1.1);
}
.cl_header .header_rating .icon_course_rating path {
  opacity: 1;
  fill: #fff;
}
.cl_header .header_rating .icon_course_rating path.filled {
  fill: #FFD500;
}
.cl_header .header_rating .rating_count {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  line-height: 18px;
  padding-top: 2px;
  color: #fff;
}
.cl_header .header_rating .votes_count {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  line-height: 18px;
  padding-top: 2px;
  margin-left: 15px;
  color: #fff;
  opacity: 0.7;
}
@media screen and (max-width: 700px) {
  .cl_header .header_rating {
    margin-bottom: 35px;
  }
  .cl_header .header_rating .icon_course_rating {
    transform: scale(1);
  }
}
@media screen and (max-width: 600px) {
  .cl_header .header_rating + .header_date_start {
    margin-top: -28px;
  }
}/*# sourceMappingURL=rating.css.map */