.collections_header {
  position: relative;
  overflow: hidden;
  background: linear-gradient(94.52deg, rgba(191, 208, 240, 0.2) 0%, rgba(204, 167, 133, 0.2) 100%); }
  .collections_header .ch_bg {
    position: absolute;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 0;
    top: 0;
    width: 1920px;
    height: 540px; }
    .collections_header .ch_bg .ch_bg_l {
      position: absolute;
      top: 84px;
      left: 27px;
      width: 494px;
      height: 334px;
      background-image: url("./images/main-header-l.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .collections_header .ch_bg .ch_bg_l {
          background-image: url("./images/main-header-l@2x.png");
          background-size: 494px 334px; } }
    .collections_header .ch_bg .ch_bg_r {
      position: absolute;
      top: 112px;
      right: 66px;
      width: 380px;
      height: 312px;
      background-image: url("./images/main-header-r.png"); }
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        .collections_header .ch_bg .ch_bg_r {
          background-image: url("./images/main-header-r@2x.png");
          background-size: 380px 312px; } }
  .collections_header .ch_content {
    position: relative;
    z-index: 2;
    width: 1300px;
    margin: 0 auto; }
    .collections_header .ch_content .ch_text {
      width: 718px;
      margin: 0 auto 30px;
      padding-top: 130px;
      text-align: left; }
      .collections_header .ch_content .ch_text h1 {
        font-weight: 500;
        font-size: 60px;
        line-height: 70px;
        margin-bottom: 30px;
        color: #000; }
      .collections_header .ch_content .ch_text p {
        width: 550px;
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 60px;
        color: #000; }
      .collections_header .ch_content .ch_text .mini_bg {
        display: none; }
      .collections_header .ch_content .ch_text .ch_buttons {
        display: flex; }
        .collections_header .ch_content .ch_text .ch_buttons .chb {
          width: auto;
          vertical-align: top;
          margin-right: 20px;
          font-size: 20px;
          line-height: 24px;
          padding: 11px 27px;
          border-radius: 32px;
          border: 1px solid #1F57C3; }
          .collections_header .ch_content .ch_text .ch_buttons .chb.chb_student {
            background-color: #1F57C3;
            box-shadow: 0 8px 20px rgba(31, 87, 195, 0.4); }
          .collections_header .ch_content .ch_text .ch_buttons .chb.chb_school {
            background-color: transparent;
            color: #1F57C3; }
    .collections_header .ch_content .ch_search {
      padding-bottom: 10px; }
  @media screen and (max-width: 1500px) {
    .collections_header .ch_bg {
      width: 1160px; }
      .collections_header .ch_bg .ch_bg_l {
        left: auto;
        top: 98px;
        right: -30px; }
      .collections_header .ch_bg .ch_bg_r {
        display: none; }
    .collections_header .ch_content {
      width: 1160px; }
      .collections_header .ch_content .ch_text {
        margin: 0 0 30px 0;
        padding-top: 90px; } }
  @media screen and (max-width: 1230px) {
    .collections_header .ch_bg {
      width: 100%; }
      .collections_header .ch_bg .ch_bg_l {
        left: 670px;
        top: 78px;
        right: auto; }
    .collections_header .ch_content {
      width: auto;
      margin: 0 30px; }
      .collections_header .ch_content .ch_text {
        margin: 0 0 30px 0;
        padding-top: 90px; } }
  @media screen and (max-width: 780px) {
    .collections_header .ch_bg {
      display: none; }
    .collections_header .ch_content {
      width: auto;
      margin: 0 20px; }
      .collections_header .ch_content .ch_text {
        width: auto;
        padding-top: 90px; }
        .collections_header .ch_content .ch_text h1 {
          font-size: 28px;
          line-height: 32px;
          margin-bottom: 10px; }
        .collections_header .ch_content .ch_text p {
          width: auto;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 10px; }
        .collections_header .ch_content .ch_text .mini_bg {
          display: block;
          width: calc(100% - 60px);
          max-width: 400px;
          margin: 0 auto 20px; }
        .collections_header .ch_content .ch_text .ch_buttons .chb {
          font-size: 16px;
          line-height: 20px;
          padding: 10px 20px; } }

/*# sourceMappingURL=index.css.map */
