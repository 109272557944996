.cem_lesson .eli_video {
  padding-top: 2px;
}
.cem_lesson .eli_video .cdn_video_list {
  position: relative;
  min-height: 150px;
  max-height: 180px;
  overflow: auto;
  margin-bottom: 4px;
}
.cem_lesson .eli_video .cdn_video_list ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0823529412);
  cursor: pointer;
}
.cem_lesson .eli_video .cdn_video_list ul li:hover, .cem_lesson .eli_video .cdn_video_list ul li.selected {
  background-color: rgba(192, 0, 0, 0.062745098);
}
.cem_lesson .eli_video .cdn_video_list ul li .thumbnail {
  position: relative;
  width: 100px;
  height: 50px;
  background-color: #000;
  margin-right: 24px;
  text-align: center;
}
.cem_lesson .eli_video .cdn_video_list ul li .thumbnail img {
  height: 100%;
  display: inline-block;
}
.cem_lesson .eli_video .cdn_video_list ul li .title {
  font-size: 13px;
  line-height: 19px;
}
.cem_lesson .eli_video h5 {
  margin-bottom: 16px;
}
.cem_lesson .eli_video .course_form_hint {
  font-size: 10px;
  line-height: 16px;
  color: #C0C2C8;
}
.cem_lesson .eli_video .course_form_hint a {
  text-decoration: none;
  color: #C0C2C8;
  border-bottom: 1px solid #C0C2C8;
}
.cem_lesson .eli_video .course_form_hint.cfh_abs {
  position: absolute;
}
.cem_lesson .eli_video .course_form_hint .pseudo {
  border-bottom: 1px dotted #C0C2C8;
  cursor: pointer;
}
.cem_lesson .eli_video .eli_video_tabs {
  white-space: nowrap;
  font-size: 0;
  margin-bottom: 16px;
}
.cem_lesson .eli_video .eli_video_tabs h5 {
  cursor: pointer;
  width: 50%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  border-bottom: 1px solid #E6E7EA;
}
.cem_lesson .eli_video .eli_video_tabs h5.active {
  cursor: default;
  border-bottom: 1px solid #434A5C;
  color: #434A5C;
}
.cem_lesson .eli_video .upload_bar {
  display: block;
  width: calc(100% - 18px);
  padding: 2px 8px;
  font-size: 16px;
  line-height: 24px;
  height: 30px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.cem_lesson .eli_video .upload_bar:disabled {
  opacity: 0.4;
}
.cem_lesson .eli_video .upload_bar.placeholder {
  color: #C0C2C8;
}
.cem_lesson .eli_video .upload_bar:-moz-placeholder {
  color: #C0C2C8;
}
.cem_lesson .eli_video .upload_bar::-moz-placeholder {
  color: #C0C2C8;
}
.cem_lesson .eli_video .upload_bar:-ms-input-placeholder {
  color: #C0C2C8;
}
.cem_lesson .eli_video .upload_bar::-webkit-input-placeholder {
  color: #C0C2C8;
}
.cem_lesson .eli_video .external_source_field_wrapper {
  position: relative;
}
.cem_lesson .eli_video .external_source_field_wrapper .btn_check_source {
  position: absolute;
  top: 1px;
  right: 1px;
}
.cem_lesson .eli_video .external_source_field_wrapper .player_v2_wrapper {
  margin-top: 16px;
  width: 400px;
  height: 240px;
}
.cem_lesson .eli_video .eli_cloud_upload {
  position: relative;
  overflow: hidden;
}
.cem_lesson .eli_video .eli_cloud_upload input {
  font-size: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}
.cem_lesson .eli_video .eli_cloud_upload input:disabled + label {
  opacity: 0.4;
}
.cem_lesson .eli_video .eli_cloud_upload label {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.cem_lesson .eli_video .eli_cloud_upload label .file_name {
  padding: 6px 8px;
  font-size: 16px;
  line-height: 24px;
}
.cem_lesson .eli_video .eli_cloud_upload label .label_btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 16px;
  line-height: 16px;
  padding: 8px 20px;
  background-color: #040E26;
  color: #fff;
  cursor: pointer;
}/*# sourceMappingURL=video.css.map */