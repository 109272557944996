@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Thin.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-Thin.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-ThinItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-ExtraLight.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Light.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-Light.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/Inter/Inter-LightItalic.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-LightItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Regular.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Italic.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-Italic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Medium.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/Inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Inter/Inter-SemiBold.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/Inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Bold.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../fonts/Inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/Inter/Inter-Black.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-Black.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../fonts/Inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"), url("../fonts/Inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Manrope";
  font-weight: 200;
  src: url("../fonts/Manrope-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-weight: 300;
  src: url("../fonts/Manrope-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-weight: 400;
  src: url("../fonts/Manrope-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-weight: 500;
  src: url("../fonts/Manrope-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-weight: 600;
  src: url("../fonts/Manrope-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-weight: 700;
  src: url("../fonts/Manrope-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-weight: 800;
  src: url("../fonts/Manrope-ExtraBold.ttf") format("truetype");
}
body::-webkit-scrollbar-track {
  border-radius: 10px;
  background-clip: content-box;
  background-color: none;
}

body::-webkit-scrollbar {
  width: 18px;
  background-color: none;
}

body::-webkit-scrollbar-thumb {
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: content-box;
  border-radius: 10px;
  background-color: #EAEDEF;
  height: 50px;
}

body::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.b24-window-scrollable::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}

.b24-window-scrollable::-webkit-scrollbar {
  width: 18px;
  background-color: none;
}

.b24-window-scrollable::-webkit-scrollbar-thumb {
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #EAEDEF;
}

body {
  overflow: overlay;
  scrollbar-gutter: stable both-edges;
  font-family: "Inter", sans-serif;
  color: #040E26;
  background-color: #fff;
  font-size: unset;
  font-weight: unset;
  line-height: unset;
}
body.profile_dashboard {
  background-color: rgba(4, 14, 38, 0.0078431373);
}
body * {
  box-sizing: unset;
}
body *:before, body *:after {
  box-sizing: unset;
}
body .title:not(:last-child) {
  margin-bottom: unset;
}
body img {
  max-width: unset;
  pointer-events: none;
}
body.scroll_locked {
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  body.scroll_locked {
    position: fixed;
  }
}
body .page_view {
  min-height: calc(100vh - 96px);
}
body.minimal .page_view {
  min-height: calc(100vh - 96px - 156px);
}

input, textarea, button {
  font-family: "Inter", sans-serif;
}

.text_input {
  border: 0 solid;
  border-bottom: 1px solid #434A5C;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
  color: #434A5C;
}
.text_input.placeholder {
  color: #434A5C;
}
.text_input:-moz-placeholder {
  color: #434A5C;
}
.text_input::-moz-placeholder {
  color: #434A5C;
}
.text_input:-ms-input-placeholder {
  color: #434A5C;
}
.text_input::-webkit-input-placeholder {
  color: #434A5C;
}
.text_input:focus.placeholder {
  opacity: 0.5;
}
.text_input:focus:-moz-placeholder {
  opacity: 0.5;
}
.text_input:focus::-moz-placeholder {
  opacity: 0.5;
}
.text_input:focus:-ms-input-placeholder {
  opacity: 0.5;
}
.text_input:focus::-webkit-input-placeholder {
  opacity: 0.5;
}
.text_input[type=password] {
  letter-spacing: 5px;
}
.text_input[type=password].placeholder {
  letter-spacing: 0;
}
.text_input[type=password]:-moz-placeholder {
  letter-spacing: 0;
}
.text_input[type=password]::-moz-placeholder {
  letter-spacing: 0;
}
.text_input[type=password]:-ms-input-placeholder {
  letter-spacing: 0;
}
.text_input[type=password]::-webkit-input-placeholder {
  letter-spacing: 0;
}