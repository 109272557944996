.media_uploader {
  position: relative;
}
.media_uploader label {
  display: block;
  padding: 16px 64px;
  background-color: rgba(0, 0, 0, 0.0196078431);
  border: 1px dashed rgba(0, 0, 0, 0.1882352941);
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
}
.media_uploader label input {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
}
.media_uploader label span {
  font-size: 12px;
  opacity: 0.75;
  display: block;
}/*# sourceMappingURL=index.css.map */