.ls_who_can_use_wrapper {
  background-color: #fff;
}
.ls_who_can_use_wrapper .l_content {
  padding-top: 114px;
  padding-bottom: 135px;
}
.ls_who_can_use_wrapper .l_content h2 {
  margin-bottom: 100px;
}
.ls_who_can_use_wrapper .l_content .plates {
  margin-left: -15px;
  width: 1200px;
}
.ls_who_can_use_wrapper .l_content .plates li {
  display: inline-block;
  vertical-align: top;
  width: 270px;
  height: 270px;
  margin: 15px;
  border-radius: 20px;
  background-color: #F9FBFC;
  text-align: center;
}
.ls_who_can_use_wrapper .l_content .plates li img {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-top: 80px;
  margin-bottom: 35px;
}
.ls_who_can_use_wrapper .l_content .plates li p {
  font-size: 18px;
  line-height: 25px;
}
.ls_who_can_use_wrapper .l_content .button_holder {
  text-align: center;
  padding-top: 60px;
}
.ls_who_can_use_wrapper .l_content .button_holder button {
  background-color: #2450FF;
  border: 0 solid;
  padding: 23px 35px 22px;
  border-radius: 30px;
}
@media screen and (max-width: 1440px) {
  .ls_who_can_use_wrapper .l_content .button_holder button {
    padding: 13px 0;
  }
}
@media screen and (max-width: 1050px) {
  .ls_who_can_use_wrapper .l_content .plates {
    width: auto;
  }
  .ls_who_can_use_wrapper .l_content .plates li {
    width: 208px;
    height: 208px;
  }
  .ls_who_can_use_wrapper .l_content .plates li img {
    margin-top: 50px;
  }
}
@media screen and (max-width: 600px) {
  .ls_who_can_use_wrapper .l_content {
    padding-top: 55px;
    padding-bottom: 50px;
  }
  .ls_who_can_use_wrapper .l_content h2 {
    margin-bottom: 35px;
  }
  .ls_who_can_use_wrapper .l_content .plates {
    width: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 0;
  }
  .ls_who_can_use_wrapper .l_content .plates li {
    width: 48%;
    height: 175px;
    display: block;
    margin-right: 0;
    margin-left: 0;
  }
  .ls_who_can_use_wrapper .l_content .plates li img {
    margin-top: 24px;
  }
  .ls_who_can_use_wrapper .l_content .plates li p {
    font-size: 14px;
    line-height: 25px;
  }
}/*# sourceMappingURL=index.css.map */