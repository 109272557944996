.course_featured_item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 420px;
  margin-right: 20px;
  white-space: normal;
  color: #040E26;
  text-align: left;
}
.course_featured_item .collection_label {
  margin-left: 2px;
  position: absolute;
  color: #1F57C3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.course_featured_item .collection_label img {
  width: 100%;
  height: 100%;
  margin-right: 4px;
}
.course_featured_item .collection_footer {
  position: absolute;
  bottom: 8px;
  height: 48px;
  width: 100%;
  color: #FFF;
  margin-left: 16px;
  font-size: 24px;
}
.course_featured_item .additional_info_bar {
  height: 21px;
}
.course_featured_item .featured_link {
  text-decoration: none;
  color: unset;
}
.course_featured_item .featured_link.featured_overlay_link {
  display: block;
  height: 131px;
  margin-bottom: 13px;
}
@media (hover: hover) {
  .course_featured_item:hover .featured_overlay .cp_params {
    opacity: 1;
  }
}
.course_featured_item .img_wrapper {
  position: relative;
  z-index: 0;
  height: 300px;
  overflow: hidden;
  border-radius: 8px;
}
.course_featured_item .img_wrapper img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.course_featured_item .img_wrapper img.vertical, .course_featured_item .img_wrapper img.book_cover {
  height: auto;
  width: 100%;
  position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: translate(0, 0);
  position: absolute;
  top: 0;
}
.course_featured_item .img_wrapper img.bottom_orientated {
  height: auto;
  width: 100%;
  position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: translate(0, 0);
  position: absolute;
  bottom: 0;
}
.course_featured_item .img_wrapper img.middle_orientated {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.course_featured_item .img_wrapper .img_plug {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}
.course_featured_item .img_wrapper .featured_fade {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir ); /* IE6-9 */
}
.course_featured_item .featured_overlay {
  position: absolute;
  z-index: 10;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  color: #fff;
}
.course_featured_item .featured_overlay .cp_params {
  padding: 0 0 20px;
  width: 100%;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #fff;
  transition: opacity linear 200ms;
  opacity: 0;
}
.course_featured_item .featured_overlay .cp_params svg path {
  fill: #fff;
}
.course_featured_item .featured_overlay .cp_params .icon_course_level {
  margin-bottom: -4px;
}
.course_featured_item .featured_overlay .cp_params .cp_pict {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}
.course_featured_item .featured_overlay .cp_params .cp_pict svg {
  width: 22px;
  height: 22px;
}
.course_featured_item .featured_overlay .cp_params .cp_pict_text {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.55px;
  padding: 4px 10px;
  border-radius: 10px;
  text-transform: uppercase;
  color: #fff;
}
.course_featured_item .featured_overlay .cp_params .cp_pict_text.cp_pict_audio {
  background-color: #7f35be;
}
.course_featured_item .featured_overlay .cp_params .cp_pict_text.cp_pict_video {
  background-color: #2450ff;
}
.course_featured_item .featured_overlay .cp_params .cp_label {
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
  background-color: #BA319B;
  color: #fff;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.55px;
  padding: 4px 10px;
  border-radius: 10px;
}
.course_featured_item .featured_overlay h4 {
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 10px;
}
.course_featured_item .featured_overlay .featured_text {
  overflow: hidden;
  max-height: 70px;
}
.course_featured_item .featured_overlay h3 {
  font-size: 18px;
  line-height: 23px;
  max-height: 46px;
  margin-bottom: 8px;
  font-weight: 400;
  overflow: hidden;
}
.course_featured_item .featured_overlay .teaser {
  font-size: 12px;
  line-height: 15px;
  max-height: 30px;
  overflow: hidden;
}
.course_featured_item .featured_overlay .button_holder .discount_text {
  display: inline-block;
  margin-left: 20px;
  color: #040E26;
  opacity: 0.7;
  font-size: 12px;
}
.course_featured_item .featured_overlay .button_holder .discount_text.old_price {
  font-size: 16px;
  opacity: 0.5;
  text-decoration: line-through;
}
@media screen and (max-width: 1500px) {
  .course_featured_item {
    width: 375px;
    margin-right: 18px;
  }
  .course_featured_item .img_wrapper {
    height: 240px;
  }
}
@media screen and (max-width: 780px) {
  .course_featured_item {
    width: 320px;
    margin-right: 20px;
  }
  .course_featured_item .img_wrapper {
    height: 240px;
  }
  .course_featured_item .featured_link.featured_overlay_link {
    height: 180px;
  }
  .course_featured_item .featured_overlay .featured_text {
    max-height: 128px;
  }
  .course_featured_item .featured_overlay .featured_text h3 {
    font-size: 18px;
    line-height: 21px;
    max-height: 63px;
  }
  .course_featured_item .featured_overlay .featured_text .teaser {
    max-height: 45px;
  }
}/*# sourceMappingURL=item.css.map */