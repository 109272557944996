.main_search_bar {
  position: relative;
}
.main_search_bar .msb_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 14px;
}
.main_search_bar input {
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  padding: 12px 0 10px;
  border: 1px solid #fff;
  background-color: #fff;
  text-indent: 44px;
  border-radius: 20px;
  transition: ease-out 150ms;
}
.main_search_bar input:focus {
  border-color: #1F57C3;
}
.main_search_bar input.placeholder {
  color: #C4C4C4;
}
.main_search_bar input:-moz-placeholder {
  color: #C4C4C4;
}
.main_search_bar input::-moz-placeholder {
  color: #C4C4C4;
}
.main_search_bar input:-ms-input-placeholder {
  color: #C4C4C4;
}
.main_search_bar input::-webkit-input-placeholder {
  color: #C4C4C4;
}/*# sourceMappingURL=index.css.map */