@import "../../styles/mixins.css";
.stats_wrapper {
  margin: 0 24px;
}
.stats_wrapper h2 {
  margin-bottom: 16px;
  font-size: 17px;
  line-height: 25px;
  font-weight: 500;
}
.stats_wrapper .school_stats {
  margin-bottom: 48px;
}
.stats_wrapper .school_stats dl {
  white-space: nowrap;
}
.stats_wrapper .school_stats dl:nth-of-type(odd) {
  background-color: #F2F0F0;
}
.stats_wrapper .school_stats dl dt, .stats_wrapper .school_stats dl dd {
  display: inline-block;
  vertical-align: top;
  padding: 3px 15px;
}
.stats_wrapper .school_stats dl dt {
  width: 200px;
}
.stats_wrapper .school_stats dl dd {
  font-weight: bold;
}
.stats_wrapper .school_stats dl dd span {
  font-size: 13px;
  font-weight: normal;
  opacity: 0.5;
}
.stats_wrapper .interaction_stats {
  margin-bottom: 64px;
}
.stats_wrapper .interaction_stats h5 {
  margin-bottom: 16px;
}
.stats_wrapper .interaction_stats h5 .pseudo_link {
  display: inline-block;
  cursor: pointer;
  margin: 0 24px;
  opacity: 0.5;
  border-bottom: 1px dotted rgba(4, 14, 38, 0.3137254902);
}
.stats_wrapper .interaction_stats h5 .pseudo_link:first-of-type {
  margin-left: 0;
}
.stats_wrapper .interaction_stats h5 .pseudo_link:hover {
  opacity: 1;
}
.stats_wrapper .interaction_stats h5 .pseudo_link.active {
  cursor: default;
  opacity: 1;
  border: 0 solid;
}
.stats_wrapper .interaction_stats .interaction_list li {
  padding: 3px 15px;
}
.stats_wrapper .interaction_stats .interaction_list li:nth-of-type(odd) {
  background-color: #F2F0F0;
}
.stats_wrapper .interaction_stats .interaction_list li .sl_title {
  display: inline-block;
  vertical-align: top;
  width: 480px;
}
.stats_wrapper .interaction_stats .interaction_list li a {
  color: unset;
  text-decoration: none;
  border-bottom: 1px solid rgba(4, 14, 38, 0.3137254902);
}/*# sourceMappingURL=stats.css.map */