@import "../styles/mixins.css";
.expanded_tab {
  max-height: 500px !important;
  transition: max-height 0.25s ease-in;
}

.student_education .main_preloader .preloader_wrapper {
  width: 100vw;
  height: 100vh;
}

.student_education_tabs {
  display: flex;
  flex-direction: row;
  background: rgba(150, 163, 174, 0.1);
  border-radius: 8px;
  padding: 4px;
  max-width: 1240px;
  margin-left: -4px;
}
@media screen and (max-width: 1400px) {
  .student_education_tabs {
    max-width: 1150px;
  }
}
@media screen and (min-width: 481px) and (max-width: 820px) {
  .student_education_tabs {
    justify-content: space-between;
  }
}
@media screen and (max-width: 480px) {
  .student_education_tabs {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: normal;
    align-items: flex-start;
    background-color: #1f57c3;
    max-height: 32px;
    overflow: hidden;
    word-break: break-word;
  }
}
.student_education_tabs .courses_counter {
  height: 20px;
  min-width: 20px;
  line-height: 20px;
  background-color: #fff;
  border-radius: 100px;
  margin-left: 10px;
}
.student_education_tabs a {
  text-decoration: none;
  color: #96A3AE;
}
.student_education_tabs .student_education_tabs_tab {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  padding: 0px 35px;
  margin: 0px 4px;
  cursor: pointer;
  text-align: center;
  line-height: 32px;
  height: 32px;
  max-width: 280px;
  min-width: 120px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}
@media screen and (max-width: 1400px) {
  .student_education_tabs .student_education_tabs_tab {
    padding: 0px 10px;
  }
}
@media screen and (max-width: 820px) and (min-width: 481px) {
  .student_education_tabs .student_education_tabs_tab {
    padding: 0px 7px;
    font-size: 14px;
    min-width: 0px;
  }
}
@media screen and (max-width: 480px) {
  .student_education_tabs .student_education_tabs_tab {
    padding: 0px 0px 0px 20px;
    justify-content: flex-start;
  }
}
.student_education_tabs .student_education_tabs_tab:hover {
  background: #1F57C3;
  border-radius: 6px;
  color: #fff;
}
.student_education_tabs .student_education_tabs_tab:hover .courses_counter {
  color: #1F57C3;
}
.student_education_tabs .education_active_tab {
  background: #1F57C3;
  border-radius: 6px;
  color: #fff;
}
.student_education_tabs .education_active_tab:hover {
  color: #fff;
}
.student_education_tabs .education_active_tab .courses_counter {
  color: #1F57C3;
}

.profile_courses_list {
  position: relative;
  padding-top: 16px;
  text-align: center;
}
.profile_courses_list .courses_block {
  margin-top: 30px;
  text-align: left;
  padding-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
@media screen and (max-width: 820px) and (min-width: 481px) {
  .profile_courses_list .courses_block {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-left: 0px;
  }
}
@media screen and (max-width: 480px) {
  .profile_courses_list .courses_block {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
body.no_dashboard .profile_courses_list .courses_block {
  text-align: center;
  margin-left: auto;
}
.profile_courses_list .status_switcher {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 32px;
}
.profile_courses_list .status_switcher a {
  display: inline-block;
  color: #040E26;
  text-decoration: none;
  margin: 0 8px;
  opacity: 0.5;
  border-bottom: 1px dotted rgba(4, 14, 38, 0.4392156863);
}
.profile_courses_list .status_switcher a:hover {
  opacity: 1;
}
.profile_courses_list .status_switcher a.active {
  cursor: default;
  opacity: 1;
  border-bottom: 0 solid;
}
.profile_courses_list .pcl_top {
  height: 120px;
}
.profile_courses_list .pcl_top h2 {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}
.profile_courses_list .no_courses {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.profile_courses_list .no_courses a {
  color: #040E26;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}