.cl_adverts {
  padding: 60px 0 30px;
}
.cl_adverts .adverts_carousel {
  position: relative;
}
.cl_adverts .adverts_carousel .feed_holder {
  overflow: hidden;
  text-align: left;
}
.cl_adverts .adverts_carousel .feed_holder.short .feed {
  justify-content: center;
}
.cl_adverts .adverts_carousel .feed_holder .feed {
  display: flex;
  flex-wrap: nowrap;
  transition: margin-left ease-out 300ms;
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item {
  width: 370px;
  flex-shrink: 0;
  margin-right: 30px;
  cursor: pointer;
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item:hover .video_bg .w_hub_logo {
  filter: blur(0.5px);
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item:hover .video_bg .btn_play svg {
  transform: scale(1.3);
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item .video_bg {
  position: relative;
  height: 250px;
  border-radius: 5px;
  background-color: #F5F5F5;
  margin-bottom: 18px;
  overflow: hidden;
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item .video_bg .preview {
  position: relative;
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item .video_bg .preview:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item .video_bg .preview .player_v2_wrapper {
  width: 370px;
  height: 250px;
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item .video_bg .preview .player_v2_wrapper .react-player__shadow {
  display: none !important;
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item .video_bg .btn_play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item .video_bg .btn_play svg {
  transition: ease-out 150ms;
}
.cl_adverts .adverts_carousel .feed_holder .feed .feed_item h5 {
  font-size: 15px;
  line-height: 20px;
  margin: 0 10px;
}
.cl_adverts .adverts_carousel .arr {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
}
.cl_adverts .adverts_carousel .arr:hover {
  opacity: 1;
}
.cl_adverts .adverts_carousel .arr:before {
  content: "";
  position: absolute;
  top: -6px;
  width: 30px;
  height: 30px;
  border: 1px solid #040E26;
  transform: rotate(45deg);
}
.cl_adverts .adverts_carousel .arr.arr_left {
  right: 100%;
  margin-right: 30px;
}
.cl_adverts .adverts_carousel .arr.arr_left:before {
  left: 7px;
}
.cl_adverts .adverts_carousel .arr.arr_right {
  left: 100%;
  margin-left: 30px;
}
.cl_adverts .adverts_carousel .arr.arr_right:before {
  right: 7px;
}
@media screen and (max-width: 700px) {
  .cl_adverts {
    padding: 41px 0;
  }
  .cl_adverts .cl_centrer {
    margin: 0;
  }
  .cl_adverts .adverts_carousel .feed_holder.short .feed {
    justify-content: flex-start;
  }
  .cl_adverts .adverts_carousel .feed_holder .feed .feed_item {
    width: 290px;
    margin-right: 20px;
  }
  .cl_adverts .adverts_carousel .feed_holder .feed .feed_item:first-of-type {
    margin-left: 20px;
  }
  .cl_adverts .arr {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */