.page_footer {
  position: relative;
  width: 1300px;
  margin: 0px auto;
  padding: 40px 0 20px;
  background-color: #96A3AE;
  border-radius: 8px;
}
@media screen and (min-width: 821px) {
  body.profile_dashboard .page_footer {
    width: auto;
    margin: 0px 0px 0px 235px;
  }
}
@media screen and (min-width: 821px) {
  body.lesson_view .page_footer {
    width: auto;
    margin: 0px 0px 0px 365px;
  }
}
.page_footer .pf_inner {
  padding: 0 40px;
  color: #fff;
}
body.no_footer .page_footer {
  display: none !important;
}
.page_footer .footer_links {
  display: flex;
  margin-bottom: 32px;
  flex-wrap: wrap;
}
.page_footer .footer_links .fl_block,
.page_footer .footer_links .footer_hidden_block {
  margin-right: 96px;
}
.page_footer .footer_links .fl_block .footer-socials,
.page_footer .footer_links .footer_hidden_block .footer-socials {
  margin-bottom: 20px;
}
.page_footer .footer_links .fl_block h5,
.page_footer .footer_links .footer_hidden_block h5 {
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}
.page_footer .footer_links .fl_block p,
.page_footer .footer_links .footer_hidden_block p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}
.page_footer .footer_links .fl_block p:last-of-type,
.page_footer .footer_links .footer_hidden_block p:last-of-type {
  margin-bottom: 0;
}
.page_footer .footer_links .fl_block p a, .page_footer .footer_links .fl_block p span,
.page_footer .footer_links .footer_hidden_block p a,
.page_footer .footer_links .footer_hidden_block p span {
  white-space: nowrap;
  color: #fff;
  text-decoration: none;
}
.page_footer .footer_links .fl_block p a.red, .page_footer .footer_links .fl_block p span.red,
.page_footer .footer_links .footer_hidden_block p a.red,
.page_footer .footer_links .footer_hidden_block p span.red {
  color: #C33333;
}
.page_footer .footer_links .fl_block p a.mail_link, .page_footer .footer_links .fl_block p span.mail_link,
.page_footer .footer_links .footer_hidden_block p a.mail_link,
.page_footer .footer_links .footer_hidden_block p span.mail_link {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3137254902);
}
.page_footer .footer_links .fl_block p span,
.page_footer .footer_links .footer_hidden_block p span {
  color: #818692;
}
.page_footer .footer_links .fl_block p span.pseudo_link,
.page_footer .footer_links .footer_hidden_block p span.pseudo_link {
  color: #fff;
  cursor: pointer;
}
.page_footer .footer_links .fl_block p span.pseudo_link.pl_dashed,
.page_footer .footer_links .footer_hidden_block p span.pseudo_link.pl_dashed {
  color: #818692;
  border-bottom: 1px dashed #818692;
}
body.no_support .page_footer .footer_links .fl_block p.tech_support_link,
body.no_support .page_footer .footer_links .footer_hidden_block p.tech_support_link {
  display: none;
}
.page_footer .footer_links .footer_hidden_block {
  display: none;
}
.page_footer .footer_links .beta_note {
  font-size: 16px;
  line-height: 24px;
  padding-top: 12px;
  margin-right: 96px;
}
.page_footer .footer_links .beta_note a {
  color: #040E26;
  text-decoration: none;
  border-bottom: 1px solid #040E26;
  white-space: nowrap;
}
.page_footer .footer_bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 40px;
  border-top: 1px solid #fff;
  font-size: 14px;
  line-height: 14px;
}
.page_footer .footer_bottom .cpr {
  text-align: left;
  color: #E6E7EA;
  white-space: nowrap;
}
.page_footer .footer_bottom .tech_support_link span {
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .page_footer {
    margin: 0 10px 0px;
    width: initial;
  }
  .page_footer .pf_inner {
    padding: 0 10px;
  }
  .page_footer .footer_links .fl_block {
    width: 100%;
    margin: 0 0 40px;
    text-align: center;
  }
  .page_footer .footer_links .fl_block:last-of-type {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 820px) and (min-width: 481px) {
  .page_footer {
    margin: 0 10px 0px;
    width: initial;
  }
  .page_footer .pf_inner {
    padding: 0 33px;
  }
  .page_footer .footer_links {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .page_footer .footer_links .fl_block {
    width: initial;
    margin: 0 0 40px;
    text-align: left;
  }
  .page_footer .footer_links .fl_block:last-of-type {
    margin-bottom: 0;
  }
}/*# sourceMappingURL=index.css.map */