.main_catalog {
  position: relative;
  max-width: 1295px;
  padding: 40px 0 20px;
  margin: 0 auto;
}
.main_catalog h2 {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}
.main_catalog .mc_feed {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
}
.main_catalog .mc_feed a {
  position: relative;
  display: block;
  width: 298px;
  height: 160px;
  text-decoration: none;
  color: unset;
  border-radius: 4px;
  overflow: hidden;
  background-color: #C4C4C4;
  margin-bottom: 30px;
}
.main_catalog .mc_feed a .mc_bg {
  height: 100%;
  border-radius: 4px;
}
.main_catalog .mc_feed a .mc_icon {
  display: none;
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: #2450FF;
  border-radius: 50%;
}
.main_catalog .mc_feed a .mc_icon img, .main_catalog .mc_feed a .mc_icon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.main_catalog .mc_feed a .cat_label {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 20px;
  bottom: 10px;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
}
.main_catalog .mc_feed a .cat_label svg {
  margin-left: 10px;
}
.main_catalog .mc_feed a .cat_fade {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(0, 0, 0, 0); /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir ); /* IE6-9 */
}
.main_catalog .show_all {
  margin-top: 15px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
  border-bottom: 1px solid #1F57C3;
  cursor: pointer;
}
.main_catalog .show_all svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 10px;
}
@media screen and (max-width: 1500px) {
  .main_catalog {
    max-width: 1160px;
  }
  .main_catalog .mc_feed a {
    width: 275px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1230px) {
  .main_catalog {
    max-width: 1280px;
    width: auto;
    margin-left: 30px;
    margin-right: 30px;
  }
  .main_catalog .mc_feed a {
    width: 226px;
  }
}
@media screen and (max-width: 780px) {
  .main_catalog {
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
  }
  .main_catalog h2, .main_catalog .show_all {
    margin-left: 20px;
  }
  .main_catalog .mc_feed {
    flex-wrap: nowrap;
    padding-left: 20px;
    overflow: auto;
  }
  .main_catalog .mc_feed a {
    width: 240px;
    margin-right: 20px;
    margin-bottom: 0;
    flex-shrink: 0;
  }
}/*# sourceMappingURL=index.css.map */