.feed_block_outer {
  position: relative;
  max-width: 1295px;
  margin: 0 auto;
}
.feed_block_outer.single_gift .feed_block_wrapper {
  height: 330px;
}
.feed_block_outer .arr {
  position: absolute;
  z-index: 5;
  top: 0;
  height: 100%;
  width: 64px;
  opacity: 0.8;
  cursor: pointer;
}
.feed_block_outer .arr i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
}
.feed_block_outer .arr i svg {
  width: 16px;
  height: 16px;
}
.feed_block_outer .arr.hidden {
  display: none;
}
.feed_block_outer .arr.arr_prev {
  right: 100%;
}
.feed_block_outer .arr.arr_next {
  left: 100%;
}
.feed_block_outer .arr.arr_next i svg {
  transform: rotate(180deg);
}
@media screen and (max-width: 1500px) {
  .feed_block_outer {
    max-width: 1160px;
  }
}
@media screen and (max-width: 1230px) {
  .feed_block_outer {
    max-width: 1280px;
    width: auto;
  }
  .feed_block_outer .arr {
    display: none;
  }
  .feed_block_outer .feed_block {
    margin-left: 30px;
  }
}
@media screen and (max-width: 780px) {
  .feed_block_outer .feed_block {
    margin-left: 20px;
  }
}

.feed_block_wrapper {
  overflow: hidden;
  position: relative;
  height: 338px;
}

.feed_block {
  position: absolute;
  top: 0;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
  transition: left ease-out 300ms;
}
.feed_block .course_preview:first-of-type {
  margin-left: 0;
}/*# sourceMappingURL=index.css.map */