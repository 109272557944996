.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 36px;
  text-align: inherit;
  max-height: 90vh;
  overflow: auto;
}
@media screen and (max-width: 600px) {
  .Modal {
    width: 80%;
  }
}
.Modal.no_padding {
  padding: 0;
}
.Modal .modal_close {
  position: absolute;
  z-index: 300;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.Modal .video_modal {
  width: 640px;
  height: 360px;
}
.Modal .video_modal .player_v2_wrapper {
  width: 640px;
  height: 360px;
}
.Modal .video_modal .cannot_play {
  padding: 30px;
}
.Modal .video_modal .cannot_play h3 {
  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 30px;
}
@media screen and (max-width: 600px) {
  .Modal {
    padding: 16px;
  }
  .Modal .video_modal {
    width: 100%;
    height: 32vh;
  }
  .Modal .video_modal .player_v2_wrapper {
    width: 100%;
    height: 32vh;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9909;
}

.modal_purchase_link {
  text-align: center;
}
.modal_purchase_link p {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}

.crunch_reg_link {
  text-align: center;
  padding-top: 16px;
}
.crunch_reg_link a {
  color: #434A5C;
  border-bottom: 1px solid rgba(60, 30, 30, 0.5);
  text-decoration: none;
}/*# sourceMappingURL=index.css.map */