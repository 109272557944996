.course_wrapper {
  padding-top: 70px;
}
@media screen and (max-width: 1050px) {
  .course_wrapper {
    padding-top: 60px;
  }
}
.course_wrapper .course_default .course_top .bg {
  min-height: 430px;
}
.course_wrapper .course_top {
  position: relative;
  max-height: 587px;
  overflow: hidden;
  z-index: 10;
}
.course_wrapper .course_top.book_cover {
  overflow: unset;
  z-index: 30;
}
.course_wrapper .course_top .bg {
  height: 100%;
  position: relative;
}
.course_wrapper .course_top .bg .cover_wrapper {
  background-color: #000;
}
.course_wrapper .course_top .bg .cover_wrapper img {
  opacity: 0.6;
}
.course_wrapper .course_top .bg.book_cover {
  text-align: right;
  height: auto;
  padding: 112px 0 0;
  z-index: 10;
}
.course_wrapper .course_top .bg.book_cover .cover_wrapper {
  position: relative;
  display: inline-block;
  margin-right: 64px;
  width: 416px;
  height: 416px;
}
.course_wrapper .course_top .bg.book_cover .cover_wrapper img {
  opacity: 1;
}
.course_wrapper .course_top .bg.book_cover .cover_wrapper .book_cover_cutter {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.course_wrapper .course_top .bg.book_cover .cover_wrapper .btn_play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 30;
  width: 100%;
  height: 100%;
}
.course_wrapper .course_top .bg.book_cover .cover_wrapper .btn_play svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
}
.course_wrapper .course_top .bg.book_cover .cover_wrapper .btn_play svg path {
  fill: #46C790;
}
.course_wrapper .course_top .bg.book_cover .cover_wrapper img {
  width: 100%;
}
@media screen and (max-width: 1050px) {
  .course_wrapper .course_top .bg.book_cover {
    text-align: center;
    min-height: 0;
  }
  .course_wrapper .course_top .bg.book_cover .cover_wrapper {
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    min-height: 0;
    position: relative;
    width: 50%;
    min-width: 300px;
    height: auto;
    margin: 0 auto -5px;
  }
}
@media screen and (max-width: 380px) {
  .course_wrapper .course_top .bg.book_cover {
    text-align: center;
    min-height: 0;
  }
  .course_wrapper .course_top .bg.book_cover .cover_wrapper {
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    min-height: 0;
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto -5px;
  }
}
.course_wrapper .course_top .bg .cover_wrapper {
  width: 100%;
}
.course_wrapper .course_top .bg .cover_wrapper img {
  width: 100%;
}
.course_wrapper .course_top .ct_content {
  position: absolute;
  bottom: 46px;
  left: 64px;
  margin-left: 225px;
  width: 525px;
  color: #fff;
  z-index: 10;
}
.course_wrapper .course_top .ct_content h6 {
  font-size: 10px;
  line-height: 24px;
  opacity: 0.75;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  display: inline-block;
}
.course_wrapper .course_top .ct_content h6 a {
  display: inline-block;
  margin-right: 12px;
  color: unset;
  text-decoration: none;
  line-height: 10px;
}
.course_wrapper .course_top .ct_content h6 span a {
  margin-left: 10px;
}
.course_wrapper .course_top .ct_content h6 svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-top: -2px;
}
.course_wrapper .course_top .ct_content h1 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
.course_wrapper .course_top .ct_content .teaser {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.course_wrapper .course_body {
  position: relative;
  z-index: 20;
  min-height: 780px;
}
.course_wrapper .course_side_wrapper {
  position: absolute;
  right: 0;
  top: -70px;
}
.course_wrapper .course_side_wrapper .course_side {
  position: absolute;
  right: 64px;
  top: 134px;
  width: 416px;
}
@media screen and (min-width: 600px) {
  .course_wrapper .course_side_wrapper .course_side.fixed .cs_info {
    opacity: 1;
  }
}
.course_wrapper .course_side_wrapper .course_side .buttons_block {
  display: block;
}
.course_wrapper .course_side_wrapper .course_side .buttons_block .discount_disclamer {
  margin-bottom: 16px;
  font-weight: bold;
  text-align: center;
}
.course_wrapper .course_side_wrapper .course_side .buttons_block a {
  text-decoration: none;
  color: unset;
}
.course_wrapper .course_side_wrapper .course_side .buttons_block button, .course_wrapper .course_side_wrapper .course_side .buttons_block a {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}
@media screen and (max-width: 1050px) {
  .course_wrapper .course_side_wrapper .course_side .buttons_block button, .course_wrapper .course_side_wrapper .course_side .buttons_block a {
    width: 270px;
    margin: 0 auto 16px;
  }
}
.course_wrapper .course_side_wrapper .course_side .buttons_block a button {
  margin-bottom: 0;
}
.course_wrapper .course_side_wrapper .course_side .buttons_block.school_lock {
  text-align: center;
  display: block;
}
.course_wrapper .course_side_wrapper .course_side .buttons_block .outofstock_overlay {
  position: absolute;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.course_wrapper .course_side_wrapper .course_side .buttons_block .outofstock_overlay p {
  text-align: center;
}
.course_wrapper .course_side_wrapper .cs_info {
  padding-top: 24px;
  opacity: 0;
  transition: linear 300ms;
}
.course_wrapper .course_side_wrapper .cs_info h6 {
  font-size: 10px;
  line-height: 24px;
  opacity: 0.75;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  display: inline-block;
  padding-right: 15px;
}
.course_wrapper .course_side_wrapper .cs_info h6 a {
  display: inline-block;
  color: unset;
  text-decoration: none;
  margin-right: 12px;
}
.course_wrapper .course_side_wrapper .cs_info h6 span a {
  margin-left: 10px;
}
.course_wrapper .course_side_wrapper .cs_info h6 svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-top: -2px;
}
.course_wrapper .course_side_wrapper .cs_info h6 svg circle {
  stroke: #000;
}
.course_wrapper .course_side_wrapper .cs_info h6 svg circle.filled {
  stroke: #000;
  fill: #000;
}
.course_wrapper .course_side_wrapper .cs_info h2 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
.course_wrapper .course_side_wrapper .cs_info .course_params dl dd svg path {
  fill: #434A5C;
}
.course_wrapper .course_text {
  margin: 0 544px 0 64px;
  padding: 64px 0 100px;
}
.course_wrapper .course_text .course_grid_item {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
}
.course_wrapper .course_text .course_grid_item h5 {
  width: 182px;
  margin-right: 40px;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 24px;
  color: #434A5C;
  opacity: 0.5;
}
.course_wrapper .course_text .course_grid_item .cgi_content {
  position: relative;
  padding-bottom: 48px;
  width: 100%;
}
.course_wrapper .course_text .course_grid_item .cgi_content a {
  color: #434A5C;
  text-decoration: none;
  border-bottom: 1px solid #434A5C;
}
.course_wrapper .course_text .course_grid_item:last-of-type .cgi_content {
  padding-bottom: 0;
}
.course_wrapper .course_text .course_description {
  padding-right: 100px;
  color: #434A5C;
  white-space: pre-line;
}
.course_wrapper .course_text .course_content.cc_generated_lessons ol .ol_item a {
  color: #040E26;
  text-decoration: none;
}
.course_wrapper .course_text .course_content ol {
  list-style-type: decimal;
  margin-left: -32px;
  margin-top: -24px;
  color: #040E26;
}
.course_wrapper .course_text .course_content ol .ol_item {
  position: relative;
  font-size: 24px;
  line-height: 32px;
  width: calc(100% - 100px);
  padding: 18px 100px 14px 32px;
}
.course_wrapper .course_text .course_content ol .ol_item.with_subcontent {
  background-color: #F2F0F0;
}
.course_wrapper .course_text .course_content ol .ol_item:before {
  content: attr(data-idx);
  display: inline-block;
  vertical-align: top;
  width: 40px;
  margin-left: -40px;
  color: #434A5C;
  opacity: 0.5;
}
.course_wrapper .course_text .course_content ol .ol_item.lecture {
  padding-right: 180px;
  width: calc(100% - 180px);
}
.course_wrapper .course_text .course_content ol .ol_item.lecture .lecture_params {
  position: absolute;
  right: 16px;
  top: 14px;
  text-align: right;
  font-size: 14px;
  line-height: 17px;
}
.course_wrapper .course_text .course_content ol .ol_item .subcontent ul {
  margin-top: 14px;
  padding: 24px 0;
}
.course_wrapper .course_text .course_content ol .ol_item .subcontent ul li {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}
.course_wrapper .course_text .course_content ol .ol_item .subcontent ul li:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #040E26;
  margin-right: 8px;
  margin-top: 7px;
}
.course_wrapper .course_text .course_content ol .ol_item .subcontent p {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
}
.course_wrapper .course_text .author_comment {
  padding-right: 100px;
  color: #434A5C;
}
.course_wrapper .course_text .students_feedback {
  padding-right: 100px;
}
.course_wrapper .course_text .students_feedback .feedback_item {
  color: #434A5C;
  margin-bottom: 48px;
}
.course_wrapper .course_text .students_feedback .feedback_item:last-of-type {
  margin-bottom: 0;
}
.course_wrapper .course_text .students_feedback .feedback_item p {
  margin-bottom: 24px;
}
.course_wrapper .course_text .students_feedback .feedback_item p.fi_respondent {
  text-align: right;
  margin-bottom: 0;
  color: #C0C2C8;
}
@media screen and (max-width: 1050px) {
  .course_wrapper .course_top {
    height: auto;
    max-height: 2000px;
    padding-bottom: 50px;
  }
  .course_wrapper .course_top.book_cover {
    padding-bottom: 0;
  }
  .course_wrapper .course_top .bg {
    height: 445px;
  }
  .course_wrapper .course_top .bg .cover_wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: auto;
  }
  .course_wrapper .course_top .bg .cover_wrapper img {
    height: 100%;
    width: auto;
  }
  .course_wrapper .course_top .ct_content {
    position: relative;
    left: auto;
    bottom: auto;
    width: auto;
    margin: 0 24px;
    z-index: 0;
  }
  .course_wrapper .course_top .ct_content .ct_headers {
    margin-top: -200px;
    height: 200px;
    margin-bottom: 24px;
  }
  .course_wrapper .course_top .ct_content.book_cover .ct_headers {
    height: auto;
    margin-top: 0;
    padding-top: 20px;
    color: #434A5C;
  }
  .course_wrapper .course_top .ct_content .course_params dl dd a {
    color: #434A5C;
    border-bottom-color: #434A5C;
  }
  .course_wrapper .course_top .ct_content .course_params {
    color: #434A5C;
  }
  .course_wrapper .course_top .ct_content .course_params dl dd svg path {
    fill: #434A5C;
  }
  .course_wrapper .course_body {
    min-height: 0;
  }
  .course_wrapper .course_side_wrapper {
    position: relative;
    right: auto;
    top: auto;
    margin: 0 24px 37px;
  }
  .course_wrapper .course_side_wrapper .course_side {
    position: relative;
    right: auto;
    top: auto;
    width: auto;
  }
  .course_wrapper .course_side_wrapper .course_side .buttons_block button {
    width: 49%;
    min-width: 240px;
    white-space: nowrap;
  }
  .course_wrapper .course_side_wrapper .course_side .buttons_block a {
    width: 49%;
    min-width: 240px;
    position: relative;
  }
  .course_wrapper .course_side_wrapper .course_side .buttons_block a button {
    width: 100%;
  }
  .course_wrapper .course_side_wrapper .course_side .cs_info {
    display: none;
  }
  .course_wrapper .course_text {
    margin: 0 24px;
    padding: 0 0 56px;
    border-top: 1px solid #E6E7EA;
  }
  .course_wrapper .course_text .course_grid_item {
    padding: 28px 0;
    display: block;
  }
  .course_wrapper .course_text .course_grid_item h5 {
    width: auto;
    margin-bottom: 24px;
  }
  .course_wrapper .course_text .course_grid_item .cgi_content {
    padding-bottom: 0;
  }
  .course_wrapper .course_text .course_description,
  .course_wrapper .course_text .author_comment,
  .course_wrapper .course_text .students_feedback {
    padding-right: 0;
  }
  .course_wrapper .course_text .course_content ol {
    margin-top: 0;
    margin-left: -24px;
  }
  .course_wrapper .course_text .course_content ol .ol_item {
    padding: 16px 24px;
    width: calc(100% - 24px);
    font-size: 16px;
    line-height: 24px;
    border-top: 1px solid #E6E7EA;
  }
  .course_wrapper .course_text .course_content ol .ol_item ul {
    margin-top: 0;
  }
  .course_wrapper .course_text .course_content ol .ol_item ul li {
    font-size: 10px;
    line-height: 13px;
    margin-bottom: 3px;
  }
  .course_wrapper .course_text .course_content ol .ol_item ul li:before {
    width: 3px;
    height: 3px;
    margin-right: 6px;
    margin-top: 4px;
  }
}/*# sourceMappingURL=course.css.map */