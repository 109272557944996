.preloader_wrapper {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}
.preloader_wrapper.search_preloader {
  z-index: 20;
  position: fixed;
}
.preloader_wrapper.bottom_loader {
  position: relative;
  height: 100px;
  left: auto;
  top: auto;
}
.preloader_wrapper .logo_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0;
  height: 0;
  animation: preloader_rotate 4s infinite;
}
.preloader_wrapper .logo_wrapper .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  width: 40px;
  margin: 0 auto;
}
.preloader_wrapper .logo_wrapper .logo span {
  position: absolute;
  left: 0;
  text-transform: uppercase;
  font-family: "Source Black", sans-serif;
  font-size: 40px;
  line-height: 40px;
  top: 0;
}
.preloader_wrapper .logo_wrapper .logo span.w {
  color: #FF00C9;
  left: 0;
  animation: preloader_w 4s infinite;
}
.preloader_wrapper .logo_wrapper .logo span.hub {
  left: 13px;
  mix-blend-mode: multiply;
  color: #02FF00;
  animation: preloader_h 4s infinite;
}
.preloader_wrapper .logo_wrapper .logo.white a span.w {
  color: #FF0079;
}
.preloader_wrapper .logo_wrapper .logo.white a span.hub {
  mix-blend-mode: screen;
  color: #00FFFF;
}

@keyframes preloader_rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(1440deg);
  }
  100% {
    transform: rotate(1440deg);
  }
}
@keyframes preloader_w {
  0% {
    left: 0;
    opacity: 1;
  }
  20% {
    opacity: 0.5;
  }
  50% {
    left: 54px;
    opacity: 1;
  }
  75% {
    left: 0;
  }
  100% {
    left: 0;
  }
}
@keyframes preloader_h {
  0% {
    left: 13px;
    opacity: 1;
  }
  20% {
    opacity: 0.5;
  }
  50% {
    left: -46px;
    opacity: 1;
  }
  75% {
    left: 13px;
  }
  100% {
    left: 13px;
  }
}