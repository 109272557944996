.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-size: 0;
  text-align: left;
  padding-left: 22px;
}
.checkbox.disabled {
  opacity: 0.4;
}
.checkbox.disabled label, .checkbox.disabled input {
  cursor: default;
}
.checkbox label {
  cursor: pointer;
  display: block;
}
.checkbox.checked ins {
  background-color: #040E26;
}
.checkbox.checked ins svg {
  display: block;
}
.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
  height: 0;
}
.checkbox ins {
  position: absolute;
  left: 0;
  top: 6px;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border: 1px solid #040E26;
  box-sizing: border-box;
  border-radius: 2px;
}
.checkbox ins svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}
.checkbox span {
  vertical-align: middle;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  white-space: normal;
}
.checkbox span a {
  color: #040E26;
}/*# sourceMappingURL=checkbox.css.map */