.school_experts {
  margin: 0 24px;
  padding-bottom: 80px;
}
.school_experts h1 {
  font-size: 21px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 18px;
}
.school_experts p {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 18px;
}
.school_experts .add_author_button {
  margin-bottom: 16px;
}
.school_experts .experts_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.school_experts .experts_list_add_btn {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 370px;
  height: 350px;
  justify-content: center;
  align-items: center;
}
.school_experts .experts_list .expert_item {
  position: relative;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 370px;
  height: 350px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 8px;
}
.school_experts .experts_list .expert_item_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
}
.school_experts .experts_list .expert_item_header_info {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.school_experts .experts_list .expert_item_header_info_title {
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  color: #000000;
}
.school_experts .experts_list .expert_item_header_info_semititle {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #040E26;
}
.school_experts .experts_list .expert_item_header_avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background-size: contain;
  box-sizing: border-box;
}
.school_experts .experts_list .expert_item_header_avatar.letter {
  border: 1px solid rgba(150, 163, 174, 0.2);
}
.school_experts .experts_list .expert_item_content {
  max-height: 130px;
  overflow: hidden;
  width: 300px;
  text-align: left;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 8px;
}
.school_experts .experts_list .expert_item_footer {
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 25px;
}
.school_experts .experts_list .expert_item_footer_edit {
  background-repeat: no-repeat;
  background-position: center;
}
.school_experts .experts_list .expert_item_footer_dlt {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.school_experts .experts_list .expert_item_footer div {
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(150, 163, 174, 0.4);
  border-radius: 20px;
}
.school_experts .experts_list li {
  background-color: #fff;
  padding: 18px;
  border-radius: 4px;
  margin-bottom: 24px;
  position: relative;
}
.school_experts .experts_list li dl {
  font-size: 14px;
  line-height: 19px;
  display: flex;
  margin-bottom: 16px;
}
.school_experts .experts_list li dl:last-of-type {
  margin-bottom: 0;
}
.school_experts .experts_list li dl dt {
  width: 100px;
  flex-shrink: 0;
  text-align: right;
  margin-right: 16px;
  font-weight: bold;
}
.school_experts .experts_list li dl dd {
  width: 100%;
}
.school_experts .experts_list li dl dd .text_input {
  font-size: 14px;
  line-height: 14px;
  width: 100%;
}
.school_experts .experts_list li dl dd textarea {
  width: 100%;
  height: 60px;
  margin-top: -1px;
}

.cnc_plus {
  position: relative;
  width: 64px;
  height: 64px;
  margin: 0 auto 24px;
  border-radius: 50%;
  background-color: #2450FF;
}
.cnc_plus svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
}

.cnc_text {
  display: inline-block;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  border-bottom: 1px solid rgba(4, 14, 38, 0.3137254902);
}