.search_results {
  position: relative;
  text-align: center;
  padding-top: 112px;
  margin: 0 64px;
}
.search_results.search_articles {
  text-align: left;
}
.search_results.search_articles h1 {
  margin-bottom: 40px;
  margin-left: 15px;
}
.search_results.search_articles .blog_feed_item {
  margin: 0 15px 30px;
}
.search_results .search_cats {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
  z-index: 20;
}
.search_results .search_cats .category_slash {
  margin: 0 24px;
}
@media screen and (max-width: 600px) {
  .search_results {
    margin: 0 24px;
  }
  .search_results .search_cats {
    margin-bottom: 32px;
    flex-direction: column;
  }
  .search_results .search_cats .category_slash {
    display: none;
  }
}
.search_results .switcher {
  font-size: 0;
  white-space: nowrap;
  margin: 0 -12px -11px;
}
.search_results .switcher li {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 16px;
  padding: 0 12px;
  color: #C0C2C8;
  cursor: default;
}
.search_results .switcher li h3 {
  padding-bottom: 10px;
  font-size: 24px;
  line-height: 1;
}
.search_results .switcher li.active {
  color: #040E26;
}
.search_results .switcher li.active h3 {
  border-bottom: 1px solid #040E26;
}
.search_results .search-type {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 32px;
  z-index: 5;
}
.search_results .search-filters {
  position: relative;
  margin-bottom: 24px;
  padding: 20px 24px;
  background-color: #F2F0F0;
}
.search_results .search-filters__top {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.search_results .search-filters__top:not(:last-child) {
  margin-bottom: 20px;
}
.search_results .search-filters__bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.search_results .search-filters__bottom._disabled {
  pointer-events: none;
  opacity: 0.25;
}
.search_results .search-filters .cats_section {
  display: flex;
}
.search_results .search-filters .cats_section .dropdown {
  margin-right: 24px;
}
.search_results .search-filters .options_section {
  display: flex;
}
.search_results .search-filters .options_section .checkbox {
  margin-left: 50px;
}
@media screen and (max-width: 600px) {
  .search_results .switcher {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  .search_results .switcher li {
    width: 100%;
    text-align: center;
  }
  .search_results .switcher li:first-child {
    text-align: left;
  }
  .search_results .switcher li:last-child {
    text-align: right;
  }
  .search_results .switcher li h3 {
    display: inline-block;
    padding-bottom: 6px;
    font-size: 16px;
  }
  .search_results .search-type {
    display: block;
    margin-bottom: 0;
  }
  .search_results .search-type__sort {
    display: flex;
    justify-content: center;
    padding: 15px 24px 15px;
    margin-left: -24px;
    margin-right: -24px;
    background-color: #F2F0F0;
    position: relative;
    z-index: 10;
  }
  .search_results .search-filters {
    width: calc(100vw - 48px);
    margin-left: -24px;
    padding-top: 15px;
    padding-bottom: 70px;
    border-radius: 0;
  }
  .search_results .search-filters__top {
    flex-direction: column;
  }
  .search_results .search-filters__top .dropdown {
    margin-bottom: 32px;
  }
  .search_results .search-filters__top .star-rating__container {
    margin-bottom: 5px;
  }
  .search_results .search-filters .cats_section {
    margin: 0 auto;
  }
  .search_results .search-filters .ch_free {
    position: absolute;
    bottom: 25px;
  }
}/*# sourceMappingURL=search-result.css.map */