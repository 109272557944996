.main_content .main_centrer {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1500px) {
  .main_content .main_centrer {
    max-width: 1160px;
  }
}
@media screen and (max-width: 1230px) {
  .main_content .main_centrer {
    width: auto;
    max-width: 1300px;
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media screen and (max-width: 780px) {
  .main_content .main_centrer {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.main_content .dup_main_search {
  margin-bottom: 10px;
  padding-top: 40px;
}
.main_content .dup_main_search .main_search_bar input {
  border-color: #1F57C3;
}

.mp_banner {
  display: none;
  height: 20vh;
  background-color: #F2F0F0;
}/*# sourceMappingURL=index.css.map */