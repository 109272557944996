.header_plus_wrapper {
  display: inline-block;
  vertical-align: top;
  width: 40px;
  height: 40px;
  margin-left: 30px;
}
.header_plus_wrapper .header_plus_button {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2450FF;
}
.header_plus_wrapper .header_plus_button .plus_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header_plus_wrapper .header_plus_button.opened .ua_dropdown {
  display: block;
}
.header_plus_wrapper .header_plus_button .ua_dropdown {
  position: absolute;
  right: 0;
  z-index: 2;
  top: 100%;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(38, 4, 4, 0.25);
  border-radius: 8px;
  min-width: 150px;
  display: none;
}
.header_plus_wrapper .header_plus_button .ua_dropdown .tool_tip {
  right: 13px;
  position: absolute;
  bottom: 100%;
}
.header_plus_wrapper .header_plus_button .ua_dropdown a {
  display: block;
  text-decoration: none;
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  padding: 18px 16px;
  border-bottom: 1px solid #E6E7EA;
  color: #040E26;
}
.header_plus_wrapper .header_plus_button .ua_dropdown a.ua_logout {
  text-align: center;
  color: #C43333;
}
.header_plus_wrapper .header_plus_button .ua_dropdown a:last-of-type {
  border-bottom: 0 solid;
}/*# sourceMappingURL=plus.css.map */