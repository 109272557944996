.cl_faq {
  padding: 68px 0 80px;
  border-bottom: 1px solid #F5F5F5;
}
.cl_faq .cl_centrer {
  display: flex;
}
.cl_faq .cl_centrer h2 {
  width: calc(50% - 130px);
  padding-right: 130px;
}
.cl_faq .cl_centrer .faq_block {
  width: 50%;
}
.cl_faq .cl_centrer .faq_block dl {
  border-top: 1px solid rgba(4, 14, 38, 0.062745098);
}
.cl_faq .cl_centrer .faq_block dl:first-of-type {
  border-top: 0 solid;
}
.cl_faq .cl_centrer .faq_block dl.opened dd {
  display: block;
}
.cl_faq .cl_centrer .faq_block dl dt {
  position: relative;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  padding: 34px 50px 34px 0;
  cursor: pointer;
}
.cl_faq .cl_centrer .faq_block dl dt .fold_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 20px;
}
.cl_faq .cl_centrer .faq_block dl dt .fold_icon svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.cl_faq .cl_centrer .faq_block dl dd {
  display: none;
  font-size: 17px;
  line-height: 25px;
  padding-bottom: 40px;
}
@media screen and (max-width: 1180px) {
  .cl_faq .cl_centrer {
    display: block;
  }
  .cl_faq .cl_centrer h2 {
    width: auto;
    padding-right: 0;
    margin-bottom: 40px;
  }
  .cl_faq .cl_centrer .faq_block {
    width: auto;
  }
}
@media screen and (max-width: 700px) {
  .cl_faq {
    padding: 41px 0 50px;
  }
  .cl_faq .cl_centrer {
    margin: 0;
  }
  .cl_faq .cl_centrer h2 {
    margin: 0 20px 31px;
  }
  .cl_faq .cl_centrer .faq_block dl {
    padding: 0 20px;
  }
  .cl_faq .cl_centrer .faq_block dl:first-of-type {
    border-top: 1px solid rgba(4, 14, 38, 0.062745098);
  }
  .cl_faq .cl_centrer .faq_block dl dt {
    font-size: 17px;
    line-height: 25px;
    padding: 22px 50px 22px 0;
  }
}/*# sourceMappingURL=index.css.map */