.lesson_sidebar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}

.lesson_sidebar::-webkit-scrollbar {
  display: block;
  width: 14px;
  background-color: none;
}

.lesson_sidebar::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #EAEDEF;
}

.lesson_wrapper .unauthed_banner {
  position: relative;
  background-color: #040E26;
  color: #fff;
  text-align: center;
  padding: 24px 80px;
  font-size: 13px;
  line-height: 19px;
}
.lesson_wrapper .unauthed_banner span {
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.lesson_wrapper .unauthed_banner ins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  cursor: pointer;
}
.lesson_wrapper .unauthed_banner ins svg rect {
  fill: #fff;
}
.lesson_wrapper .lesson_dashboard {
  position: relative;
  padding-left: 360px;
}
.lesson_wrapper .lesson_dashboard .lesson_overlay {
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1882352941);
  display: none;
}
@media screen and (max-width: 1180px) {
  .lesson_wrapper .lesson_sidebar {
    left: -360px;
    z-index: 600;
    transition: left ease-out 150ms;
  }
  .lesson_wrapper .lesson_dashboard {
    padding-left: 0;
  }
  .lesson_wrapper.sidebar_opened .lesson_sidebar {
    left: 0;
  }
  .lesson_wrapper.sidebar_opened .lesson_overlay {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .lesson_wrapper .lesson_sidebar {
    left: -100%;
  }
}