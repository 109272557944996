.ceb_advert .advert_block {
  margin-bottom: 48px;
}
.ceb_advert .advert_block .packages_list {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.ceb_advert .advert_block .packages_list .radio_wrapper {
  flex-shrink: 0;
  width: 400px;
  margin: 0 16px;
}
.ceb_advert .advert_block .packages_list .radio_wrapper.simple {
  width: auto;
}
.ceb_advert .advert_block .packages_list .radio_wrapper.simple label {
  padding-left: 60px;
}
.ceb_advert .advert_block .packages_list .radio_wrapper.simple label .point {
  left: 28px;
}
.ceb_advert .advert_block .packages_list .radio_wrapper.simple input:checked + label {
  background-color: #fff;
}
.ceb_advert .advert_block .packages_list .radio_wrapper.simple:hover label {
  background-color: #fff;
}
.ceb_advert .advert_block .packages_list .radio_wrapper .ai_price {
  font-size: 24px;
  line-height: 32px;
  padding-top: 24px;
  border-top: 1px solid #C0C2C8;
}
.ceb_advert .advert_datepicker h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
.ceb_advert .ce_button_holder {
  padding-top: 32px;
}
.ceb_advert .agreement_wrapper {
  padding-top: 32px;
}
.ceb_advert .radio_wrapper {
  position: relative;
}
.ceb_advert .radio_wrapper label {
  position: relative;
  display: block;
  padding: 48px 48px 40px 80px;
  text-align: left;
  cursor: pointer;
  height: calc(100% - 98px);
}
.ceb_advert .radio_wrapper label:hover {
  background-color: #F2F0F0;
}
.ceb_advert .radio_wrapper label:hover p {
  color: #434A5C;
}
.ceb_advert .radio_wrapper label .point {
  position: absolute;
  left: 48px;
  top: 58px;
  background-color: #fff;
  border: 1px solid #C0C2C8;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.ceb_advert .radio_wrapper label h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
.ceb_advert .radio_wrapper label p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  color: #C0C2C8;
}
.ceb_advert .radio_wrapper input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
  height: 0;
  left: -100px;
  top: -100px;
}
.ceb_advert .radio_wrapper input:checked + label {
  background-color: #F2F0F0;
}
.ceb_advert .radio_wrapper input:checked + label .point:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #040E26;
}
.ceb_advert .radio_wrapper input:checked + label p {
  color: #434A5C;
}/*# sourceMappingURL=advert.css.map */