@charset "UTF-8";
.ls_profitable_wrapper {
  background-color: #F8F8FA;
  padding-top: 100px;
  padding-bottom: 30px;
}
.ls_profitable_wrapper h2 {
  margin-bottom: 70px;
}
.ls_profitable_wrapper ul {
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-start;
  align-items: flex-start;
}
.ls_profitable_wrapper ul li {
  position: relative;
  width: 367px;
  height: 380px;
  box-shadow: 20px 20px 40px rgba(0, 22, 82, 0.0509803922);
  border-radius: 20px;
  margin-right: 35px;
  margin-bottom: 35px;
  overflow: hidden;
}
.ls_profitable_wrapper ul li:nth-of-type(1) {
  background-color: #FFC8D8;
}
.ls_profitable_wrapper ul li:nth-of-type(2) {
  background-color: #D8D6FF;
}
.ls_profitable_wrapper ul li:nth-of-type(3) {
  background-color: #CFFFE8;
}
.ls_profitable_wrapper ul li:nth-of-type(4) {
  background-color: #fff;
}
.ls_profitable_wrapper ul li:nth-of-type(5) {
  background-color: #FFFCF2;
  margin-right: 0;
}
.ls_profitable_wrapper ul li .item_bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  bottom: 0;
}
.ls_profitable_wrapper ul li .item_bg.corner {
  width: calc(100% - 80px);
  text-align: right;
  bottom: 28px;
}
.ls_profitable_wrapper ul li .item_bg img {
  width: auto;
}
.ls_profitable_wrapper ul li .item_bg img.pic_5 {
  display: inline-block;
  height: 96px;
}
.ls_profitable_wrapper ul li .item_text {
  margin: 0 40px;
  padding-top: 32px;
  font-size: 24px;
  line-height: 35px;
  font-weight: 500;
}
.ls_profitable_wrapper ul li .item_text ul {
  width: auto;
  padding-top: 18px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  display: block;
}
.ls_profitable_wrapper ul li .item_text ul li {
  position: relative;
  padding-left: 32px;
  width: auto;
  height: auto;
  margin: 0 0 18px;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent !important;
}
.ls_profitable_wrapper ul li .item_text ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "—";
}
@media screen and (max-width: 1050px) {
  .ls_profitable_wrapper ul li {
    width: 288px;
    height: 328px;
    margin-right: 24px;
    margin-bottom: 24px;
  }
  .ls_profitable_wrapper ul li .item_bg.corner {
    width: calc(100% - 17px);
    bottom: 8px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_1 {
    height: 202px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_2 {
    height: 187px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_3 {
    height: 213px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_4 {
    height: 186px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_5 {
    display: inline-block;
    height: 66px;
  }
  .ls_profitable_wrapper ul li .item_text {
    margin: 0 28px;
    padding-top: 28px;
  }
}
@media screen and (max-width: 600px) {
  .ls_profitable_wrapper {
    padding: 46px 0 0;
  }
  .ls_profitable_wrapper h2 {
    margin-bottom: 28px;
  }
  .ls_profitable_wrapper ul {
    flex-wrap: nowrap;
    overflow: auto;
    width: 100vw;
    margin-left: -25px;
    padding-bottom: 28px;
  }
  .ls_profitable_wrapper ul li {
    flex-shrink: 0;
    height: 328px;
    width: calc(100vw - 80px);
  }
  .ls_profitable_wrapper ul li:first-of-type {
    margin-left: 25px;
  }
  .ls_profitable_wrapper ul li:last-of-type {
    margin-right: 25px;
  }
  .ls_profitable_wrapper ul li .item_bg.corner {
    width: calc(100% - 17px);
    bottom: 8px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_1 {
    height: 202px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_2 {
    height: 187px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_3 {
    height: 213px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_4 {
    height: 186px;
  }
  .ls_profitable_wrapper ul li .item_bg img.pic_5 {
    display: inline-block;
    height: 66px;
  }
  .ls_profitable_wrapper ul li .item_text {
    font-size: 18px;
    line-height: 25px;
  }
  .ls_profitable_wrapper ul li .item_text ul {
    font-size: 13px;
    line-height: 18px;
    margin-left: 0;
  }
}/*# sourceMappingURL=index.css.map */