.crop_resize_wrapper {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.crop_resize_wrapper .reactEasyCrop_CropArea {
  box-shadow: 0 0 0 150px rgba(0, 0, 0, 0.5647058824);
}
.crop_resize_wrapper h4 {
  font: normal normal 600 24px/24px Manrope;
  color: #000000;
  margin-bottom: 4px;
}
.crop_resize_wrapper h6 {
  font: normal normal 500 12px/24px Manrope;
  color: #88888F;
  margin-bottom: 36px;
}
.crop_resize_wrapper .crop_tools {
  margin-left: 24px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  width: 300px;
}
.crop_resize_wrapper .orientation_wrapper {
  margin-bottom: 32px;
}
.crop_resize_wrapper .orientation_wrapper .radio_wrapper {
  margin-bottom: 16px;
}
.crop_resize_wrapper .cropper_wrapper {
  position: relative;
  overflow: hidden;
  width: 400px;
  height: 400px;
  background-color: #F7F7F7;
  display: inline-block;
  vertical-align: top;
}
.crop_resize_wrapper .cropper_wrapper .reactEasyCrop_CropAreaGrid,
.crop_resize_wrapper .cropper_wrapper .reactEasyCrop_CropAreaGrid:before,
.crop_resize_wrapper .cropper_wrapper .reactEasyCrop_CropAreaGrid:after {
  border-color: rgba(247, 247, 247, 0.5647058824);
}
.crop_resize_wrapper .crop_buttons button {
  cursor: pointer;
  font: normal normal 600 14px/14px Manrope;
  padding: 18px 0 19px;
  width: 100%;
  color: #0064FF;
  border: 2px solid #0064FF;
  border-radius: 28px;
  background-color: #fff;
  margin-bottom: 16px;
}
.crop_resize_wrapper .crop_buttons button.ok_button {
  background-color: #0064FF;
  color: #fff;
  margin-right: 24px;
}
.crop_resize_wrapper .zoom_slider {
  margin: 0 12px 32px;
}
.crop_resize_wrapper .zoom_slider .rc-slider-handle {
  background-color: #0064FF;
}
.crop_resize_wrapper .zoom_slider .rc-slider-handle:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.1254901961);
}
.crop_resize_wrapper .zoom_slider .rc-slider-track,
.crop_resize_wrapper .zoom_slider .rc-slider-rail {
  background-color: #F7F7F7;
}
@media screen and (max-width: 600px) {
  .crop_resize_wrapper {
    white-space: normal;
    padding: 24px 24px 40px;
    text-align: center;
  }
  .crop_resize_wrapper .orientation_wrapper {
    white-space: nowrap;
  }
  .crop_resize_wrapper .crop_tools {
    width: auto;
    text-align: left;
  }
  .crop_resize_wrapper .cropper_wrapper {
    margin-bottom: 24px;
  }
  .crop_resize_wrapper .crop_buttons {
    white-space: nowrap;
  }
  .crop_resize_wrapper .crop_buttons button {
    width: 151px;
    margin-right: 16px;
    margin-bottom: 16px;
  }
  .crop_resize_wrapper .crop_buttons button:last-of-type {
    margin-right: 0;
  }
}
@media screen and (max-width: 380px) {
  .crop_resize_wrapper .cropper_wrapper {
    height: calc(100vw - 48px);
  }
  .crop_resize_wrapper .orientation_wrapper .radio_wrapper {
    display: block;
    margin-bottom: 16px;
  }
  .crop_resize_wrapper .orientation_wrapper .radio_wrapper:last-of-type {
    margin-right: 0;
  }
}/*# sourceMappingURL=index.css.map */