.course_button {
  position: relative;
  display: block;
  height: 28px;
  border-radius: 100px;
  border: 1px solid #1F57C3;
  color: #1F57C3;
  cursor: pointer;
  text-decoration: none;
  transition: color ease-out 150ms;
}
.course_button.continue {
  border-color: #6EC495;
  color: #6EC495;
}
.course_button.continue .button_hover {
  background-color: #6EC495;
}
.course_button.purchase_online {
  border-color: #F02D2D;
  color: #F02D2D;
}
.course_button.purchase_online .button_hover {
  background-color: #F02D2D;
}
.course_button.subscribed_online {
  border-color: #9747FF;
  color: #9747FF;
}
.course_button.subscribed_online .button_hover {
  background-color: #9747FF;
}
.course_button.discount_button {
  border-color: #F02D2D;
  color: #F02D2D;
}
.course_button.discount_button.featured_button {
  color: #fff;
}
.course_button.discount_button .button_hover {
  background-color: #F02D2D;
}
.course_button.featured_button {
  color: #fff;
}
.course_button:hover {
  color: #fff;
}
.course_button:hover .button_hover {
  width: calc(100% - 8px);
}
.course_button .button_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.course_button .button_text .old_price {
  display: inline-block;
  margin-left: 8px;
  font-weight: 400;
  text-decoration: line-through;
  color: #96A3AE;
}
.course_button .button_hover {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #1F57C3;
  transition: width ease-out 150ms;
}
.course_button .button_hover svg {
  width: 20px;
  height: 20px;
}