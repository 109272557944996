@import url("https://fonts.googleapis.com/css2?family=Frijole&display=swap");
.greeting_wrapper {
  padding-top: 70px;
}
.greeting_wrapper.loaded .player_wrapper .greeting_screen .greeting_pattern .countdown_wrapper {
  opacity: 1;
}
.greeting_wrapper.loaded .player_wrapper .greeting_screen .greeting_pattern .flake_loader {
  opacity: 0;
  z-index: -1;
}
.greeting_wrapper .player_wrapper {
  position: relative;
  background-color: #000;
  height: calc(100vh - 70px);
}
.greeting_wrapper .player_wrapper.playing {
  height: auto;
}
.greeting_wrapper .player_wrapper .greeting_screen {
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: ease-out 1000ms;
}
.greeting_wrapper .player_wrapper .greeting_screen.hidden {
  opacity: 0;
}
.greeting_wrapper .player_wrapper .greeting_screen.hidden .greeting_gradient .gg_sizer {
  width: 800px;
  height: 800px;
}
.greeting_wrapper .player_wrapper .greeting_screen.removed {
  display: none;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_gradient {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_gradient .gg_sizer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 600px;
  transition: ease-out 500ms;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_gradient .gg_sizer.started {
  width: 400px;
  height: 400px;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_gradient .gg_sizer.started.scaled {
  width: 650px;
  height: 650px;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_gradient .gg_sizer .g_color {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("./images/ice_pattern.png") 50% 50%;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .greeting_play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5019607843);
  cursor: pointer;
  transition: ease-out 500ms;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .greeting_play svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: 5px;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .greeting_play:hover {
  width: 210px;
  height: 210px;
  background-color: rgba(255, 255, 255, 0.3764705882);
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .greeting_play.playing {
  width: 260px;
  height: 260px;
  opacity: 0;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .greeting_buttons_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .greeting_buttons_wrapper a {
  display: inline-block;
  vertical-align: top;
  margin: 0 12px 24px;
  background-color: transparent;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .greeting_buttons_wrapper a button {
  margin: 0;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .greeting_buttons_wrapper button {
  display: inline-block;
  vertical-align: top;
  margin: 0 12px 24px;
  background-color: rgba(255, 255, 255, 0.5019607843);
  border: 2px solid #0064FF;
  border-radius: 30px;
  color: #0064FF;
  font-weight: 600;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .waiting_message_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 24px;
  border-radius: 24px;
  text-align: center;
  width: calc(100% - 32px);
  max-width: 500px;
  background-color: rgba(255, 255, 255, 0.5647058824);
  border: 1px solid rgba(0, 100, 255, 0.5647058824);
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .waiting_message_wrapper p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  text-align: left;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .waiting_message_wrapper p:last-of-type {
  margin-bottom: 0;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .payment_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  border-radius: 24px;
  text-align: center;
  width: calc(100% - 32px);
  max-width: 500px;
  background-color: rgba(255, 255, 255, 0.5647058824);
  border: 1px solid rgba(0, 100, 255, 0.5647058824);
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .payment_wrapper p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
  text-align: left;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .payment_wrapper button {
  display: inline-block;
  vertical-align: top;
  background-color: rgba(255, 255, 255, 0.5019607843);
  border: 2px solid #0064FF;
  border-radius: 30px;
  color: #0064FF;
  font-weight: 600;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .countdown_wrapper {
  font-family: "Frijole", cursive;
  font-size: 72px;
  line-height: 72px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  color: #fff;
  cursor: default;
  transition: opacity linear 300ms;
  opacity: 0;
}
@media screen and (max-width: 600px) {
  .greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .countdown_wrapper {
    font-size: 56px;
    line-height: 56px;
  }
}
@media screen and (max-width: 380px) {
  .greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .countdown_wrapper {
    font-size: 40px;
    line-height: 40px;
  }
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .flake_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90px;
  transition: opacity linear 300ms;
}
.greeting_wrapper .player_wrapper .greeting_screen .greeting_pattern .flake_loader svg {
  width: 100%;
  height: 100%;
  animation: rotating 4s linear infinite;
}
.greeting_wrapper .player_wrapper .player_inner {
  margin: 0 auto;
  position: relative;
  height: 100%;
}
.greeting_wrapper .player_wrapper .player_inner .player_v2_wrapper {
  height: 100%;
}
.greeting_wrapper .player_wrapper .player_inner .player_v2_wrapper .react-player {
  background-color: #ffffff;
}
@media screen and (max-width: 1050px) {
  .greeting_wrapper .player_wrapper .player_inner {
    width: auto;
  }
  .greeting_wrapper .player_wrapper .player_inner iframe {
    width: 100%;
    height: 100%;
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=index.css.map */