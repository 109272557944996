a button, a.button_link {
  text-decoration: none !important;
}

button {
  width: 256px;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding: 19px 0;
  color: #fff;
  background-color: #040E26;
  border: 1px solid #040E26;
  white-space: nowrap;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  cursor: pointer;
}
button.v2 {
  width: auto;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  color: #fff;
  padding: 22px 35px 21px;
  background-color: #2450FF;
  border: 1px solid #2450FF;
  border-radius: 30px;
}
button.v2:disabled {
  opacity: 0.5;
}
button.v2.light {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3137254902);
  color: #fff;
}
button.v2.light_inverted {
  background-color: transparent;
  border-color: rgba(4, 14, 38, 0.3137254902);
  color: #040E26;
}
button.no_style {
  width: unset;
  height: unset;
  padding: unset;
  background-color: unset;
  border: 0 solid;
}
button:disabled {
  opacity: 0.3;
  cursor: default;
}
button.light {
  color: #040E26;
  background-color: #fff;
  border-color: #fff;
}
button.light_border {
  color: #040E26;
  background-color: #fff;
  border-color: #040E26;
  padding: 19px 0;
}
button.small {
  padding: 8px 20px;
  width: auto;
}
button.red {
  background-color: #fff;
  color: #C33333;
  border-color: #C33333;
}
button.preview {
  font-size: 16px;
  line-height: 16px;
  width: unset;
  padding: 7px 20px 9px;
  border: 1px solid rgba(36, 80, 255, 0.3137254902);
  color: #2450FF;
  border-radius: 20px;
  background-color: #fff;
  font-weight: 500;
  transition: border-color ease-out 150ms;
}
button.preview:hover {
  border-color: #2450FF;
}
button.preview.discount {
  border-color: rgba(240, 45, 45, 0.3137254902);
  color: #F02D2D;
}
button.preview.discount:hover {
  border-color: #F02D2D;
}/*# sourceMappingURL=buttons.css.map */