.course_edit_modal .cem_inner {
  overflow: auto;
}
.course_edit_modal.cem_lesson {
  width: 770px;
}
.course_edit_modal.cem_lesson dl {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}
.course_edit_modal.cem_lesson dl.hidden {
  display: none;
}
.course_edit_modal.cem_lesson dl.z_up {
  z-index: 2;
}
.course_edit_modal.cem_lesson dl.z_up_3 {
  z-index: 3;
}
.course_edit_modal.cem_lesson dl.focused {
  z-index: 10;
}
.course_edit_modal.cem_lesson dl dt {
  flex-shrink: 0;
  width: 160px;
  padding-right: 16px;
  text-align: right;
  margin-top: 2px;
}
.course_edit_modal.cem_lesson dl dt.eli_dt_video_label {
  margin-top: 6px;
}
.course_edit_modal.cem_lesson dl dt.duration_label {
  margin-top: -9px;
}
.course_edit_modal.cem_lesson dl dd {
  width: 100%;
}
.course_edit_modal.cem_lesson dl dd .lesson_type_select {
  display: inline-block;
}
.course_edit_modal.cem_lesson dl dd .course_form_hint {
  font-size: 10px;
  line-height: 16px;
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd textarea {
  display: block;
  margin-top: -4px;
  width: calc(100% - 18px);
  height: 120px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.course_edit_modal.cem_lesson dl dd textarea.placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd textarea:-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd textarea::-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd textarea:-ms-input-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd textarea::-webkit-input-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input {
  display: block;
  margin-top: -4px;
  width: calc(100% - 18px);
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.course_edit_modal.cem_lesson dl dd input.placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input:-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input::-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input:-ms-input-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input::-webkit-input-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson .buttons_holder {
  margin-top: 32px;
}/*# sourceMappingURL=index.css.map */