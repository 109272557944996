.default_input_wrapper {
  position: relative;
  padding-top: 18px;
  margin-bottom: 16px;
}
.default_input_wrapper input,
.default_input_wrapper textarea {
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: transparent;
  border: 0 solid;
  border-bottom: 1px solid #C0C2C8;
  display: block;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
}
.default_input_wrapper input:disabled,
.default_input_wrapper textarea:disabled {
  border-bottom-color: transparent;
  color: #434A5C;
  opacity: 1;
}
.default_input_wrapper input {
  padding-left: 0;
  padding-right: 0;
}
.default_input_wrapper textarea {
  border: 1px solid #C0C2C8;
  margin-top: 4px;
  padding-left: 4px;
  padding-right: 4px;
  width: calc(100% - 10px);
}
.default_input_wrapper label {
  color: #C0C2C8;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 10px;
  line-height: 24px;
}
.default_input_wrapper label.up {
  top: 0;
  font-size: 10px;
}
.default_input_wrapper .dropdown {
  display: inline-block;
}
.default_input_wrapper .dropdown.disabled {
  opacity: 1;
}
.default_input_wrapper .dropdown.disabled .dropdown_arrow {
  display: none;
}
@supports not (-ms-ime-align: auto) {
  .default_input_wrapper label {
    color: #C0C2C8;
    top: 18px;
    transition: all 0.2s ease-out;
    font-size: 16px;
  }
  .default_input_wrapper label.up {
    top: 0;
    font-size: 10px;
  }
  .default_input_wrapper input:not(:-moz-placeholder-shown) + label, .default_input_wrapper textarea:not(:-moz-placeholder-shown) + label {
    top: 0;
    font-size: 10px;
  }
  .default_input_wrapper input:focus + label,
  .default_input_wrapper input:disabled + label,
  .default_input_wrapper input:not(:placeholder-shown) + label,
  .default_input_wrapper textarea:focus + label,
  .default_input_wrapper textarea:disabled + label,
  .default_input_wrapper textarea:not(:placeholder-shown) + label {
    top: 0;
    font-size: 10px;
  }
}/*# sourceMappingURL=forms.css.map */