.school_courses_reviews {
  padding-bottom: 80px;
}
.school_courses_reviews .reviews_list {
  margin: 0 36px;
  max-width: 700px;
}
.school_courses_reviews .reviews_list li {
  background-color: #fff;
  padding: 18px;
  border-radius: 4px;
  margin-bottom: 24px;
}
.school_courses_reviews .reviews_list li dl {
  font-size: 14px;
  line-height: 19px;
  display: flex;
  margin-bottom: 16px;
}
.school_courses_reviews .reviews_list li dl:last-of-type {
  margin-bottom: 0;
}
.school_courses_reviews .reviews_list li dl dt {
  width: 100px;
  flex-shrink: 0;
  text-align: right;
  margin-right: 16px;
  font-weight: bold;
}/*# sourceMappingURL=reviews.css.map */