.cl_content {
  position: relative;
  padding: 68px 0 92px;
}
.cl_content .content_header_section {
  display: flex;
  margin-bottom: 50px;
}
.cl_content .content_header_section .chs_side {
  width: 50%;
  flex-shrink: 0;
}
.cl_content .cl_anchor {
  position: absolute;
  top: -70px;
}
.cl_content .certificate {
  position: relative;
  margin-bottom: 30px;
  padding-left: 55px;
  font-size: 15px;
  line-height: 15px;
}
.cl_content .certificate svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 40px;
  height: 40px;
}
.cl_content .chs_duration ul li {
  display: inline-block;
  margin-right: 30px;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 20px;
}
.cl_content .chs_duration ul li span {
  font-weight: 600;
  display: inline-block;
  padding: 8px 15px;
  margin-right: 10px;
  color: #fff;
  border-radius: 20px;
  background-color: #040E26;
}
.cl_content.folded .content_list .lesson_item:nth-child(n+4) {
  display: none;
}
.cl_content .content_list .lesson_item {
  position: relative;
  border: 1px solid rgba(4, 14, 38, 0.062745098);
  border-radius: 5px;
  margin-bottom: 20px;
  transition: linear 150ms;
}
.cl_content .content_list .lesson_item.opened {
  border-color: #fff;
}
.cl_content .content_list .lesson_item.opened .description_wrapper {
  display: block;
}
.cl_content .content_list .lesson_item .header {
  position: relative;
  padding: 34px 100px 34px 90px;
}
.cl_content .content_list .lesson_item .header .type_icon {
  position: absolute;
  top: 29px;
  left: 30px;
  font-size: 0;
}
.cl_content .content_list .lesson_item .header .title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
}
.cl_content .content_list .lesson_item .header .fold_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 35px;
  font-size: 0;
  cursor: pointer;
}
.cl_content .content_list .lesson_item .description_wrapper {
  overflow: hidden;
  display: none;
}
.cl_content .content_list .lesson_item .description_wrapper .description {
  padding: 3px 100px 36px 90px;
  font-size: 17px;
  line-height: 25px;
}
.cl_content .content_list .lesson_item .description_wrapper .description p {
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 25px;
}
.cl_content .content_list .lesson_item .description_wrapper .description p.lesson_duration, .cl_content .content_list .lesson_item .description_wrapper .description p.lesson_lecturer, .cl_content .content_list .lesson_item .description_wrapper .description p.lesson_starts {
  font-size: 15px;
  line-height: 15px;
}
.cl_content .content_list .lesson_item .description_wrapper .description p.lesson_duration {
  position: relative;
  padding-left: 25px;
}
.cl_content .content_list .lesson_item .description_wrapper .description p.lesson_duration svg {
  position: absolute;
  left: 0;
  top: -1px;
}
.cl_content .content_list .lesson_item .description_wrapper .description .btn_holder {
  margin-top: 25px;
}
.cl_content .switcher_centrer {
  text-align: center;
}
.cl_content .switcher_centrer .fold_switcher {
  position: relative;
  display: inline-block;
  margin-top: 55px;
  padding-right: 10px;
  font-size: 15px;
  line-height: 15px;
  font-weight: 500;
  cursor: pointer;
}
.cl_content .switcher_centrer .fold_switcher svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-top: 1px;
}
@media screen and (max-width: 1180px) {
  .cl_content .content_header_section {
    display: block;
  }
  .cl_content .content_header_section .chs_side {
    width: auto;
  }
}
@media screen and (max-width: 700px) {
  .cl_content {
    padding: 41px 0 47px;
  }
  .cl_content .content_header_section {
    margin: 0 20px 30px;
  }
  .cl_content .chs_duration ul li {
    margin-right: 20px;
    margin-bottom: 10px;
  }
  .cl_content .cl_anchor {
    top: -60px;
  }
  .cl_content .cl_centrer {
    margin: 0;
  }
  .cl_content .cl_centrer h2 {
    margin: 0 0 28px;
  }
  .cl_content .cl_centrer .certificate {
    margin: 0 0 30px;
    font-size: 15px;
    line-height: 20px;
  }
  .cl_content .content_list .lesson_item {
    border-radius: 0;
    border-left: 0 solid;
    border-right: 0 solid;
  }
  .cl_content .content_list .lesson_item.opened {
    border-color: rgba(4, 14, 38, 0.062745098);
  }
  .cl_content .content_list .lesson_item .header {
    padding: 27px 70px;
  }
  .cl_content .content_list .lesson_item .header .type_icon {
    left: 20px;
  }
  .cl_content .content_list .lesson_item .header .title {
    font-size: 17px;
    line-height: 25px;
  }
  .cl_content .content_list .lesson_item .header .fold_icon {
    right: 20px;
  }
  .cl_content .content_list .lesson_item .description_wrapper .description {
    padding: 2px 20px 27px;
  }
}