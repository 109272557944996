.course_landing_wrapper .cl_centrer {
  position: relative;
  width: 1170px;
  height: 100%;
  margin: 0 auto;
}
.course_landing_wrapper .cl_centrer h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
  color: #040E26;
  margin-bottom: 72px;
}
@media screen and (max-width: 1180px) {
  .course_landing_wrapper .cl_centrer {
    width: auto;
    margin: 0 50px;
  }
}
@media screen and (max-width: 700px) {
  .course_landing_wrapper .cl_centrer {
    margin: 0 20px;
  }
  .course_landing_wrapper .cl_centrer h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 45px;
  }
}/*# sourceMappingURL=index.css.map */