.feedback_form_modal {
  width: 290px;
}
.feedback_form_modal .ffm_inner {
  position: relative;
}
.feedback_form_modal textarea {
  height: 140px;
}
.feedback_form_modal button {
  width: 290px;
}
.feedback_form_modal .ffm_after_message {
  text-align: center;
}
.feedback_form_modal .ffm_after_message p {
  font-size: 19px;
  line-height: 32px;
  margin-bottom: 32px;
}/*# sourceMappingURL=support.css.map */