.text_modal .tm_text {
  margin-bottom: 24px;
}
.text_modal .tm_buttons {
  text-align: center;
}
.text_modal .tm_buttons button {
  display: inline-block;
  margin: 0 8px;
}
.text_modal .tm_buttons a {
  display: inline-block;
  margin: 0 8px;
}
.text_modal .tm_buttons a button {
  margin: 0;
}/*# sourceMappingURL=text-modal.css.map */