.cl_header .header_basic_params {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 42px;
  display: flex;
  justify-content: flex-start;
}
.cl_header .header_basic_params .bp_item {
  flex-shrink: 0;
  font-size: 15px;
  line-height: 15px;
  margin-right: 40px;
}
.cl_header .header_basic_params .bp_item .cl_icon, .cl_header .header_basic_params .bp_item svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  max-width: 20px;
  max-height: 20px;
}
.cl_header .header_basic_params .bp_item .cl_icon.difficulty_icon circle, .cl_header .header_basic_params .bp_item svg.difficulty_icon circle {
  fill: none;
  stroke: #fff;
  opacity: 0.5;
}
.cl_header .header_basic_params .bp_item .cl_icon.difficulty_icon circle.filled, .cl_header .header_basic_params .bp_item svg.difficulty_icon circle.filled {
  fill: #fff;
  opacity: 1;
}
.cl_header .header_basic_params .bp_item.bp_item_certificate {
  display: none;
}
.cl_header .header_basic_params .bp_item span {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}
@media screen and (max-width: 700px) {
  .cl_header .header_basic_params {
    width: 100%;
    bottom: 37px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .cl_header .header_basic_params .bp_item {
    width: 33%;
    margin-right: 0;
    margin-bottom: 32px;
  }
  .cl_header .header_basic_params .bp_item .cl_icon, .cl_header .header_basic_params .bp_item svg {
    display: block;
    margin-bottom: 8px;
  }
  .cl_header .header_basic_params .bp_item.bp_item_certificate {
    width: 100%;
    margin-bottom: 0;
  }
  .cl_header .header_basic_params .bp_item.bp_item_certificate .cl_icon {
    display: inline-block;
    margin-bottom: 0;
  }
}/*# sourceMappingURL=basic-params.css.map */