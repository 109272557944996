.main_certs_wrapper {
  position: relative;
  padding: 40px 0;
}
.main_certs_wrapper .mc_image_background {
  top: -2000px;
  left: -1000px;
  position: absolute;
  width: 4000px;
  height: 4000px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
@media screen and (max-width: 820px) {
  .main_certs_wrapper .mc_image_background {
    top: -2500px;
    position: absolute;
    left: -30px;
    width: calc(100vw + 70px);
    height: 4000px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
}
.main_certs_wrapper .mc_image_background .mc_image_content {
  position: fixed;
  min-width: 75vw;
  min-height: 75vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 2000;
}
.main_certs_wrapper .mc_feed_outer {
  position: relative;
  max-width: 1295px;
  margin: 0 auto;
}
.main_certs_wrapper .mc_feed_outer .arr {
  position: absolute;
  z-index: 5;
  top: 0;
  height: 240px;
  width: 64px;
  opacity: 0.8;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .main_certs_wrapper .mc_feed_outer .arr {
    display: none;
  }
}
.main_certs_wrapper .mc_feed_outer .arr i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
}
.main_certs_wrapper .mc_feed_outer .arr i svg {
  width: 16px;
  height: 16px;
}
.main_certs_wrapper .mc_feed_outer .arr.hidden {
  display: none;
}
.main_certs_wrapper .mc_feed_outer .arr.arr_prev {
  right: 100%;
}
.main_certs_wrapper .mc_feed_outer .arr.arr_next {
  left: 100%;
}
.main_certs_wrapper .mc_feed_outer .arr.arr_next i svg {
  transform: rotate(180deg);
}
.main_certs_wrapper .mc_feed_wrapper {
  overflow: hidden;
  position: relative;
  height: 352px;
}
@media screen and (max-width: 1500px) {
  .main_certs_wrapper .mc_feed_wrapper {
    max-width: 1160px;
  }
}
.main_certs_wrapper .mc_feed {
  justify-content: space-between;
  align-items: center;
  position: absolute;
  display: flex;
  gap: 55px;
  transition: left ease-out 300ms;
  white-space: nowrap;
  text-align: left;
  top: 0;
  overflow: hidden;
}
@media screen and (max-width: 1500px) {
  .main_certs_wrapper .mc_feed {
    gap: 25px;
  }
}
.main_certs_wrapper .mc_feed .mc_item {
  position: relative;
  cursor: pointer;
  width: 170px !important;
  height: 240px !important;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 480px) {
  .main_certs_wrapper .mc_feed {
    display: block;
    width: 100%;
    overflow-x: auto;
    position: relative;
  }
  .main_certs_wrapper .mc_item {
    display: inline-block;
    margin-left: 6px;
  }
}/*# sourceMappingURL=index.css.map */