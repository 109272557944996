.cl_header .header_buttons {
  flex-shrink: 0;
  position: relative;
  width: 288px;
  margin: 80px 0 40px;
  padding: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: #fff;
  overflow: hidden;
}
.cl_header .header_buttons .favourite_link {
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3137254902);
  margin-bottom: 50px;
  cursor: pointer;
  opacity: 0.7;
}
.cl_header .header_buttons .favourite_link.favourited {
  border-bottom-color: transparent;
  cursor: default;
}
.cl_header .header_buttons .cert_block {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.cl_header .header_buttons .header_top {
  min-height: 30px;
}
.cl_header .header_buttons .share_holder {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;
  right: 40px;
  top: 35px;
  width: 30px;
  height: 30px;
}
.cl_header .header_buttons .favorites_block {
  width: 20px;
  position: absolute;
  right: 70px;
  top: 40px;
  cursor: pointer;
}
.cl_header .header_buttons .price_block {
  margin-bottom: 37px;
}
.cl_header .header_buttons .price_block .discount_text {
  font-size: 15px;
  line-height: 15px;
  opacity: 0.7;
  margin-bottom: 10px;
}
.cl_header .header_buttons .price_block .actual_price {
  font-size: 40px;
  line-height: 40px;
  font-weight: 500;
}
.cl_header .header_buttons .price_block .regular_price_block {
  margin-top: 20px;
}
.cl_header .header_buttons .price_block .regular_price_block p {
  font-size: 13px;
  line-height: 13px;
  opacity: 0.5;
  margin-bottom: 10px;
}
.cl_header .header_buttons .price_block .regular_price_block span {
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  text-decoration: line-through;
  opacity: 0.5;
}
.cl_header .header_buttons button, .cl_header .header_buttons a {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  text-decoration: none;
}
.cl_header .header_buttons .school_text {
  margin-bottom: 35px;
  font-weight: 500;
}
.cl_header .header_buttons .report_link_wrapper {
  text-align: center;
}
.cl_header .header_buttons .report_link_wrapper .report_link {
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3137254902);
  cursor: pointer;
  opacity: 0.7;
}
@media screen and (max-width: 1180px) {
  .cl_header .header_buttons {
    width: auto;
    height: auto;
    padding: 38px 50px 50px;
    margin: 0;
    border-radius: 20px 20px 0 0;
    background-color: #fff;
    color: #040E26;
  }
  .cl_header .header_buttons .favourite_link {
    border-bottom-color: rgba(4, 14, 38, 0.3137254902);
  }
  .cl_header .header_buttons button.light {
    border-color: rgba(4, 14, 38, 0.3137254902);
    color: #040E26;
  }
}
@media screen and (max-width: 700px) {
  .cl_header .header_buttons {
    padding: 32px 20px 32px;
  }
  .cl_header .header_buttons .favourite_link {
    margin-bottom: 35px;
  }
  .cl_header .header_buttons .share_holder {
    top: 20px;
    right: 25px;
  }
  .cl_header .header_buttons .btn_edit {
    display: none;
  }
}