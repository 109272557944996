.cl_roadmap {
  padding: 68px 0 72px;
}
.cl_roadmap .cl_centrer h2 {
  margin-bottom: 117px;
}
.cl_roadmap .roadmap_wrapper {
  border-top: 1px solid #F5F5F5;
}
.cl_roadmap .roadmap_wrapper .cl_centrer {
  margin-top: -25px;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel {
  position: relative;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder {
  overflow: hidden;
  text-align: left;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed {
  display: flex;
  flex-wrap: nowrap;
  transition: margin-left ease-out 300ms;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item {
  width: 240px;
  flex-shrink: 0;
  margin-right: 60px;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item dt {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 22px;
  font-weight: 500;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item dt .idx {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 32px;
  background-color: #040E26;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item dt .idx span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item dd {
  font-size: 17px;
  line-height: 25px;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .arr {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .arr:hover {
  opacity: 1;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .arr:before {
  content: "";
  position: absolute;
  top: -6px;
  width: 30px;
  height: 30px;
  border: 1px solid #040E26;
  transform: rotate(45deg);
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .arr.arr_left {
  right: 100%;
  margin-right: 30px;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .arr.arr_left:before {
  left: 7px;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .arr.arr_right {
  left: 100%;
  margin-left: 30px;
}
.cl_roadmap .roadmap_wrapper .roadmap_carousel .arr.arr_right:before {
  right: 7px;
}
@media screen and (max-width: 700px) {
  .cl_roadmap {
    padding: 41px 0 50px;
  }
  .cl_roadmap .cl_centrer {
    margin: 0;
  }
  .cl_roadmap .cl_centrer h2 {
    margin: 0 20px 65px;
  }
  .cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item {
    width: 220px;
    margin-right: 50px;
  }
  .cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item:first-of-type {
    margin-left: 20px;
  }
  .cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item dt {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 25px;
  }
  .cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item dt .idx {
    margin-bottom: 36px;
  }
  .cl_roadmap .roadmap_wrapper .roadmap_carousel .feed_holder .feed .feed_item dd {
    font-size: 15px;
    line-height: 20px;
  }
  .cl_roadmap .roadmap_wrapper .roadmap_carousel .arr {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */