.radio_wrapper {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 32px;
  white-space: nowrap;
  font-size: 0;
  cursor: pointer;
}
.radio_wrapper .radio_box {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #F7F7F7;
  margin-right: 8px;
  overflow: hidden;
  transition: linear 100ms;
}
.radio_wrapper .radio_label {
  font: normal normal 500 16px/16px Manrope;
  display: inline-block;
  vertical-align: top;
  color: #88888F;
  margin-top: 4px;
  transition: linear 100ms;
}
.radio_wrapper.checked .radio_box {
  background-color: #0064FF;
}
.radio_wrapper.checked .radio_box:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.1254901961);
}
.radio_wrapper.checked .radio_label {
  color: #000;
}
.radio_wrapper:hover .radio_label {
  color: #000;
}/*# sourceMappingURL=index.css.map */