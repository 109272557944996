.course_edit_modal.authors_select_modal .cem_inner {
  overflow: visible;
}
.course_edit_modal.authors_select_modal .cem_inner a {
  color: #2450FF;
  text-decoration: none;
  border-bottom: 1px solid rgba(36, 80, 255, 0.3137254902);
}
.course_edit_modal.authors_select_modal .cem_inner .cem_content {
  height: 200px;
}/*# sourceMappingURL=authors.css.map */