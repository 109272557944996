@import "../styles/mixins.css";
.notifications_pagination_selector {
  position: relative;
  width: 50px;
  height: 28px;
  display: flex;
  align-items: center;
  border: 2px solid rgba(150, 163, 174, 0.1);
  border-radius: 4px;
  margin-left: 16px;
  margin-bottom: 12px;
}
.notifications_pagination_selector_ico {
  position: absolute;
  right: 2px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
  rotate: 90deg;
}
.notifications_pagination_selector select {
  z-index: 5;
  padding-left: 12px;
  border: none;
  outline: none;
  background: none;
  width: 50px;
}

.notif_list {
  padding: 16px;
  display: flex;
  flex-direction: column;
  min-height: 320px;
}
@media screen and (min-width: 481px) {
  .notif_list_row_info_content {
    opacity: 0.5;
  }
}
.notif_list .unread {
  background-color: #fff;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  opacity: 1;
}
.notif_list_row {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 24px 10px;
  margin-bottom: 10px;
  display: flex;
  position: relative;
}
@media screen and (min-width: 1050px) {
  .notif_list_row {
    margin-right: 312px;
  }
}
.notif_list_row_ico {
  position: absolute;
  width: 38px;
  height: 38px;
  top: -14px;
  left: -16px;
  background-repeat: no-repeat;
}
.notif_list_row_logo {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 60px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.notif_list_row_logo.letter {
  border: 1px solid rgba(150, 163, 174, 0.2);
  text-transform: uppercase;
  font-size: 30px;
  line-height: 62px;
}
.notif_list_row_info {
  display: flex;
  flex-direction: column;
}
.notif_list_row_info_date {
  color: #E933C3;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
}/*# sourceMappingURL=notifications.css.map */