.ls_free_services_wrapper {
  padding: 48px 0 30px;
  background: #FAFCFF; /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom, #FAFCFF 0%, #DEE6F5 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir ); /* IE6-9 */
}
.school_services .ls_free_services_wrapper {
  padding-top: 120px;
}
.ls_free_services_wrapper h2 {
  margin-bottom: 70px;
}
.ls_free_services_wrapper ul {
  display: flex;
  flex-wrap: wrap;
}
.ls_free_services_wrapper ul li {
  width: 30%;
  margin-right: 3%;
  flex-shrink: 0;
  font-size: 24px;
  line-height: 35px;
  font-weight: 500;
  margin-bottom: 40px;
}
.ls_free_services_wrapper ul li .icon_plus {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #ff009d; /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom, #ff009d 0%, #ff004c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir ); /* IE6-9 */
  margin-bottom: 34px;
}
.ls_free_services_wrapper ul li .icon_plus span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -4px;
  color: #fff;
  font-size: 42px;
  line-height: 42px;
  font-weight: 400;
}
@media screen and (max-width: 600px) {
  .ls_free_services_wrapper h2 {
    margin-bottom: 30px;
  }
  .ls_free_services_wrapper ul {
    display: block;
  }
  .ls_free_services_wrapper ul li {
    position: relative;
    width: auto;
    display: block;
    margin-right: 0;
    margin-bottom: 38px;
    padding-left: 48px;
    font-size: 18px;
    line-height: 28px;
  }
  .ls_free_services_wrapper ul li .icon_plus {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 0;
    top: 0px;
  }
  .ls_free_services_wrapper ul li .icon_plus span {
    font-size: 32px;
    line-height: 32px;
    margin-top: -3px;
  }
}/*# sourceMappingURL=index.css.map */