#editorjs {
  margin-top: 120px;
  padding: 20px 32px 10px;
}

.cdx-list--unordered li {
  list-style-type: disc !important;
}

.school_services .ls_payments_wrapper {
  padding-bottom: 45px;
}
.school_services .btn_holder {
  text-align: center;
}

.about_wrapper {
  width: 670px;
  padding-top: 128px;
  padding-bottom: 64px;
  margin: 0 auto;
}
.about_wrapper h1 {
  text-align: center;
  margin-bottom: 64px;
}
.about_wrapper h3 {
  margin-bottom: 24px;
}
.about_wrapper p a, .about_wrapper ul a {
  color: #040E26;
  text-decoration: none;
  border-bottom: 1px solid #040E26;
}
.about_wrapper p a.img_link, .about_wrapper ul a.img_link {
  border: 0 solid;
}
.about_wrapper p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.about_wrapper p img {
  display: inline;
}
.about_wrapper p.social_link {
  display: flex;
  align-items: center;
}
.about_wrapper p.social_link a {
  font-weight: 500;
}
.about_wrapper p.social_link a.icon_link {
  border-bottom: 0 solid;
  margin-right: 16px;
  margin-top: 8px;
}
.about_wrapper p + ul {
  margin-top: -16px;
}
.about_wrapper ul {
  font-size: 16px;
  line-height: 24px;
}
.about_wrapper ul li {
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;
}
.about_wrapper ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #040E26;
}
@media screen and (max-width: 600px) {
  .about_wrapper {
    width: auto;
    padding: 128px 24px 64px;
  }
}

.faq_page h2 {
  text-align: center;
  margin-bottom: 24px;
}
.faq_page dl {
  margin-bottom: 64px;
  font-size: 16px;
  line-height: 24px;
}
.faq_page dl dt {
  font-weight: bold;
  margin-bottom: 24px;
}
.faq_page .faq_bottom {
  position: relative;
  text-align: center;
  padding-top: 64px;
}
.faq_page .faq_bottom:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 100%;
  height: 1px;
  background-color: #818692;
}