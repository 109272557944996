.help_wrapper {
  display: flex;
  width: 1140px;
}
.help_wrapper .hw_left, .help_wrapper .hw_right {
  width: 50%;
  flex-shrink: 0;
}
.help_wrapper .hw_left {
  display: flex;
}
.help_wrapper .hw_left .hw_photo {
  position: relative;
  flex-shrink: 0;
  width: 170px;
  height: 170px;
  margin-right: 30px;
  background-image: url("./images/dariia.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .help_wrapper .hw_left .hw_photo {
    background-image: url("./images/dariia@2x.png");
    background-size: 170px 170px;
  }
}
.help_wrapper .hw_left .hw_photo.online:after {
  display: block;
}
.help_wrapper .hw_left .hw_photo:after {
  display: none;
  content: "";
  position: absolute;
  right: 5px;
  bottom: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #6AB261;
  border: 5px solid #fff;
}
.help_wrapper .hw_left .hw_text h1 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 17px;
}
.help_wrapper .hw_left .hw_text p {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 24px;
}
.help_wrapper .hw_left .hw_text dl {
  margin-bottom: 33px;
}
.help_wrapper .hw_left .hw_text dl dt {
  display: block;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 6px;
  opacity: 0.7;
}
.help_wrapper .hw_left .hw_text dl dd {
  display: block;
  font-size: 16px;
  line-height: 16px;
}
.help_wrapper .hw_left .hw_text dl dd a {
  color: #040E26;
}
.help_wrapper .hw_right {
  position: relative;
  background-color: #F5F5F5;
  border-radius: 16px;
  max-width: 600px;
}
.help_wrapper .hw_right .hwr_inner {
  padding: 40px;
  display: flex;
  justify-content: space-between;
}
.help_wrapper .hw_right .hwr_inner .hw_contacts p {
  position: relative;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 30px;
  padding-left: 40px;
}
.help_wrapper .hw_right .hwr_inner .hw_contacts p svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.help_wrapper .hw_right .hwr_inner .hw_contacts p svg.help_mail_icon {
  margin-top: 1px;
}
.help_wrapper .hw_right .hwr_inner .hw_contacts p a {
  text-decoration: none;
  color: #2450FF;
  border-bottom: 0 solid;
}
.help_wrapper .hw_right .hwr_inner .hw_qr {
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  text-align: center;
}
.help_wrapper .hw_right .hwr_inner .hw_qr .qr {
  width: 120px;
  height: 120px;
  border: 20px solid #fff;
  background-image: url("./images/wa-qr.png");
  margin-bottom: 17px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .help_wrapper .hw_right .hwr_inner .hw_qr .qr {
    background-image: url("./images/wa-qr@2x.png");
    background-size: 120px 120px;
  }
}
.help_wrapper .hw_right .hwr_inner .hw_qr p {
  font-size: 13px;
  line-height: 20px;
  opacity: 0.7;
}
@media screen and (max-width: 1345px) {
  .help_wrapper {
    width: auto;
    margin: 0 50px;
    display: block;
  }
  .help_wrapper .hw_left, .help_wrapper .hw_right {
    width: auto;
    margin-bottom: 60px;
  }
  .help_wrapper .hw_left .hw_photo {
    margin-right: 50px;
  }
  .help_wrapper .hw_left .hw_text dl {
    display: inline-block;
    vertical-align: top;
    margin-right: 80px;
  }
  .help_wrapper .hw_left .hw_text dl:last-of-type {
    margin-right: 0;
  }
  .help_wrapper .hw_right {
    margin: 0 auto 60px;
  }
}
@media screen and (max-width: 600px) {
  .help_wrapper {
    margin: 0 20px;
    padding-left: 0;
    padding-right: 0;
  }
  .help_wrapper .hw_left, .help_wrapper .hw_right {
    margin-bottom: 46px;
  }
  .help_wrapper .hw_left {
    flex-direction: row-reverse;
  }
  .help_wrapper .hw_left .hw_photo {
    width: 120px;
    height: 120px;
    background-size: 120px 120px;
    margin-right: 0;
    margin-left: 21px;
  }
  .help_wrapper .hw_left .hw_photo:after {
    width: 15px;
    height: 15px;
    right: 5px;
    bottom: 5px;
  }
  .help_wrapper .hw_left .hw_text h1 {
    font-size: 22px;
    height: 22px;
    margin-bottom: 19px;
  }
  .help_wrapper .hw_left .hw_text p {
    margin-bottom: 33px;
  }
  .help_wrapper .hw_right {
    width: 100vw;
    margin-left: -20px;
    border-radius: 0;
  }
  .help_wrapper .hw_right .hwr_inner .hw_qr {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */