.breadcrumbs {
  position: absolute;
  z-index: 200;
  white-space: nowrap;
  color: #fff;
  left: 30px;
  top: 95px;
  font-size: 13px;
  line-height: 13px;
}
.course_landing_wrapper .breadcrumbs {
  left: auto;
  width: 1170px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 1050px) {
  .course_landing_wrapper .breadcrumbs {
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    top: 85px;
    left: 50px;
  }
}
@media screen and (max-width: 700px) {
  .course_landing_wrapper .breadcrumbs {
    left: 20px;
  }
}
.breadcrumbs li {
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
}
.breadcrumbs li span {
  display: inline-block;
  margin-right: 8px;
}
.breadcrumbs li a, .breadcrumbs li span {
  color: unset;
  opacity: 0.7;
  text-decoration: none;
}
.breadcrumbs li a:hover {
  opacity: 1;
}
.breadcrumbs.lesson {
  color: #040E26;
  position: relative;
  top: 0;
  left: 0;
  margin-bottom: 20px;
}
.breadcrumbs.category {
  color: #040E26;
}
@media screen and (max-width: 600px) {
  .breadcrumbs {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */