.range {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 100%;
  padding: 0 17px;
}
.range_label {
  display: inline-block;
  white-space: nowrap;
  width: 100px;
  padding: 0 27px;
  text-align: left;
}
.range_label:first-child {
  text-align: right;
}
.range_container {
  position: relative;
  display: flex;
  width: 640px;
  max-width: 100%;
  height: 36px;
}
.range_container:before, .range_container:after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 50%;
  width: 2px;
  height: 6px;
  background-color: #C0C2C8;
  transform: translateY(-50%);
}
.range_container:before {
  left: 0;
}
.range_container:after {
  right: 0;
}
.range_line {
  align-self: center;
  height: 2px;
  width: 100%;
  border-radius: 4px;
}
.range_thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  background-color: #040E26;
  border: 1px solid #F2F0F0;
}
.range_clear {
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
  transform: translateY(-50%);
}
.range_clear svg {
  width: 10px;
  height: 10px;
}
@media screen and (max-width: 600px) {
  .range {
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
  }
  .range .range_container {
    width: 100%;
  }
  .range .range_label {
    width: 50%;
    order: 1;
    box-sizing: border-box;
    padding: 0;
    text-align: right;
  }
  .range .range_label:first-child {
    text-align: left;
  }
  .range .range_clear {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */