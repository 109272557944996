.cl_reviews {
  padding: 68px 0 72px;
}
.cl_reviews .reviews_list .review_item {
  position: relative;
  margin-bottom: 30px;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(4, 14, 38, 0.062745098);
  display: flex;
  justify-content: space-between;
}
.cl_reviews .reviews_list .review_item .user {
  position: relative;
  padding-left: 75px;
  margin-right: 45px;
  width: 150px;
  height: auto;
  flex-shrink: 0;
}
.cl_reviews .reviews_list .review_item .user .userpic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #040E26;
}
.cl_reviews .reviews_list .review_item .user .userpic img, .cl_reviews .reviews_list .review_item .user .userpic span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cl_reviews .reviews_list .review_item .user .userpic span {
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  font-weight: 500;
}
.cl_reviews .reviews_list .review_item .user .username {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 4px;
}
.cl_reviews .reviews_list .review_item .user .username a {
  text-decoration: none;
  color: unset;
}
.cl_reviews .reviews_list .review_item .user .review_date {
  font-size: 13px;
  line-height: 13px;
  opacity: 0.7;
}
.cl_reviews .reviews_list .review_item .user .stars {
  display: none;
}
.cl_reviews .reviews_list .review_item .user .stars svg path {
  opacity: 1;
  fill: #E4E4E4;
}
.cl_reviews .reviews_list .review_item .user .stars svg path.filled {
  fill: #FFD500;
}
.cl_reviews .reviews_list .review_item .text {
  width: 100%;
  font-size: 17px;
  line-height: 25px;
  margin-top: -4px;
}
.cl_reviews .reviews_list .review_item .score {
  flex-shrink: 0;
}
.cl_reviews .reviews_list .review_item .score svg path {
  opacity: 1;
  fill: #E4E4E4;
}
.cl_reviews .reviews_list .review_item .score svg path.filled {
  fill: #FFD500;
}
@media screen and (max-width: 1180px) {
  .cl_reviews .reviews_list .review_item {
    padding-top: 131px;
  }
  .cl_reviews .reviews_list .review_item .user {
    position: absolute;
    left: 30px;
    top: 30px;
  }
  .cl_reviews .reviews_list .review_item .score {
    position: absolute;
    right: 30px;
    top: 40px;
  }
}
@media screen and (max-width: 700px) {
  .cl_reviews {
    padding-bottom: 30px;
  }
  .cl_reviews .reviews_list .review_item {
    padding: 118px 20px 37px;
    margin-bottom: 20px;
  }
  .cl_reviews .reviews_list .review_item:last-of-type {
    margin-bottom: 0;
  }
  .cl_reviews .reviews_list .review_item .user {
    left: 20px;
  }
  .cl_reviews .reviews_list .review_item .user .stars {
    display: block;
    margin-top: 8px;
  }
  .cl_reviews .reviews_list .review_item .text {
    font-size: 15px;
    line-height: 20px;
  }
  .cl_reviews .reviews_list .review_item .score {
    display: none;
  }
}/*# sourceMappingURL=index.css.map */