.schools_invitation {
  position: relative;
  height: 380px;
  margin-top: 40px;
  margin-bottom: 40px;
  background: #1F57C3;
  border-radius: 8px;
}
.schools_invitation .si_inner {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  padding: 10px;
}
.schools_invitation .si_pic {
  flex-shrink: 0;
  width: 432px;
  margin-right: 40px;
}
.schools_invitation .si_pic img {
  width: 100%;
}
.schools_invitation .si_text {
  width: 100%;
  color: #fff;
  margin-right: 32px;
}
.schools_invitation .si_text h4 {
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  padding-top: 20px;
  margin-bottom: 20px;
  margin-right: 10px;
}
.schools_invitation .si_text p {
  font-size: 21px;
  line-height: 34px;
  margin-right: 90px;
  margin-bottom: 34px;
}
.schools_invitation .si_text .btn_holder {
  text-align: right;
}
.schools_invitation .si_text .btn_holder .si_btn_invite {
  width: 200px;
  text-align: center;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
  background-color: transparent;
  padding: 8px 0 8px;
  border-radius: 40px;
  border: 1px solid #fff;
}
@media screen and (max-width: 1500px) {
  .schools_invitation .si_text h4 {
    font-size: 29px;
    line-height: 36px;
  }
  .schools_invitation .si_text p {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 1230px) {
  .schools_invitation {
    height: auto;
  }
  .schools_invitation .si_inner {
    display: block;
  }
  .schools_invitation .si_inner .si_pic {
    position: relative;
    width: 100%;
    height: 260px;
    overflow: hidden;
    border-radius: 6px;
  }
  .schools_invitation .si_inner .si_pic img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .schools_invitation .si_text h4 {
    font-size: 29px;
    line-height: 36px;
  }
  .schools_invitation .si_text p {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 780px) {
  .schools_invitation .si_inner .si_pic {
    height: 160px;
  }
  .schools_invitation .si_text h4 {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .schools_invitation .si_text p {
    font-size: 14px;
    line-height: 22px;
  }
  .schools_invitation .si_text .btn_holder {
    text-align: left;
  }
}/*# sourceMappingURL=index.css.map */