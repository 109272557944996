.cl_future_skills {
  padding: 60px 0 40px;
}
.cl_future_skills ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.cl_future_skills ul li {
  width: 28%;
  margin-right: 5%;
  flex-shrink: 0;
  margin-bottom: 60px;
  font-size: 17px;
  line-height: 25px;
  color: #040E26;
}
.cl_future_skills ul li svg {
  display: block;
  margin-bottom: 26px;
}
@media screen and (max-width: 900px) {
  .cl_future_skills ul li {
    width: 47%;
  }
}
@media screen and (max-width: 700px) {
  .cl_future_skills {
    padding: 41px 0 20px;
  }
  .cl_future_skills ul li {
    width: 100%;
    margin-bottom: 47px;
    font-size: 16px;
  }
  .cl_future_skills ul li:last-of-type {
    margin-bottom: 0;
  }
}/*# sourceMappingURL=index.css.map */