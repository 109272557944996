.main_reviews_wrapper {
  position: relative;
  max-width: 1295px;
  padding: 40px 0;
  margin: 0 auto;
}
.main_reviews_wrapper h2 {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
}
.main_reviews_wrapper .review_feed_outer {
  position: relative;
}
.main_reviews_wrapper .review_feed_outer .arr {
  position: absolute;
  z-index: 5;
  top: 0;
  height: 100%;
  width: 64px;
  opacity: 0.8;
  cursor: pointer;
}
.main_reviews_wrapper .review_feed_outer .arr i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
}
.main_reviews_wrapper .review_feed_outer .arr i svg {
  width: 16px;
  height: 16px;
}
.main_reviews_wrapper .review_feed_outer .arr.hidden {
  display: none;
}
.main_reviews_wrapper .review_feed_outer .arr.arr_prev {
  right: 100%;
}
.main_reviews_wrapper .review_feed_outer .arr.arr_next {
  left: 100%;
}
.main_reviews_wrapper .review_feed_outer .arr.arr_next i svg {
  transform: rotate(180deg);
}
.main_reviews_wrapper .review_feed_outer .review_feed_inner {
  position: relative;
  overflow: hidden;
}
.main_reviews_wrapper .reviews_feed {
  position: relative;
  transition: margin-left ease-out 300ms;
}
.main_reviews_wrapper .reviews_feed .feeder {
  display: flex;
  flex-wrap: nowrap;
}
.main_reviews_wrapper .reviews_feed .review_item {
  position: relative;
  flex-shrink: 0;
  transition: opacity ease-out 300ms;
}
.main_reviews_wrapper .reviews_feed .review_item.item_hidden {
  opacity: 0;
}
.main_reviews_wrapper .reviews_feed .review_item .ri_photo {
  position: absolute;
  width: 196px;
  height: 196px;
  left: 10px;
  top: 10px;
  z-index: 2;
  overflow: hidden;
}
.main_reviews_wrapper .reviews_feed .review_item .ri_photo img {
  width: 100%;
}
.main_reviews_wrapper .reviews_feed .review_item .ri_author_abs {
  display: none;
}
.main_reviews_wrapper .reviews_feed .review_item .ri_icon {
  position: absolute;
  left: 240px;
  top: 22px;
  z-index: 5;
}
.main_reviews_wrapper .reviews_feed .review_item .ri_text {
  min-height: 216px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  padding: 54px 16px 30px 240px;
  background: rgba(31, 87, 195, 0.04);
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
}
.main_reviews_wrapper .reviews_feed .review_item .ri_text .ri_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.main_reviews_wrapper .reviews_feed .review_item .ri_text .ri_footer .ri_link {
  cursor: pointer;
  width: 95px;
  height: 30px;
  margin-right: 40px;
  border: 1px solid #1F57C3;
  border-radius: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #1F57C3;
  text-align: center;
}
.main_reviews_wrapper .reviews_feed .review_item .ri_text .ri_footer .ri_author {
  color: #1F57C3;
}
@media screen and (max-width: 780px) {
  .main_reviews_wrapper .reviews_feed .review_item .ri_photo {
    top: 0;
    left: 0;
    width: 70px;
    height: 70px;
  }
  .main_reviews_wrapper .reviews_feed .review_item .ri_author_abs {
    display: block;
    position: absolute;
    left: 80px;
    top: 38px;
    font-size: 14px;
    line-height: 18px;
    width: 30px;
    color: #1F57C3;
  }
  .main_reviews_wrapper .reviews_feed .review_item .ri_icon {
    top: 80px;
    left: 30px;
  }
  .main_reviews_wrapper .reviews_feed .review_item .ri_text {
    margin-left: 10px;
    margin-top: 10px;
    padding: 104px 20px 20px;
    display: inline-block;
    width: calc(100% - 50px);
    font-size: 12px;
    line-height: 15px;
  }
  .main_reviews_wrapper .reviews_feed .review_item .ri_text .ri_author {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .main_reviews_wrapper .arr {
    display: none;
  }
}