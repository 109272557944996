.main_statistics {
  margin-bottom: 80px;
  padding-top: 10px;
}
.main_statistics h4 {
  margin-bottom: 32px;
  font-size: 40px;
  line-height: 40px;
  color: #1F57C3;
}
.main_statistics .stat_items {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
.main_statistics .stat_items .si.si_counter {
  margin-right: 80px;
  color: #1F57C3;
}
.main_statistics .stat_items .si.si_counter .si_num {
  font-size: 80px;
  line-height: 80px;
}
.main_statistics .stat_items .si.si_counter .si_label {
  font-size: 24px;
  line-height: 34px;
}
.main_statistics .stat_items .si.si_link {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 30px;
  overflow: hidden;
}
.main_statistics .stat_items .si.si_link.with_border {
  width: 98px;
  height: 98px;
  border: 1px solid #1F57C3;
}
.main_statistics .stat_items .si.si_link img {
  width: 100%;
}
@media screen and (max-width: 1230px) {
  .main_statistics .stat_items {
    flex-wrap: wrap;
  }
  .main_statistics .stat_items .si {
    margin-bottom: 30px;
  }
  .main_statistics .stat_items .si.si_counter {
    width: 100%;
  }
}/*# sourceMappingURL=index.css.map */