.password_reset_wrapper {
  position: relative;
  width: 336px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 120px;
  text-align: center;
}
.password_reset_wrapper h4 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
}
.password_reset_wrapper .password_reset_form .text_input {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
}
.password_reset_wrapper .password_reset_form .checkbox {
  margin-bottom: 45px;
}
.password_reset_wrapper .password_reset_form .after_message span {
  font-size: 14px;
  line-height: 19px;
}
.password_reset_wrapper .password_reset_form .after_message span a {
  display: inline-block;
  font-size: 17px;
  padding-top: 19px;
  text-decoration: none;
  color: #434A5C;
  border-bottom: 1px solid rgba(67, 74, 92, 0.3137254902);
}
@media screen and (max-width: 600px) {
  .password_reset_wrapper {
    padding-top: 112px;
    width: auto;
    margin: 0 24px;
  }
}/*# sourceMappingURL=index.css.map */