.public_profile_wrapper {
  margin-bottom: 80px;
}
.public_profile_wrapper .public_profile .profile_button {
  width: 160px;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 6px;
  color: #FFF;
  border-radius: 16px;
  cursor: pointer;
}
.public_profile_wrapper .public_profile .profile_button:hover {
  opacity: 0.9;
}
.public_profile_wrapper .public_profile .profile_button.subcribe {
  background-color: #1f57c3;
}
.public_profile_wrapper .public_profile .profile_button.unsubcribe {
  background-color: gray;
}
.public_profile_wrapper .public_profile .profile_bg {
  height: 320px;
  background-color: #f3f4f8;
}
.public_profile_wrapper .public_profile .profile_userpic {
  position: relative;
  z-index: 5;
  margin: -96px auto 0;
  width: 186px;
  height: 186px;
  border-radius: 50%;
  border: 3px solid #434A5C;
  background-color: #fff;
  overflow: hidden;
}
.public_profile_wrapper .public_profile .profile_userpic span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  text-transform: uppercase;
  color: #040E26;
  font-size: 96px;
  line-height: 96px;
}
.public_profile_wrapper .public_profile .profile_userpic img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.public_profile_wrapper .public_profile .profile_name {
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  color: #040E26;
  padding-top: 24px;
  margin-bottom: 24px;
}
.public_profile_wrapper .public_profile .profile_role {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #040E26;
  margin-bottom: 24px;
}
.public_profile_wrapper .public_profile .profile_about_wrapper {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 976px;
  margin: 0 auto;
}
.public_profile_wrapper .public_profile .profile_about_wrapper .profile_about {
  width: 100%;
  margin: 16px 0;
  padding: 32px;
  background-color: #F2F0F0;
  border-radius: 16px;
}
.public_profile_wrapper .public_profile .profile_about_wrapper .public_contacts {
  margin: 16px 0 16px 32px;
  width: 220px;
  flex-shrink: 0;
}
@media screen and (max-width: 600px) {
  .public_profile_wrapper .public_profile .profile_about_wrapper {
    display: block;
    margin: 0 20px;
    width: auto;
  }
  .public_profile_wrapper .public_profile .profile_about_wrapper .profile_about {
    width: auto;
  }
}
.public_profile_wrapper .public_profile .profile_courses_list .profile_courses_feed {
  margin-bottom: 64px;
}
.public_profile_wrapper .public_profile .profile_courses_list .profile_courses_feed:last-of-type {
  margin-bottom: 0;
}
.public_profile_wrapper .public_profile .profile_courses_list .profile_courses_feed h2 {
  margin-bottom: 32px;
}