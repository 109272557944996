.error_404_wrapper {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 100px;
}
.error_404_wrapper img {
  display: inline-block;
  margin-bottom: 24px;
}
.error_404_wrapper h1 {
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 48px;
}
.error_404_wrapper h1 span {
  display: inline-block;
  position: relative;
}
.error_404_wrapper h1 span:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin-top: 24px;
  width: 192px;
  height: 1px;
  background-color: #C0C2C8;
}
.error_404_wrapper h1 span ins {
  position: absolute;
  left: 100%;
  top: -12px;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  color: #C0C2C8;
  margin-left: 10px;
}
.error_404_wrapper p {
  font-size: 16px;
  line-height: 24px;
}
.error_404_wrapper p a {
  color: #434A5C;
}/*# sourceMappingURL=index.css.map */