.course_edit_modal {
  min-width: 600px;
}
.course_edit_modal.cem_course_for {
  min-width: 400px;
}
.course_edit_modal.cem_course_for .cem_content.cem_text textarea {
  height: 60px;
}
.course_edit_modal.cem_future_skills .cem_content {
  min-height: 170px;
}
.course_edit_modal.cem_future_skills .cem_content textarea {
  height: 120px;
}
.course_edit_modal.cem_faq .cem_content textarea {
  height: 120px;
}
.course_edit_modal .cem_inner {
  overflow: auto;
}
.course_edit_modal .cem_text {
  margin-bottom: 24px;
}
.course_edit_modal .cem_text h3 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
}
.course_edit_modal .cem_text .block_description {
  font-size: 13px;
  line-height: 19px;
}
.course_edit_modal .cem_content {
  margin-bottom: 24px;
}
.course_edit_modal .cem_content h5 {
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #C0C2C8;
}
.course_edit_modal .cem_content .cem_hint {
  font-size: 10px;
  line-height: 16px;
  color: #C0C2C8;
}
.course_edit_modal .cem_content .cem_hint a {
  text-decoration: none;
  color: #C0C2C8;
  border-bottom: 1px solid #C0C2C8;
}
.course_edit_modal .cem_content.cem_text textarea {
  display: block;
  width: calc(100% - 18px);
  height: 120px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.course_edit_modal .cem_content.cem_text textarea.placeholder {
  color: #C0C2C8;
}
.course_edit_modal .cem_content.cem_text textarea:-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal .cem_content.cem_text textarea::-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal .cem_content.cem_text textarea:-ms-input-placeholder {
  color: #C0C2C8;
}
.course_edit_modal .cem_content.cem_text textarea::-webkit-input-placeholder {
  color: #C0C2C8;
}
.course_edit_modal .cem_content.cem_list .list_header {
  width: calc(100% - 64px);
  margin-bottom: 32px;
  text-align: center;
}
.course_edit_modal .cem_content.cem_list .list_header .dropdown {
  display: inline-block;
  margin: 0 10px 0 8px;
  border-bottom: 1px solid #C0C2C8;
}
.course_edit_modal .cem_buttons {
  text-align: center;
}
.course_edit_modal .cem_buttons button {
  display: inline-block;
  margin: 0 8px;
}
.course_edit_modal .cem_buttons a {
  display: inline-block;
  margin: 0 8px;
}
.course_edit_modal .cem_buttons a button {
  margin: 0;
}
.course_edit_modal.cem_lesson {
  width: 720px;
}
.course_edit_modal.cem_lesson dl {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
}
.course_edit_modal.cem_lesson dl dt {
  flex-shrink: 0;
  width: 104px;
  padding-right: 16px;
  text-align: right;
  margin-top: 2px;
}
.course_edit_modal.cem_lesson dl dd {
  width: 100%;
}
.course_edit_modal.cem_lesson dl dd textarea {
  display: block;
  margin-top: -4px;
  width: calc(100% - 18px);
  height: 120px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  border-bottom-width: 2px;
  color: #434A5C;
}
.course_edit_modal.cem_lesson dl dd textarea.placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd textarea:-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd textarea::-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd textarea:-ms-input-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd textarea::-webkit-input-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input {
  display: block;
  margin-top: -4px;
  width: calc(100% - 18px);
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.course_edit_modal.cem_lesson dl dd input.placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input:-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input::-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input:-ms-input-placeholder {
  color: #C0C2C8;
}
.course_edit_modal.cem_lesson dl dd input::-webkit-input-placeholder {
  color: #C0C2C8;
}/*# sourceMappingURL=modals.css.map */