.default_form .sz_status_check {
  color: #C33333;
}
.default_form .sz_status_check.checked {
  color: #46C790;
}
.default_form .sz_status_check .checked_note {
  padding-top: 13px;
  font-size: 13px;
  line-height: 19px;
  color: #818692;
}
.default_form .form_block {
  position: relative;
  width: 864px;
  margin: 0 auto;
  margin-bottom: 58px;
}
.default_form .form_block .form_block_label {
  color: #040e26;
  font-weight: 600;
  margin-bottom: 16px;
}
.default_form .input_block {
  font-size: 0;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-top: 18px;
  margin-bottom: 54px;
}
.default_form .input_block.ib_864 {
  width: 864px;
  margin-right: 0;
}
.default_form .input_block.ib_832 {
  width: 832px;
  margin-right: 0;
}
.default_form .input_block.ib_528 {
  width: 528px;
  margin-right: 32px;
}
.default_form .input_block.ib_256 {
  width: 256px;
  margin-right: 32px;
}
.default_form .input_block.ib_192 {
  width: 192px;
  margin-left: 32px;
  margin-right: 32px;
}
.default_form .input_block.ib_304 {
  width: 304px;
}
.default_form .input_block input {
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: transparent;
  border: 0 solid;
  border-bottom: 1px solid #C0C2C8;
  display: block;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 8px;
}
.default_form .input_block input:disabled {
  border-bottom-color: transparent;
  color: #434A5C;
  opacity: 1;
}
.default_form .input_block label {
  color: #C0C2C8;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 10px;
  line-height: 24px;
}
.default_form .input_block label.up {
  top: 0;
  font-size: 10px;
}
.default_form .input_block .form_error {
  display: block;
  font-size: 12px;
  line-height: 12px;
  color: #C33333;
  padding-top: 12px;
}
.default_form .input_block .dropdown {
  display: inline-block;
}
.default_form .input_block .dropdown.disabled {
  opacity: 1;
}
.default_form .input_block .dropdown.disabled .dropdown_arrow {
  display: none;
}
@supports not (-ms-ime-align: auto) {
  .default_form .input_block label {
    color: #C0C2C8;
    top: 18px;
    transition: all 0.2s ease-out;
    font-size: 16px;
  }
  .default_form .input_block label.up {
    top: 0;
    font-size: 10px;
  }
  .default_form .input_block input:not(:-moz-placeholder-shown) + label {
    top: 0;
    font-size: 10px;
  }
  .default_form .input_block input:focus + label,
  .default_form .input_block input:disabled + label,
  .default_form .input_block input:not(:placeholder-shown) + label {
    top: 0;
    font-size: 10px;
  }
}
.default_form .button_holder {
  text-align: center;
  padding-bottom: 64px;
}/*# sourceMappingURL=form.css.map */