.sign_in_form {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
}
.sign_in_form .text_input {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
}
.sign_in_form .password_recovery_link {
  padding-top: 24px;
}
.sign_in_form .password_recovery_link a {
  text-decoration: none;
  color: #434A5C;
  border-bottom: 1px solid rgba(60, 30, 30, 0.5);
}/*# sourceMappingURL=sign-in-form.css.map */