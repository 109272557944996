.demo_note {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 450px;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(38, 4, 4, 0.25);
  border-radius: 8px;
  text-align: center;
  margin-top: -50px;
  z-index: 10000;
}
.demo_note p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
@media screen and (max-width: 600px) {
  .demo_note {
    width: calc(100% - 48px);
    border-radius: 0;
  }
}/*# sourceMappingURL=index.css.map */