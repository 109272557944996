.header_v2 {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}
body.no_header .header_v2 {
  display: none !important;
}
.header_v2 .centrer {
  position: relative;
  z-index: 100;
  background-color: #fff;
  height: 69px;
  border-bottom: 1px solid #E8E8E8;
}
.header_v2 .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 39px;
}
.header_v2 .logo img {
  height: 100%;
}
.header_v2 .logo .beta {
  font-size: 13px;
  line-height: 13px;
  position: absolute;
  left: 100%;
  margin-left: 5px;
  top: -5px;
  color: #46C790;
  font-weight: bold;
}
.header_v2 .sidebar_switcher {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: none;
}
.header_v2 .sidebar_switcher .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 320px;
}
.header_v2 .sidebar_switcher.opened svg .a {
  fill: #e8e8e8;
}
.header_v2 .search_toggler {
  display: none;
}
@media screen and (max-width: 820px) and (min-width: 481px) {
  .header_v2 .logo {
    left: 50% !important;
  }
}
@media screen and (max-width: 480px) {
  .header_v2 .centrer {
    height: 59px;
  }
  .header_v2 .logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 60px;
  }
  .header_v2 .sidebar_switcher {
    display: block;
  }
  .header_v2 .search_toggler {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 70px;
    display: block;
  }
}