.course_preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 298px;
  height: 336px;
  margin: 0 34px 32px 0;
  white-space: normal;
  color: #040E26;
  text-align: left;
}
.course_preview .course_preview_link {
  text-decoration: none;
  color: unset;
}
.course_preview .additional_info_bar {
  height: 19px;
}
.course_preview .additional_info_bar_date {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.7);
}
.course_preview .additional_info_bar_date_icon {
  width: 8px;
  height: 10px;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (hover: hover) {
  .course_preview:hover .cp_fav_switcher {
    opacity: 1;
  }
  .course_preview:hover .img_wrapper .cp_params,
  .course_preview:hover .img_wrapper .cp_fade {
    opacity: 1;
  }
  .course_preview:hover .cp_school_links {
    display: block;
  }
}
.course_preview .cp_school_links {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px 0 0;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  display: none;
}
.course_preview .cp_school_links button {
  width: 200px;
  margin-bottom: 16px;
}
.course_preview .img_wrapper {
  position: relative;
  height: 160px;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 14px;
}
.course_preview .img_wrapper img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.course_preview .img_wrapper img.vertical, .course_preview .img_wrapper img.book_cover {
  height: auto;
  width: 100%;
  position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: translate(0, 0);
  position: absolute;
  top: 0;
}
.course_preview .img_wrapper .img_plug {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 0;
}
.course_preview .img_wrapper .cp_pict {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}
.course_preview .img_wrapper .cp_pict svg {
  width: 22px;
  height: 22px;
}
.course_preview .img_wrapper .cp_pict_text {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0.55px;
  padding: 4px 10px;
  border-radius: 10px;
  text-transform: uppercase;
  color: #fff;
}
.course_preview .img_wrapper .cp_pict_text.cp_pict_audio {
  background-color: #7f35be;
}
.course_preview .img_wrapper .cp_pict_text.cp_pict_video {
  background-color: #2450ff;
}
.course_preview .img_wrapper .cp_params {
  position: absolute;
  z-index: 3333;
  left: 0;
  bottom: 0;
  padding: 0 10px 10px;
  width: calc(100% - 20px);
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #fff;
  transition: opacity linear 200ms;
  opacity: 0;
}
.course_preview .img_wrapper .cp_params svg path {
  fill: #fff;
}
.course_preview .img_wrapper .cp_params .icon_course_level {
  margin-bottom: -4px;
}
.course_preview .img_wrapper .cp_fade {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: rgba(0, 0, 0, 0); /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$from, endColorstr=$to,GradientType=$ie-dir ); /* IE6-9 */
  transition: opacity linear 200ms;
  opacity: 0;
}
.course_preview h4 {
  font-size: 10px;
  line-height: 13px;
  opacity: 0.7;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
}
.course_preview h4 a {
  color: unset;
  text-decoration: none;
}
@media (hover: hover) {
  .course_preview h4 a:hover {
    text-decoration: underline;
  }
}
.course_preview h4 span {
  display: inline-block;
  margin: 0 6px;
}
.course_preview h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  height: 36px;
  overflow: hidden;
  margin-bottom: 10px;
}
.course_preview .teaser {
  font-size: 10px;
  line-height: 13px;
  opacity: 0.7;
  height: 26px;
  overflow: hidden;
  margin-bottom: 20px;
}
.course_preview .button_holder {
  display: flex;
}
.course_preview .button_holder .course_button {
  width: 100%;
}
.course_preview .button_holder .cp_links_dropdown {
  flex-shrink: 0;
  margin-left: 10px;
  padding: 0;
}
.course_preview .button_holder .cp_links_dropdown .dropdown_opener {
  width: unset;
}
.course_preview .button_holder .cp_links_dropdown .ld_button {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.course_preview .button_holder .cp_links_dropdown .ld_button ins {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.course_preview .button_holder .cp_links_dropdown .ld_button ins i {
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin-bottom: 5px;
  background-color: #040E26;
}
.course_preview .button_holder .cp_links_dropdown .ld_button ins i:last-of-type {
  margin-bottom: 0;
}
.profile_dashboard .course_preview {
  width: 298px;
  margin-right: 0px;
}
@media screen and (max-width: 820px) and (min-width: 481px) {
  .profile_dashboard .course_preview {
    width: 245px;
    margin-right: 0px;
  }
}
@media screen and (max-width: 1500px) {
  .course_preview {
    width: 275px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 780px) {
  .course_preview {
    width: 240px;
    margin-right: 20px;
  }
}

.cp_progress_wrapper {
  border-radius: 4px;
  position: absolute;
  bottom: 10px;
  z-index: 112;
  margin: auto;
  left: 2%;
  height: 3px;
  width: 96%;
  background-color: rgb(255, 255, 255);
}
.cp_progress_wrapper .cp_progress_bar {
  border-radius: 4px;
  height: 100%;
  background-color: rgb(110, 196, 149);
}

.cp_fav_switcher {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 19px;
  right: 30px;
  z-index: 101;
  opacity: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

.cp_online_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(31, 87, 195, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  color: white;
}

.cp_create_new_course {
  display: inline-block;
  vertical-align: top;
  height: 270px;
  width: 240px;
  margin-right: 20px;
  overflow: hidden;
}
.cp_create_new_course.cp_new_article {
  width: 252px;
}
@media screen and (max-width: 780px) {
  .cp_create_new_course {
    display: none;
  }
}
.cp_create_new_course .course_preview_link {
  height: 100%;
  display: block;
  text-align: center;
  padding-top: 64px;
}
.cp_create_new_course .course_preview_link .cnc_plus {
  position: relative;
  width: 64px;
  height: 64px;
  margin: 0 auto 32px;
  border-radius: 50%;
  background-color: #2450FF;
}
.cp_create_new_course .course_preview_link .cnc_plus svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
}
.cp_create_new_course .course_preview_link .cnc_text {
  display: inline-block;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  border-bottom: 1px solid rgba(4, 14, 38, 0.3137254902);
}