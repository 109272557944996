.categories_buttons {
  position: relative;
  max-width: 1295px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.categories_buttons a {
  margin: 0 10px 10px 0;
}
.categories_buttons .button_category {
  padding: 18.5px 40px 16.5px;
  background-color: #1F57C3;
  border-radius: 100px;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
}
.categories_buttons .button_category img {
  display: none;
}
@media screen and (max-width: 780px) {
  .categories_buttons .button_category {
    width: 280px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
}/*# sourceMappingURL=index.css.map */