.header_background_wrapper {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.header_background_wrapper .hb_stroke {
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 440px;
  background-color: #0064FF;
}
.header_background_wrapper .flake {
  z-index: 1;
  position: absolute;
}
.header_background_wrapper .flake.gradient_flake {
  width: 157px;
  height: 96px;
  top: 0;
  left: 3%;
  background-image: url("./images/gradient_flake.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .header_background_wrapper .flake.gradient_flake {
    background-image: url("./images/gradient_flake@2x.png");
    background-size: 157px 96px;
  }
}
.header_background_wrapper .flake.flake_3 {
  top: 40px;
  left: 22%;
}
.header_background_wrapper .flake.flake_4 {
  top: 0;
  left: 46%;
}
.header_background_wrapper .flake.flake_5 {
  top: 0;
  left: 60%;
}
.header_background_wrapper .flake.flake_6 {
  top: 24px;
  right: 48px;
}
.header_background_wrapper .flake.flake_7 {
  top: 100%;
  left: 0;
}
.header_background_wrapper .flake.flake_8 {
  top: 100%;
  left: 40%;
}
.header_background_wrapper .flake.flake_9 {
  top: 100%;
  left: 70%;
}
@media screen and (max-width: 1050px) {
  .header_background_wrapper .flake.flake_3 {
    left: 40%;
  }
  .header_background_wrapper .flake.flake_4 {
    left: 65%;
  }
  .header_background_wrapper .flake.flake_5, .header_background_wrapper .flake.flake_6 {
    display: none;
  }
  .header_background_wrapper .flake.flake_8 {
    left: 50%;
  }
  .header_background_wrapper .flake.flake_9 {
    left: 80%;
  }
}
@media screen and (max-width: 700px) {
  .header_background_wrapper .hb_stroke {
    height: calc(100% - 72px);
  }
  .header_background_wrapper .flake.gradient_flake, .header_background_wrapper .flake.flake_3, .header_background_wrapper .flake.flake_4, .header_background_wrapper .flake.flake_7, .header_background_wrapper .flake.flake_9 {
    display: none;
  }
  .header_background_wrapper .flake.flake_5 {
    display: block;
    width: 91px;
    height: auto;
    left: 8px;
  }
  .header_background_wrapper .flake.flake_6 {
    display: block;
    width: 60px;
    height: auto;
    top: 8px;
    right: 8px;
  }
  .header_background_wrapper .flake.flake_8 {
    width: 85px;
    height: auto;
    left: auto;
    right: 64px;
    margin-top: -1px;
  }
}/*# sourceMappingURL=background.css.map */