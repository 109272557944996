.header_categories_wrapper {
  position: relative;
  width: 227px;
  height: 69px;
  border-right: 1px solid #E8E8E8;
}
.header_categories_wrapper.opened {
  background-color: #E8E8E8;
}
.header_categories_wrapper.opened .categories_list_wrapper {
  display: flex;
}
.header_categories_wrapper .hc_button {
  position: relative;
  width: 174px;
  height: 69px;
  cursor: pointer;
}
.header_categories_wrapper .hc_button svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
}
.header_categories_wrapper .hc_button span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
}
.header_categories_wrapper .categories_list_wrapper {
  display: none;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 1px;
  box-shadow: 5px 6px 10px rgba(0, 0, 0, 0.1607843137);
  flex-wrap: nowrap;
}
.header_categories_wrapper ul {
  width: 240px;
  height: calc(100vh - 70px);
  overflow: auto;
  flex-shrink: 0;
}
.header_categories_wrapper ul.child_list {
  border-left: 1px solid #F5F5F5;
}
.header_categories_wrapper ul li {
  font-size: 15px;
  line-height: 15px;
  display: flex;
  flex-wrap: nowrap;
}
.header_categories_wrapper ul li:hover a, .header_categories_wrapper ul li.hovered a {
  background-color: #E8E8E8;
}
.header_categories_wrapper ul li a {
  position: relative;
  color: unset;
  text-decoration: none;
  display: block;
  width: 150px;
  white-space: nowrap;
  padding: 15px 60px 15px 30px;
  flex-shrink: 0;
  flex-grow: 0;
}
.header_categories_wrapper ul li a svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  z-index: 0;
}
.header_categories_wrapper ul li a svg .a {
  fill: none;
  stroke: #040e26;
  opacity: 0.7;
}
@media screen and (max-width: 1050px) {
  .header_categories_wrapper {
    border-right: 0 solid;
    height: 59px;
    width: 60px;
  }
  .header_categories_wrapper .hc_button {
    width: 60px;
    height: 59px;
  }
  .header_categories_wrapper .hc_button svg {
    left: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .header_categories_wrapper .hc_button span {
    display: none;
  }
  .header_categories_wrapper ul {
    max-height: calc(100vh - 60px);
  }
}/*# sourceMappingURL=index.css.map */