.main_partners {
  position: relative;
  padding-bottom: 100px;
}
.main_partners h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 22px;
  color: #000000;
}
.main_partners .partners_list {
  display: flex;
  flex-wrap: wrap;
}
.main_partners .partners_list .pl_item {
  position: relative;
  margin-right: 10px;
  margin-bottom: 20px;
  min-width: 250px;
  height: 80px;
}
.main_partners .partners_list .pl_item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
}
@media screen and (max-width: 780px) {
  .main_partners {
    padding-bottom: 30px;
  }
  .main_partners .partners_list {
    display: block;
    text-align: center;
  }
  .main_partners .partners_list .pl_item {
    width: auto;
    margin: 0 auto 20px;
  }
}/*# sourceMappingURL=index.css.map */