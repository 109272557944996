.blog_feed_holder {
  position: relative;
  background-color: #F5F5F5;
  padding: 40px 0 30px;
}

.blog_feed_outer {
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
}
.blog_feed_outer h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: #000;
  margin-bottom: 9px;
}
.blog_feed_outer .blog_feed {
  position: relative;
}
.blog_feed_outer .blog_feed .arr {
  position: absolute;
  z-index: 5;
  top: 0;
  height: 100%;
  width: 64px;
  opacity: 0.8;
  cursor: pointer;
}
.blog_feed_outer .blog_feed .arr i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
}
.blog_feed_outer .blog_feed .arr i svg {
  width: 16px;
  height: 16px;
}
.blog_feed_outer .blog_feed .arr.hidden {
  display: none;
}
.blog_feed_outer .blog_feed .arr.arr_prev {
  right: 100%;
}
.blog_feed_outer .blog_feed .arr.arr_next {
  left: 100%;
}
.blog_feed_outer .blog_feed .arr.arr_next i svg {
  transform: rotate(180deg);
}
.blog_feed_outer .feed_link_more {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 30px;
}
.blog_feed_outer .feed_link_more a {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  color: #1F57C3;
  text-decoration: none;
  border-bottom: 1px solid #1F57C3;
}
.blog_feed_outer .feed_link_more a svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 10px;
}
@media screen and (max-width: 1500px) {
  .blog_feed_outer {
    max-width: 1160px;
  }
}
@media screen and (max-width: 1230px) {
  .blog_feed_outer {
    max-width: 1280px;
    width: auto;
  }
  .blog_feed_outer .arr {
    display: none;
  }
  .blog_feed_outer h2, .blog_feed_outer .feed_link_more {
    margin-left: 30px;
  }
  .blog_feed_outer .blog_feed {
    margin-left: 30px;
  }
}
@media screen and (max-width: 780px) {
  .blog_feed_outer h2, .blog_feed_outer .feed_link_more {
    margin-left: 20px;
  }
  .blog_feed_outer .blog_feed {
    margin-left: 20px;
  }
  .blog_feed_outer .feed_link_more span {
    display: block;
  }
  .blog_feed_outer .feed_link_more a {
    margin-left: 0;
  }
}

.blog_feed_wrapper {
  overflow: hidden;
  position: relative;
  height: 487px;
  padding: 4px 0 8px;
}
@media screen and (max-width: 780px) {
  .blog_feed_wrapper {
    height: 422px;
  }
}

.blog_feed_block {
  position: absolute;
  top: 0;
  text-align: left;
  white-space: nowrap;
  z-index: 1;
  transition: left ease-out 300ms;
}/*# sourceMappingURL=index.css.map */