.main_feed_wrapper {
  position: relative;
  padding: 40px 0;
}
.main_feed_wrapper.with_background:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 260px;
  background-color: rgba(36, 80, 255, 0.0392156863);
}
.main_feed_wrapper.feed_popular_categories {
  padding-bottom: 37px;
}
.main_feed_wrapper.feed_popular_categories h2 {
  margin-bottom: 29px;
}
.main_feed_wrapper h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  max-width: 1295px;
  margin: 0 auto 10px;
}
.main_feed_wrapper .welcome_schools {
  width: 304px;
  height: 330px;
  position: relative;
  border: 1px dashed #E6E7EA;
  box-sizing: border-box;
  border-radius: 16px;
  text-align: center;
  text-decoration: none;
  color: #040E26;
}
.main_feed_wrapper .welcome_schools .ws_inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.main_feed_wrapper .welcome_schools .ws_inner img {
  margin-bottom: 12px;
  display: inline-block;
}
.main_feed_wrapper .welcome_schools .ws_inner p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}
.main_feed_wrapper .feed_link_more {
  max-width: 1295px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 40px;
}
.main_feed_wrapper .feed_link_more a {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  color: #1F57C3;
  text-decoration: none;
  border-bottom: 1px solid #1F57C3;
}
.main_feed_wrapper .feed_link_more a svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 10px;
}
@media screen and (max-width: 1500px) {
  .main_feed_wrapper h2, .main_feed_wrapper .feed_link_more {
    max-width: 1160px;
  }
}
@media screen and (max-width: 1230px) {
  .main_feed_wrapper h2, .main_feed_wrapper .feed_link_more {
    max-width: 1280px;
    width: auto;
    margin-left: 30px;
  }
  .main_feed_wrapper.feed_popular_categories h2 {
    margin-left: 0;
  }
}
@media screen and (max-width: 1230px) {
  .main_feed_wrapper h2, .main_feed_wrapper .feed_link_more {
    margin-left: 20px;
  }
  .main_feed_wrapper .feed_link_more span {
    display: block;
    width: 220px;
  }
  .main_feed_wrapper .feed_link_more a {
    margin-left: 0;
  }
  .main_feed_wrapper.feed_popular_categories h2 {
    margin-left: 0;
  }
}/*# sourceMappingURL=feed.css.map */