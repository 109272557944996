.course_dl_wrapper {
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0862745098);
  min-width: 600px;
}
.course_dl_wrapper .dl_item_delete {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.course_dl_wrapper dl {
  width: calc(100% - 64px);
}
.course_dl_wrapper dl dt {
  display: block;
  margin-bottom: 8px;
}
.course_dl_wrapper dl dt input {
  width: calc(100% - 18px);
  padding: 4px 8px;
  font-size: 16px;
  line-height: 16px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.course_dl_wrapper dl dt input.placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper dl dt input:-moz-placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper dl dt input::-moz-placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper dl dt input:-ms-input-placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper dl dt input::-webkit-input-placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper dl dd textarea {
  display: block;
  width: calc(100% - 18px);
  height: 48px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.course_dl_wrapper dl dd textarea.placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper dl dd textarea:-moz-placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper dl dd textarea::-moz-placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper dl dd textarea:-ms-input-placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper dl dd textarea::-webkit-input-placeholder {
  color: #C0C2C8;
}
.course_dl_wrapper.dl_video dl dt .video_input_holder {
  display: flex;
  flex-wrap: nowrap;
}
.course_dl_wrapper.dl_video dl dt .video_input_holder button {
  flex-shrink: 0;
}
.course_dl_wrapper.dl_video dl dt .hint {
  font-size: 10px;
  line-height: 16px;
  color: #C0C2C8;
}
.course_dl_wrapper.dl_video dl dt .invalid {
  font-size: 10px;
  line-height: 16px;
  color: #C00000;
}
.course_dl_wrapper.dl_video dl dt .player_v2_wrapper {
  height: 200px;
}/*# sourceMappingURL=dl-item.css.map */