.button_default {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
  border: unset;
  padding: unset;
  width: unset;
  height: unset;
}
.button_default svg, .button_default img {
  margin-right: 10px;
}

.button_default_link {
  text-decoration: none;
}
.button_default_link span {
  text-decoration: none;
}/*# sourceMappingURL=index.css.map */