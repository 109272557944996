.search_empty {
  text-align: center;
  padding-top: 24px;
}
.search_empty img {
  display: inline-block;
  margin-bottom: 24px;
}
.search_empty .empty_text {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 64px;
}/*# sourceMappingURL=search-empty.css.map */