.cl_header {
  position: relative;
  z-index: 95;
}
.cl_header .cl_centrer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
}
.cl_header .header_content {
  position: relative;
  padding: 105px 0 150px;
}
.cl_header .header_content h1 {
  color: #fff;
  font-size: 46px;
  line-height: 60px;
  font-weight: 500;
}
@media screen and (max-width: 1180px) {
  .cl_header .cl_centrer {
    margin: 0;
    display: block;
  }
  .cl_header .header_content {
    padding-top: 95px;
    margin: 0 50px;
  }
}
@media screen and (max-width: 700px) {
  .cl_header .header_content {
    padding-top: 50px;
    padding-bottom: 210px;
    margin: 0 20px;
  }
  .cl_header .header_content h1 {
    font-size: 28px;
    line-height: 35px;
  }
}