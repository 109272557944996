.footer-socials {
  white-space: nowrap;
  font-size: 0;
  padding-top: 8px;
  margin-bottom: 8px;
}
.footer-socials a {
  display: inline-block;
  vertical-align: top !important;
  margin-right: 8px;
}
.footer-socials a.zen_link {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}/*# sourceMappingURL=footer-socials.css.map */