.lp_splash_wrapper {
  background-color: #FDF9F3;
  padding: 135px 0 147px;
}
.lp_splash_wrapper .l_content {
  display: flex;
  justify-content: space-between;
}
.lp_splash_wrapper .l_content p {
  width: calc(50% - 85px);
  font-size: 18px;
  line-height: 25px;
}
@media screen and (max-width: 1050px) {
  .lp_splash_wrapper {
    padding: 100px 0 120px;
  }
  .lp_splash_wrapper .l_content p {
    width: calc(50% - 30px);
  }
}
@media screen and (max-width: 600px) {
  .lp_splash_wrapper {
    padding: 55px 0 57px;
  }
  .lp_splash_wrapper .l_content {
    display: block;
  }
  .lp_splash_wrapper .l_content p {
    width: auto;
    margin-bottom: 25px;
  }
  .lp_splash_wrapper .l_content p:last-of-type {
    margin-bottom: 0;
  }
}/*# sourceMappingURL=index.css.map */