.course_create_wrapper {
  max-width: 1088px;
  margin: 0 auto;
  padding-top: 45px;
}
.course_create_wrapper .cc_top {
  text-align: center;
}
.course_create_wrapper .cc_top h1 {
  font-size: 24px;
  line-height: 32px;
  color: #434A5C;
  margin-bottom: 4px;
}
.course_create_wrapper .cc_top p {
  padding-top: 30px;
}
.course_create_wrapper .cc_top p a {
  color: #434A5C;
}/*# sourceMappingURL=index.css.map */