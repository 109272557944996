.school_students {
  margin: 0 24px;
  padding-bottom: 80px;
}
.school_students h1 {
  font-size: 21px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 18px;
}
.school_students p {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 18px;
}
.school_students .students_list {
  font-size: 14px;
  line-height: 19px;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.school_students .students_list_student {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.school_students .students_list_student_letter {
  border: 1px solid rgba(150, 163, 174, 0.2);
}
.school_students .students_list_student_avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 72px;
  line-height: 150px;
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 75px;
}
.school_students .students_list li {
  margin-bottom: 8px;
}
.school_students .students_list li a {
  color: #2450FF;
  text-decoration: none;
  border-bottom: 1px solid rgba(36, 80, 255, 0.3137254902);
}/*# sourceMappingURL=students.css.map */