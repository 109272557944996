.cl_params {
  position: relative;
  z-index: 95;
  background-color: #F5F5F5;
}
.cl_params .cl_centrer {
  padding: 50px 0;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
}
.cl_params .params_section {
  width: calc(25% - 16px);
  margin-right: 16px;
  flex-shrink: 0;
}
.cl_params .params_section h3 {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  letter-spacing: 0.6px;
  color: #040E26;
  text-transform: uppercase;
  opacity: 0.5;
  margin-bottom: 40px;
}
.cl_params .cl_param_school {
  position: relative;
  padding-left: 75px;
}
.cl_params .cl_param_school .userpic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #040E26;
}
.cl_params .cl_param_school .userpic img, .cl_params .cl_param_school .userpic span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cl_params .cl_param_school .userpic img {
  width: 100%;
}
.cl_params .cl_param_school .userpic span {
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  font-weight: 500;
}
.cl_params .cl_param_school .title {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: #040E26;
  margin-bottom: 3px;
}
.cl_params .cl_param_school .title a {
  text-decoration: none;
  color: unset;
}
.cl_params .cl_param_school .courses_count {
  font-size: 13px;
  line-height: 13px;
}
.cl_params .cl_param_school .courses_count a {
  color: #2450FF;
  text-decoration: none;
  border-bottom: 1px solid rgba(36, 80, 255, 0.3137254902);
}
.cl_params .cl_param_authors {
  font-size: 15px;
  line-height: 20px;
  color: #040E26;
}
.cl_params .cl_param_authors li {
  margin-bottom: 9px;
}
.cl_params .cl_param_duration_total {
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: 800;
  color: #040E26;
}
.cl_params .cl_param_duration p {
  font-size: 15px;
  line-height: 20px;
  color: #040E26;
  margin-bottom: 15px;
}
.cl_params .cl_param_duration p.time {
  position: relative;
  padding-left: 25px;
}
.cl_params .cl_param_duration p.time svg {
  position: absolute;
  left: 0;
  top: 1px;
}
.cl_params .cl_param_duration p .sorted:after {
  content: ", ";
}
.cl_params .cl_param_duration p .sorted:last-of-type:after {
  content: "";
}
.cl_params .cl_param_course_for p {
  font-size: 15px;
  line-height: 20px;
  color: #040E26;
}
@media screen and (max-width: 1180px) {
  .cl_params .cl_centrer {
    padding-bottom: 0;
  }
  .cl_params .params_section {
    width: 33%;
    margin-bottom: 55px;
  }
  .cl_params .params_section:first-of-type {
    width: 100%;
  }
  .cl_params .params_section h3 {
    margin-bottom: 26px;
  }
}
@media screen and (max-width: 900px) {
  .cl_params .params_section {
    width: 50%;
  }
  .cl_params .params_section:first-of-type {
    width: 50%;
  }
}
@media screen and (max-width: 700px) {
  .cl_params .cl_centrer {
    padding-bottom: 40px;
  }
  .cl_params .params_section {
    width: 100%;
    margin-bottom: 37px;
  }
  .cl_params .params_section:first-of-type {
    width: 100%;
    margin-bottom: 53px;
  }
  .cl_params .params_section:last-of-type {
    margin-bottom: 0;
  }
}