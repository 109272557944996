.cl_statistics {
  position: relative;
  padding: 60px 0 30px;
}
.cl_statistics .cl_centrer h2 {
  margin-bottom: 32px;
}
.cl_statistics .cl_centrer p {
  margin-bottom: 32px;
}
.cl_statistics .date_tabs {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #4b4b4b;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.cl_statistics .date_tabs span {
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #4b4b4b;
  border-radius: 6px;
  cursor: pointer;
}
.cl_statistics .date_tabs span:hover {
  opacity: 0.8;
}
.cl_statistics .date_tabs .selected_period {
  cursor: initial;
  background-color: #8c8b8b;
}
.cl_statistics .date_tabs .selected_period:hover {
  opacity: 1;
}
.cl_statistics .stat_tabs span {
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;
}
.cl_statistics .stat_tabs span.active {
  font-weight: 500;
  cursor: default;
}
.cl_statistics .apexcharts-toolbar {
  display: none;
}