.user_experts_container {
  margin-top: 40px;
  margin-left: 40px;
}

.user_experts_item {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 200px;
  margin-right: 50px;
  margin-bottom: 50px;
  text-align: center;
}
.user_experts_item a {
  text-decoration: none;
  color: unset;
}
.user_experts_item .user_experts_avatar {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #E6E7EA;
  margin-bottom: 20px;
  overflow: hidden;
}
.user_experts_item .user_experts_avatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200px;
}
.user_experts_item h5 {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 4px;
}
.user_experts_item h6 {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 15px;
  opacity: 0.7;
}
.user_experts_item .user_experts_count {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.user_experts_item .user_experts_count a {
  color: #1F57C3;
}