.course_edit_list_wrapper li {
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0862745098);
}
.course_edit_list_wrapper li .item_inner {
  width: calc(100% - 64px);
}
.course_edit_list_wrapper li .item_inner textarea {
  display: block;
  width: calc(100% - 18px);
  height: 48px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.course_edit_list_wrapper li .item_inner textarea.placeholder {
  color: #C0C2C8;
}
.course_edit_list_wrapper li .item_inner textarea:-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_list_wrapper li .item_inner textarea::-moz-placeholder {
  color: #C0C2C8;
}
.course_edit_list_wrapper li .item_inner textarea:-ms-input-placeholder {
  color: #C0C2C8;
}
.course_edit_list_wrapper li .item_inner textarea::-webkit-input-placeholder {
  color: #C0C2C8;
}
.course_edit_list_wrapper li .item_delete {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}/*# sourceMappingURL=list-item.css.map */