.dropdown {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  white-space: nowrap;
  padding-right: 17px;
}
.dropdown.disabled {
  opacity: 0.4;
  cursor: default;
}
.dropdown.opened {
  z-index: 100;
}
.dropdown.opened .dropdown_list_wrapper {
  display: block;
}
.dropdown .dropdown_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  left: 0;
  top: 0;
}
.dropdown .dropdown_opener {
  position: relative;
  display: block;
  width: 100%;
  z-index: 5;
  text-align: left;
}
.dropdown .dropdown_opener:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 100%;
  top: 0;
  height: 100%;
  width: 17px;
}
.dropdown .dropdown_opener svg {
  vertical-align: middle;
}
.dropdown .dropdown_opener svg:not(:last-child) {
  margin-right: 8px;
}
.dropdown .dropdown_opener span[data-value=akcziya] {
  color: #C33333;
}
.dropdown .dropdown_arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 0;
}
.dropdown .clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 10;
}
.dropdown .clear svg {
  width: 10px;
  height: 10px;
}
.dropdown .dropdown_list_wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  top: 100%;
  margin-top: 10px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(38, 4, 4, 0.25);
  border-radius: 8px;
  color: #040E26;
  display: none;
}
.dropdown .dropdown_list_wrapper .tool_tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
}
.dropdown .dropdown_list_wrapper ul {
  overflow: auto;
  max-height: 300px;
}
.dropdown .dropdown_list_wrapper ul li {
  white-space: nowrap;
  padding: 18px 50px 18px 16px;
  border-top: 1px solid #E6E7EA;
}
.dropdown .dropdown_list_wrapper ul li.selected {
  display: none;
}
.dropdown .dropdown_list_wrapper ul li:first-of-type {
  border-top: 0 solid;
}
.dropdown .dropdown_list_wrapper ul li:first-of-type + :not([style*="display: none"]) {
  border-top: 0 solid;
}
.dropdown .dropdown_list_wrapper ul li[data-value=akcziya] {
  color: #C33333;
}
.dropdown .dropdown_list_wrapper ul li a {
  color: unset;
  text-decoration: none;
}
.dropdown .simple_list_wrapper {
  overflow: auto;
  max-height: 300px;
}
.dropdown .simple_list_wrapper a {
  text-decoration: none;
  color: unset;
}
.dropdown .simple_list_wrapper a, .dropdown .simple_list_wrapper span {
  display: block;
  white-space: nowrap;
  padding: 18px 50px 18px 16px;
  border-bottom: 1px solid #E6E7EA;
}
.dropdown .simple_list_wrapper a:last-child, .dropdown .simple_list_wrapper span:last-child {
  border-bottom: 0 solid;
}
.dropdown.top-left .dropdown_list_wrapper {
  position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: translate(0, 0);
  bottom: 100%;
  right: 0;
  margin-bottom: 10px;
  border-radius: 8px;
}
.dropdown.top-left .dropdown_list_wrapper .tool_tip {
  position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: translate(0, 0);
  transform: rotate(180deg);
  right: 8px;
  top: 100%;
}/*# sourceMappingURL=dropdown.css.map */