.custom_modal_content {
  min-width: 600px;
}
@media screen and (max-width: 600px) {
  .custom_modal_content {
    min-width: 0;
  }
}

.commercial_greeting_player {
  position: relative;
  width: 100%;
  min-width: 600px;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .commercial_greeting_player {
    min-width: 0;
  }
}
.commercial_greeting_player.playing .btn_play {
  opacity: 0;
}
.commercial_greeting_player.playing .preloader {
  opacity: 1;
}
.commercial_greeting_player.playing.started .btn_play,
.commercial_greeting_player.playing.started .poster,
.commercial_greeting_player.playing.started .preloader {
  display: none;
}
.commercial_greeting_player .poster {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  transition: linear 300ms;
}
.commercial_greeting_player .btn_play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  transition: linear 300ms;
}
.commercial_greeting_player .preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  opacity: 0;
  width: 59px;
  height: 64px;
  margin-left: -8px;
}
.commercial_greeting_player .preloader svg {
  animation: rotating 2s linear infinite;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=index.css.map */