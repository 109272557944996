.ls_easy_to_use_wrapper {
  padding-top: 100px;
  padding-bottom: 110px;
}
.ls_easy_to_use_wrapper.no_header {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ls_easy_to_use_wrapper.no_header .l_content h2 {
  display: none;
}
.ls_easy_to_use_wrapper.no_header .l_content .items {
  display: none;
}
.ls_easy_to_use_wrapper .l_content h2 {
  margin-bottom: 92px;
}
.ls_easy_to_use_wrapper .l_content .storage h3 {
  font-size: 24px;
  line-height: 35px;
  font-weight: 500;
  margin-bottom: 55px;
}
.ls_easy_to_use_wrapper .l_content .storage .plate_section {
  margin-bottom: 48px;
}
.ls_easy_to_use_wrapper .l_content .storage .plate_section h4 {
  font-size: 24px;
  line-height: 35px;
  font-weight: 600;
  margin-bottom: 16px;
}
.ls_easy_to_use_wrapper .l_content .storage .plates + p {
  margin-top: 16px;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_video li:nth-of-type(1) {
  background-color: #FFF3FE;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_video li:nth-of-type(2) {
  background-color: #F2F6FC;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_video li:nth-of-type(3) {
  background-color: #F5F1FC;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_video li:nth-of-type(4) {
  background-color: #F8FCF1;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_video li:nth-of-type(5) {
  background-color: #FDF9F3;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_video li:nth-of-type(6) {
  background-color: #F9F9F9;
  margin-right: 0;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_pdf li:nth-of-type(1) {
  background-color: #F2F2F2;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_pdf li:nth-of-type(2) {
  background-color: #FDF9F3;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_pdf li:nth-of-type(3) {
  background-color: #E6EBFF;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_pdf li:nth-of-type(4) {
  background-color: #FFFCF2;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_pdf li:nth-of-type(5) {
  background-color: #CFFFE8;
  margin-right: 0;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_webinar li:nth-of-type(1) {
  background-color: #FFF3FE;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_webinar li:nth-of-type(2) {
  background-color: #D6E9FF;
}
.ls_easy_to_use_wrapper .l_content .storage .plates.plates_webinar li:nth-of-type(3) {
  background-color: #FFE4EC;
  margin-right: 0;
}
.ls_easy_to_use_wrapper .l_content .storage .plates li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 170px;
  height: 220px;
  margin-right: 30px;
  margin-bottom: 30px;
  border-radius: 20px;
  text-align: center;
}
.ls_easy_to_use_wrapper .l_content .storage .plates li .img_wrapper {
  height: 165px;
  position: relative;
}
.ls_easy_to_use_wrapper .l_content .storage .plates li .img_wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ls_easy_to_use_wrapper .l_content .storage .plates li .img_wrapper img.img_g_service {
  height: 70px;
  width: auto;
}
.ls_easy_to_use_wrapper .l_content .storage .plates li .img_wrapper img.img_zoom {
  width: 70px;
  height: auto;
}
.ls_easy_to_use_wrapper .l_content .storage .plates li .img_wrapper img.img_webinarru {
  width: 90px;
  height: auto;
}
.ls_easy_to_use_wrapper .l_content .storage p {
  font-size: 14px;
  line-height: 15px;
  color: #444750;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item {
  flex-shrink: 0;
  width: 210px;
  margin-right: 30px;
  margin-bottom: 35px;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item .img_wrapper {
  height: 210px;
  border-radius: 20px;
  margin-bottom: 40px;
  position: relative;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item .img_wrapper.iw1 {
  background-color: #FFF3FE;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item .img_wrapper.iw2 {
  background-color: #F2F6FC;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item .img_wrapper.iw3 {
  background-color: #F5F1FC;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item .img_wrapper.iw4 {
  background-color: #F8FCF1;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item .img_wrapper.iw5 {
  background-color: #FFFCF2;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item .img_wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item h5 {
  font-family: Commissioner, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  min-height: 76px;
}
.ls_easy_to_use_wrapper .l_content .storage .cloud_solution .cs_item p {
  font-size: 12px;
  line-height: 18px;
}
.ls_easy_to_use_wrapper .l_content .items {
  white-space: nowrap;
}
.ls_easy_to_use_wrapper .l_content .items li {
  display: inline-block;
  vertical-align: top;
  margin-right: 140px;
}
.ls_easy_to_use_wrapper .l_content .items li:last-of-type {
  margin-right: 0;
}
.ls_easy_to_use_wrapper .l_content .items li h4 {
  font-size: 45px;
  line-height: 35px;
  font-weight: 600;
  margin-bottom: 26px;
}
.ls_easy_to_use_wrapper .l_content .items li p {
  white-space: normal;
  width: 300px;
  font-size: 18px;
  line-height: 25px;
  color: #444750;
}
@media screen and (max-width: 1440px) {
  .ls_easy_to_use_wrapper .l_content .items {
    white-space: normal;
  }
  .ls_easy_to_use_wrapper .l_content .items li {
    margin-right: 0;
    display: block;
    margin-bottom: 60px;
  }
  .ls_easy_to_use_wrapper .l_content .items li:last-of-type {
    margin-bottom: 0;
  }
  .ls_easy_to_use_wrapper .l_content .items li p {
    width: auto;
  }
}
@media screen and (max-width: 1050px) {
  .ls_easy_to_use_wrapper .l_content .storage .plates {
    white-space: nowrap;
    padding: 0 50px 20px;
    width: 100%;
    margin-left: -50px;
    overflow: auto;
  }
}
@media screen and (max-width: 600px) {
  .ls_easy_to_use_wrapper {
    padding-top: 51px;
    padding-bottom: 47px;
  }
  .ls_easy_to_use_wrapper .l_content h2 {
    margin-bottom: 30px;
  }
  .ls_easy_to_use_wrapper .l_content .storage {
    margin-bottom: 44px;
  }
  .ls_easy_to_use_wrapper .l_content .storage h3 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 37px;
  }
  .ls_easy_to_use_wrapper .l_content .storage .plates {
    padding: 0 25px 20px;
    margin-bottom: 10px;
    margin-left: -25px;
  }
  .ls_easy_to_use_wrapper .l_content .items li {
    margin-bottom: 43px;
  }
  .ls_easy_to_use_wrapper .l_content .items li h4 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 13px;
  }
  .ls_easy_to_use_wrapper .l_content .items li p {
    font-size: 14px;
    line-height: 20px;
  }
}/*# sourceMappingURL=index.css.map */