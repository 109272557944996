.sidebar_profile a {
  text-decoration: none;
}
.sidebar_profile .sp_userdata {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 24px 0;
  text-align: center;
  margin-bottom: 64px;
}
.sidebar_profile .sp_userdata .sp_userpic {
  width: 96px;
  height: 96px;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  background-color: #fff;
  margin-bottom: 24px;
}
.sidebar_profile .sp_userdata .sp_userpic span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 72px;
  line-height: 72px;
}
.sidebar_profile .sp_userdata .sp_username_text {
  font-size: 16px;
  line-height: 24px;
  color: #040E26;
}
.sidebar_profile .sp_userdata .sp_username_text.school {
  font-size: 24px;
  color: #434A5C;
}
.sidebar_profile .sp_userdata .sp_role {
  font-size: 16px;
  line-height: 24px;
  color: #818692;
}
.sidebar_profile .sp_links {
  text-align: center;
}
.sidebar_profile .sp_links a {
  display: block;
  color: #040E26;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
}
.sidebar_profile .sp_links a span {
  color: #C0C2C8;
}
.sidebar_profile .sp_links a.ua_logout, .sidebar_profile .sp_links a.red {
  color: #C43333;
}/*# sourceMappingURL=authorized.css.map */