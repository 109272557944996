.lp_opportunities_wrapper {
  padding-top: 114px;
  padding-bottom: 150px;
}
.lp_opportunities_wrapper .l_content h2 {
  margin-bottom: 111px;
}
.lp_opportunities_wrapper .l_content .plates {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.lp_opportunities_wrapper .l_content .plates li {
  position: relative;
  width: calc(33% - 20px);
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 20px;
  height: 480px;
}
.lp_opportunities_wrapper .l_content .plates li p {
  padding: 40px 40px 0;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 35px;
}
.lp_opportunities_wrapper .l_content .plates li p strong {
  display: block;
}
.lp_opportunities_wrapper .l_content .plates li .plate_round {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  margin-left: 40px;
}
.lp_opportunities_wrapper .l_content .plates li .plate_round img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lp_opportunities_wrapper .l_content .plates li.plate_1 {
  background-color: #FFF4F2;
}
.lp_opportunities_wrapper .l_content .plates li.plate_1 p strong {
  color: #F90353;
}
.lp_opportunities_wrapper .l_content .plates li.plate_1 .plate_round {
  background-color: #fff;
  box-shadow: 10px 10px 30px #FFD5CC;
}
.lp_opportunities_wrapper .l_content .plates li.plate_1 .plate_bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 0;
  width: calc(100% - 154px);
}
.lp_opportunities_wrapper .l_content .plates li.plate_2 {
  background-color: #fff;
  box-shadow: 20px 40px 60px rgba(0, 22, 82, 0.1019607843);
}
.lp_opportunities_wrapper .l_content .plates li.plate_2 p strong {
  color: #604BC4;
}
.lp_opportunities_wrapper .l_content .plates li.plate_2 .plate_bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 0;
  width: calc(100% - 60px);
}
.lp_opportunities_wrapper .l_content .plates li.plate_3 {
  background-color: #604BC4;
}
.lp_opportunities_wrapper .l_content .plates li.plate_3 p {
  color: #fff;
}
.lp_opportunities_wrapper .l_content .plates li.plate_3 .plate_round {
  background: #FAFCFF; /* Old browsers */ /* FF3.6+ */
  background: -webkit-gradient(129deg, color-stop(0%, #FAFCFF), color-stop(100%, #DEE6F5)); /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(129deg, #FAFCFF 0%, #DEE6F5 100%); /* W3C */
  box-shadow: 10px 10px 30px #4933B2;
}
.lp_opportunities_wrapper .l_content .plates li.plate_3 .plate_bg {
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
  width: calc(100% - 154px);
}
@media screen and (max-width: 1050px) {
  .lp_opportunities_wrapper .l_content .plates li {
    height: 375px;
  }
  .lp_opportunities_wrapper .l_content .plates li p {
    padding: 35px 30px 0;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 27px;
  }
  .lp_opportunities_wrapper .l_content .plates li.plate_1 .plate_bg, .lp_opportunities_wrapper .l_content .plates li.plate_3 .plate_bg {
    width: calc(100% - 100px);
  }
}
@media screen and (max-width: 900px) {
  .lp_opportunities_wrapper {
    padding: 70px 0 75px;
  }
  .lp_opportunities_wrapper .l_content h2 {
    margin-bottom: 75px;
  }
  .lp_opportunities_wrapper .l_content .plates {
    display: block;
    text-align: center;
  }
  .lp_opportunities_wrapper .l_content .plates li {
    width: auto;
    max-width: 300px;
    margin: 0 auto 35px;
    text-align: left;
  }
}
@media screen and (max-width: 600px) {
  .lp_opportunities_wrapper {
    padding: 50px 0 55px;
  }
  .lp_opportunities_wrapper .l_content h2 {
    margin-bottom: 35px;
  }
  .lp_opportunities_wrapper .l_content .plates {
    display: block;
    text-align: center;
  }
  .lp_opportunities_wrapper .l_content .plates li {
    margin-bottom: 15px;
  }
}/*# sourceMappingURL=index.css.map */