.header_v2 .search_bar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 255px;
  width: calc(50% - 250px);
}
.header_v2 .search_bar button {
  right: auto;
  left: 0;
}
.header_v2 .search_bar button .search_icon {
  display: none;
}
.header_v2 .search_bar button .search_icon_2 {
  display: block;
}
.header_v2 .search_bar input {
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  border-bottom: 0 solid;
  padding-left: 30px;
  width: calc(100% - 30px);
  text-align: left;
  color: #040e26;
}
.header_v2 .search_bar input.placeholder {
  color: #040e26;
}
.header_v2 .search_bar input:-moz-placeholder {
  color: #040e26;
}
.header_v2 .search_bar input::-moz-placeholder {
  color: #040e26;
}
.header_v2 .search_bar input:-ms-input-placeholder {
  color: #040e26;
}
.header_v2 .search_bar input::-webkit-input-placeholder {
  color: #040e26;
}
.header_v2 .search_bar input:focus.placeholder {
  color: transparent;
}
.header_v2 .search_bar input:focus:-moz-placeholder {
  color: transparent;
}
.header_v2 .search_bar input:focus::-moz-placeholder {
  color: transparent;
}
.header_v2 .search_bar input:focus:-ms-input-placeholder {
  color: transparent;
}
.header_v2 .search_bar input:focus::-webkit-input-placeholder {
  color: transparent;
}
@media screen and (max-width: 1050px) {
  .header_v2 .search_bar {
    z-index: 150;
    left: 0;
    width: 100%;
    background-color: #fff;
    height: 59px;
  }
  .header_v2 .search_bar button {
    left: 22px;
  }
  .header_v2 .search_bar input {
    margin-top: 21px;
    padding-left: 52px;
    width: calc(100% - 52px);
  }
  .header_v2 .search_bar .clear_search svg {
    width: auto;
  }
  .header_v2 .search_bar .close_search {
    display: block;
    right: 22px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 2px;
  }
}
@media screen and (max-width: 1050px) {
  .header_v2 .search_bar {
    margin-top: -60px;
    transition: linear 150ms;
  }
  .header_v2.show_search .search_bar {
    margin-top: 0;
    display: block;
  }
}

.search_bar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 416px;
}
body.no_search .search_bar {
  display: none !important;
}
.search_bar .close_search {
  z-index: 10;
  display: none;
}
.search_bar .clear_search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
  margin-top: 2px;
  opacity: 0;
  z-index: 20;
  background: #fff;
}
.search_bar .clear_search svg {
  width: 10px;
}
.search_bar.focused .clear_search {
  opacity: 1;
}
.search_bar button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.search_bar input {
  font-size: 16px;
  line-height: 24px;
  color: #E6E7EA;
  padding: 0;
  width: 100%;
  text-align: center;
  border: 0 solid;
  border-bottom: 1px solid #E6E7EA;
  background-color: transparent;
  transition: linear 250ms;
}
.search_bar input.placeholder {
  color: #C0C2C8;
}
.search_bar input:-moz-placeholder {
  color: #C0C2C8;
}
.search_bar input::-moz-placeholder {
  color: #C0C2C8;
}
.search_bar input:-ms-input-placeholder {
  color: #C0C2C8;
}
.search_bar input::-webkit-input-placeholder {
  color: #C0C2C8;
}
.search_bar button .search_icon circle, .search_bar button .search_icon path {
  stroke: #E6E7EA;
}
.search_bar button .search_icon_2 {
  display: none;
}
@media screen and (max-width: 1050px) {
  .search_bar {
    width: 50%;
    min-width: 160px;
  }
}/*# sourceMappingURL=search-bar.css.map */