.blog_editor_wrapper {
  position: relative;
  max-width: 770px;
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 120px;
  text-align: center;
}
.blog_editor_wrapper .bc_top {
  margin-bottom: 64px;
}
.blog_editor_wrapper .bc_top h2 {
  font-size: 24px;
  line-height: 32px;
}
.blog_editor_wrapper .blog_editor {
  width: 770px;
  margin-left: -70px;
}
.blog_editor_wrapper .blog_editor .rdw-link-modal-btn {
  color: #434A5C !important;
  padding: 0;
}
.blog_editor_wrapper .blog_editor .rdw-link-modal-target-option input {
  padding: 0;
  width: 16px;
  height: 16px;
  margin: 0;
  display: inline-block;
  -webkit-appearance: checkbox;
}
.blog_editor_wrapper .blog_editor dl {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}
.blog_editor_wrapper .blog_editor dl.z_up {
  z-index: 2;
}
.blog_editor_wrapper .blog_editor dl.z_up_3 {
  z-index: 3;
}
.blog_editor_wrapper .blog_editor dl.focused {
  z-index: 10;
}
.blog_editor_wrapper .blog_editor dl dt {
  flex-shrink: 0;
  width: 160px;
  padding-right: 16px;
  text-align: right;
  margin-top: 2px;
}
.blog_editor_wrapper .blog_editor dl dt.eli_dt_video_label {
  margin-top: 6px;
}
.blog_editor_wrapper .blog_editor dl dt.duration_label {
  margin-top: -9px;
}
.blog_editor_wrapper .blog_editor dl dd {
  width: 100%;
}
.blog_editor_wrapper .blog_editor dl dd .course_form_hint {
  font-size: 10px;
  line-height: 16px;
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd textarea {
  display: block;
  margin-top: -4px;
  width: calc(100% - 18px);
  height: 120px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.blog_editor_wrapper .blog_editor dl dd textarea.placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd textarea:-moz-placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd textarea::-moz-placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd textarea:-ms-input-placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd textarea::-webkit-input-placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd input {
  display: block;
  margin-top: -4px;
  width: calc(100% - 18px);
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.blog_editor_wrapper .blog_editor dl dd input.placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd input:-moz-placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd input::-moz-placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd input:-ms-input-placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor dl dd input::-webkit-input-placeholder {
  color: #C0C2C8;
}
.blog_editor_wrapper .blog_editor .buttons_holder {
  margin-top: 32px;
}
.blog_editor_wrapper .blog_editor .buttons_holder button {
  margin: 0 4px;
}
.blog_editor_wrapper .blog_editor .buttons_holder a {
  margin: 0 4px;
}
.blog_editor_wrapper .blog_editor .buttons_holder a button {
  margin: 0;
}/*# sourceMappingURL=index.css.map */