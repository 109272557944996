.course_related {
  margin: 0 64px 64px;
  position: relative;
}
.course_related h5 {
  font-size: 16px;
  line-height: 24px;
  color: #434A5C;
  opacity: 0.5;
  margin-bottom: 24px;
}
@media screen and (max-width: 600px) {
  .course_related {
    margin: 0 24px 64px;
  }
}/*# sourceMappingURL=course-related.css.map */