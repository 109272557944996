.courses_block {
  text-align: center;
  display: block;
}
.courses_block .course_preview {
  width: 304px;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .courses_block .course_preview {
    width: 100%;
    max-width: 304px;
  }
}/*# sourceMappingURL=courses-block.css.map */