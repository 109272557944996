.form_with_description {
  width: 532px;
  display: flex;
  margin-left: -196px;
}
.form_with_description .sign_up_form {
  padding-top: 20px;
}
.form_with_description .signup_form_description {
  flex-shrink: 0;
  width: 300px;
  margin: 0 16px 16px;
  background-color: #F2F0F0;
  padding: 24px;
  text-align: left;
}
.form_with_description .signup_form_description p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.form_with_description .signup_form_description p.reg_join_free {
  font-weight: bold;
  position: relative;
  padding-right: 20px;
}
.form_with_description .signup_form_description p.reg_join_free ins {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.form_with_description .signup_form_description p.reg_join_free ins svg {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);
  width: 24px;
  height: 24px;
}
.form_with_description .signup_form_description p.reg_join_free ins svg path {
  stroke: #C0C2C8;
}
.form_with_description .signup_form_description p + ul {
  margin-top: -24px;
  margin-bottom: 24px;
}
.form_with_description .signup_form_description ul li {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  padding-left: 16px;
  white-space: normal;
}
.form_with_description .signup_form_description ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 4px;
  height: 4px;
  background-color: #C0C2C8;
  border-radius: 50%;
}
@media screen and (max-width: 1050px) {
  .form_with_description {
    width: 690px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 780px) {
  .form_with_description {
    width: auto;
  }
  .form_with_description .sign_up_form {
    padding-top: 0;
  }
  .form_with_description .signup_form_description {
    display: none;
  }
}

.sign_up_form {
  text-align: left;
  position: relative;
  max-width: 300px;
  margin: 0 auto;
}
.sign_up_form.success .suf_wrapper {
  display: none;
}
.sign_up_form.success .success_message {
  display: block;
}
.sign_up_form .success_message {
  display: none;
  text-align: center;
}
.sign_up_form .success_message p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
}
.sign_up_form .success_message p span {
  font-weight: bold;
}
.sign_up_form .text_input {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 45px;
}
.sign_up_form .school_switcher {
  background-color: #F2F0F0;
  border-radius: 16px;
  padding: 8px 24px;
  margin-bottom: 24px;
}
.sign_up_form .school_switcher .checkbox {
  margin-bottom: 0;
  display: block;
}
.sign_up_form .checkbox {
  margin-bottom: 45px;
}
.sign_up_form .beta_test_disclaimer {
  font-size: 13px;
  line-height: 19px;
  margin-top: -16px;
  margin-bottom: 32px;
}
.sign_up_form .beta_test_disclaimer a {
  color: #040E26;
}
.sign_up_form button {
  display: block;
  margin: 0 auto;
}/*# sourceMappingURL=sign-up-form.css.map */