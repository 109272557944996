.edit_course_price_widget {
  margin: 0 auto 32px;
  width: 600px;
}
.edit_course_price_widget .free_course_toggler_wrapper {
  margin-bottom: 48px;
}
.edit_course_price_widget .min_price_error {
  color: #C33333;
}
.edit_course_price_widget .price_disclamer {
  font-size: 13px;
  line-height: 19px;
  padding-top: 32px;
  text-align: left;
  margin-left: 176px;
}
.edit_course_price_widget h5 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
}
.edit_course_price_widget dl {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}
.edit_course_price_widget dl.z_up {
  z-index: 2;
}
.edit_course_price_widget dl.focused {
  z-index: 10;
}
.edit_course_price_widget dl dt {
  flex-shrink: 0;
  width: 160px;
  padding-right: 16px;
  text-align: right;
  margin-top: 8px;
}
.edit_course_price_widget dl dt.eli_dt_video_label {
  margin-top: 6px;
}
.edit_course_price_widget dl dd {
  width: 100%;
  display: flex;
  align-items: stretch;
}
.edit_course_price_widget dl dd input {
  display: block;
  width: calc(100% - 18px);
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #C0C2C8;
  color: #434A5C;
}
.edit_course_price_widget dl dd input.placeholder {
  color: #C0C2C8;
}
.edit_course_price_widget dl dd input:-moz-placeholder {
  color: #C0C2C8;
}
.edit_course_price_widget dl dd input::-moz-placeholder {
  color: #C0C2C8;
}
.edit_course_price_widget dl dd input:-ms-input-placeholder {
  color: #C0C2C8;
}
.edit_course_price_widget dl dd input::-webkit-input-placeholder {
  color: #C0C2C8;
}
.edit_course_price_widget dl dd input:disabled {
  background-color: transparent;
  opacity: 0.4;
}
.edit_course_price_widget dl dd button {
  flex-shrink: 0;
}/*# sourceMappingURL=price.css.map */